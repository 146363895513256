/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Check } from '@material-ui/icons';
import {
  Box,
  Container,
  Divider,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button, Grid, Modal, Tooltip } from '@material-ui/core';
import './Shell.css';
import { withStyles } from '@mui/styles';
import organisation from '../api/organisation';
import MainMenu from './MainMenu';
import theme from '../helpers/theme';
import { colors } from '../helpers/colors';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import ClockInButton from './ClockInButton';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import CircularProgress from '@mui/material/CircularProgress';
import company from '../api/company';
import {
  DefaultAvatar,
  DefaultHumanAvatar,
  EMPortal,
  StaticBaseURL,
} from '../common/constants';
import { EditorState } from 'draft-js';
import { Toaster } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import { convertToRaw } from 'draft-js';
import announcement from '../api/announcement';
import user from '../api/user';
import UserContext from '../context/UserContext';
import { useContext } from 'react';
import ActivityLoader from './ActivityLoader';
import { authz } from '../services/authz';
import { preferenceService } from '../services/preferences';
import _ from 'lodash';
import moment from 'moment/moment';
import ViewController from './ViewController/ViewController';
import '../i18n';
import { useTranslation } from 'react-i18next';

import {
  CustomToast,
  changemainlang,
  show_action_required,
} from '../common/Methodcommon';
import LockScreenView from './LockScreenView';
import NotificationBox from './NotificationTools/NotificationBox';
import createSocketConnection from '../common/ws/connection';
import { useNavigate } from 'react-router';
import { useCheckScroll, useHover, useTranslate } from '../hooks';
import { Icons, Image, Button as TailwindButton } from '../components';
import { Popover } from '@material-ui/core';
import classNames from 'classnames';
import { useList } from '../api/react-query';
import { getCompanyCurrency } from '../api/core';

const styles = {
  box: {
    width: '100%',
    height: '90vh',
    backgroundColor: '#f5f5fa',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    overflow: 'hidden',
  },
  logo: {
    marginLeft: '100px',
    marginTop: '20px',
  },
  closeDrawer: {
    background: theme.palette.secondary.main,
    padding: 3,
    top: '10px',
    left: '180px',
    width: '36px',
    height: '36px',
    opacity: 1,
  },
  menuItem: {
    color: '#8F95B2',
    fontWeight: '400',
    font: 'normal normal normal 16px',
    letterSpacing: '0px',
    opacity: 1,
    marginLeft: '32px',
    marginTop: '14px',
    marginBottom: '16px',
  },
  menuItem1: {
    color: '#8181a5',
    fontSize: '0.85em',
    // paddingLeft:'0.5em',
    fontWeight: '400',
    font: 'normal normal bold 14px',
    marginRight: '1em',
    marginLeft: '1em',
    cursor: 'pointer',
  },
  topIcons: {
    background: '#f5f5fa 0% 0% no-repeat padding-box',
    borderRadius: '12px',
    marginRight: '22px',
  },
  notifModal: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '20px 13px 40px #0000001A',
    borderRadius: '10px',
    position: 'absolute',
    top: '80px',
    right: '5em',
    width: '420px',
    height: '360px',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: 5,
  },
  langmodal: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '20px 13px 40px #0000001A',
    borderRadius: '18px',
    position: 'absolute',
    top: '80px',
    right: '3em',
    width: '280px',
    height: '194px',
    padding: '1em',
  },
  accmodal: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '20px 13px 40px #0000001A',
    borderRadius: '18px',
    position: 'absolute',
    top: '80px',
    width: '352px',
    minheight: '160px',
    height: 'auto',
    maxHeight: '85vh',
    overflowY: 'auto',
    borderColor: 'white',
  },
  quickmodal: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '20px 13px 40px #0000001A',
    borderRadius: '18px',
    position: 'absolute',
    top: '80px',
    right: '3em',
    width: '336px',
    height: '320px',
    padding: '2em',
  },
  modal: {
    padding: '3em',
    paddingTop: '2em',
    borderRadius: '1em',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    width: '850px',
    height: '650px',
  },
  cancel: {
    fontSize: '0.8em',
    fontWeight: 'bold',
    color: '#ff5263',
    borderRadius: '5px',
    backgroundColor: '#ffedef',
    textTransform: 'none',
    textAlign: 'left',
    paddingLeft: '2.5em',
    paddingRight: '2.5em',
    paddingTop: '0.1em',
    paddingBottom: '0.1em',
  },
};

const CustomTextField = styled(TextField)({
  marginBottom: '1.8em',
  '& label.Mui-focused': {
    color: colors.primary,
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: colors.primary,
  },
  '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    font: 'normal normal normal 14px/24px;',
  },
  '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
    fontSize: '0.85em',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: colors.lightgrey,
  },
});

const Shell = (props) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [userLang, setuserLang] = useState(
    localStorage.getItem('applang') ? localStorage.getItem('applang') : 'en',
  );
  const [pageloader, setPageloader] = useState(false);
  const [notificationModal, showNotificationModal] = useState(false);
  const [accountModal, showAccountModal] = useState(false);
  const [langModal, showLangModal] = useState(false);
  const [quickModal, showQuickModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [lock, setLock] = useState(false);
  const [announcementModal, showAnnouncementModal] = useState(false);
  const [proofAttachment, setProofAttachment] = useState('');
  const [, setProofAttachmentPath] = useState('');
  const [proofAttachmentName, setProofAttachmentName] = useState('');
  const [subject, setSubject] = useState('');
  const [, setCompanyLogo] = useState('');
  const [companysubsidiery, setCompanysubsidiery] = useState({});
  const [switchrole, setSwitchrole] = useState([]);
  const [, setLandingLogo] = useState('');
  const [switching, setSwitching] = useState(false);
  const [, setMyNotifications] = useState([]);
  const [unreadCount, setUnReadCount] = useState(0);
  const [canAccessEmpPortal, setCanAccessEmpPortal] = useState(false);
  const [isFetchingNotifications, setFetchingNotifications] = useState(false);

  const { isScrolled } = useCheckScroll();

  const {
    userInfo,
    activeBU,
    activeUserRole,
    updateUserInfo,
    updateActiveBU,
    viewAsEmployee,
    updateViewAsEmployee,
    activeCompanyId,
    notifications,
    updateNotifications,
    handleLogout,
  } = useContext(UserContext);
  const [, setUserAvatar] = useState('');
  const [mainCompLogo, setMainCompLogo] = useState('');
  const [newNotification, setNewNotification] = useState(null);

  function listen(callBack, channel, event) {
    window.Echo.channel(channel).listen(event, function (payload) {
      callBack(payload);
    });

    return function cleanUp() {
      window.Echo.leaveChannel(`${channel}`);
    };
  }

  useEffect(() => {
    createSocketConnection(null);
    return listen(
      function (response) {
        const data = response?.data;
        if (data?.type || data?.notifiable_type || data?.data) {
          setNewNotification({
            title: data.notifiable_type,
            description: data.attachments ? data.attachments : null,
            time: moment(data.created_at).fromNow(),
            is_read: data.read_at === 1 ? true : false,
            id: data.id,
            type: data.type,
            datafrom: data.datafrom,
            profilepic: data.ProfilePic,
            notifiable_for_username: data?.notifiable_for_username,
            created_at: data?.created_at,
          });
        }
      },
      `public.action.required`,
      '.actionrequired',
    );
  }, []);

  useEffect(() => {
    if (newNotification) {
      const newNotifications = [newNotification, ...notifications];
      updateNotifications(newNotifications);
      setNewNotification(null);

      CustomToast(
        'success',
        `${t('new-notification')}: ${
          newNotification?.title || newNotification?.type
        }`,
        4500,
      );
    }
  }, [newNotification]);

  useEffect(() => {
    user
      .canAccessEmployeePortal(localStorage.getItem('__current_company_id'))
      .then((res) => {
        if (res.response === 'success') {
          setCanAccessEmpPortal(true);
        }
      })
      .catch((err) => {
        setCanAccessEmpPortal(false);
      });

    if (localStorage.getItem('applang')) {
      const newlang = localStorage.getItem('applang');
      setlangdata(newlang);
    } else {
      localStorage.setItem('applang', 'en');

      setlangdata('en');
    }

    getAllNotifications();
  }, []);

  const getAllNotifications = () => {
    const uid = localStorage.getItem('__active__user__id');
    const cid = localStorage.getItem('__current_company_id');

    organisation
      .getMyNotifications(uid, cid)
      .then((result) => {
        const ns = _.map(result, function (d) {
          return {
            title: d.notifiable_type,
            description: d.attachments ? d.attachments : null,
            time: moment(d.created_at).fromNow(),
            is_read: d.read_at === 1 ? true : false,
            id: d.id,
            type: d.type,
            datafrom: d.datafrom,
            profilepic: d.ProfilePic,
            notifiable_for_username: d?.notifiable_for_username,
            name: d.name,
            created_at: d?.created_at,
          };
        });
        const unread = _.filter(ns, function (s) {
          return s.is_read === false;
        });

        setFetchingNotifications(false);
        setUnReadCount(unread.length);
        setMyNotifications(ns);
        updateNotifications(ns);
      })
      .catch((err) => {
        setFetchingNotifications(false);
        console.log(err);
      });
  };

  useEffect(() => {
    setCompanysubsidiery([]);
    user
      .getDetails()
      .then((res) => {
        setLandingLogo(res.profile_pic ? res.profile_pic : '');
      })
      .catch((err) =>
        console.log('getting error during getting details in header', err),
      );
    organisation
      .getSubsidiaries(localStorage.getItem('__current_company_id'))
      .then((res) => {
        setCompanysubsidiery(res?.children);
        setCompanyLogo(res.logo ? res.logo : '');
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    var newuser =
      userInfo &&
      userInfo.currentRole &&
      userInfo.currentRole.user_details &&
      userInfo.currentRole.user_details &&
      userInfo.currentRole.user_details.profile_pic
        ? `${userInfo.currentRole.user_details.profile_pic}`
        : null;
    if (
      userInfo &&
      userInfo.currentRole &&
      userInfo.currentRole.user_details &&
      userInfo.currentRole.user_details &&
      userInfo.currentRole.user_details.profile_pic &&
      userInfo !== newuser &&
      newuser
    ) {
      setUserAvatar(newuser);
    } else {
      setUserAvatar(StaticBaseURL + 'user_picture.svg');
    }
  }, [userInfo]);

  useEffect(() => {
    var newclogo = activeBU?.settings?.company_setting?.logo
      ? `${activeBU?.settings?.company_setting?.logo}`
      : activeBU?.settings?.logo
        ? `${activeBU?.settings?.logo}`
        : null;
    if (
      activeBU?.settings?.company_setting?.logo
        ? `${activeBU?.settings?.company_setting?.logo}`
        : activeBU?.settings?.logo && newclogo !== mainCompLogo
    ) {
      setMainCompLogo(newclogo);
    } else {
      var newclogo2 =
        i18n.language === 'ar'
          ? StaticBaseURL + 'arabic_logo.png'
          : StaticBaseURL + 'landing_logo.jpg';
      setMainCompLogo(newclogo2);
    }
  }, [activeBU]);

  useEffect(() => {
    var roleslist = userInfo.company_wise_access;
    var cuser_role = userInfo?.isAdmin;
    var subcolumns = Object.keys(companysubsidiery);
    var rolcolmns = roleslist ? Object.keys(roleslist) : [];
    // Check if employee role then remove from list
    rolcolmns?.forEach((lists) => {
      var exists = roleslist[lists]?.roles?.findIndex(
        (e) => e?.toLowerCase() === 'employee',
      );
      if (
        roleslist[lists]?.roles?.length === 1 &&
        roleslist[lists]?.roles[0]?.toLowerCase() === 'employee'
      ) {
        delete roleslist[lists];
      } else if (exists >= 0) {
        roleslist[lists].roles = roleslist[lists]?.roles?.filter(
          (e) => e?.toLowerCase() !== 'employee',
        );
      }
    });
    if (subcolumns?.length > 0 && cuser_role) {
      var objects = Object.keys(roleslist);
      subcolumns?.forEach((coldata) => {
        var listexist = objects.includes((e) => e === coldata);
        if (!listexist) {
          var newitems = {
            company_name:
              roleslist[coldata]?.company_name?.trim() === ''
                ? roleslist[coldata]?.company_name_ar
                : roleslist[coldata]?.company_name,
            company_name_ar:
              roleslist[coldata]?.company_name_ar?.trim() === ''
                ? roleslist[coldata]?.company_name
                : roleslist[coldata]?.company_name_ar,
            company_id: companysubsidiery[coldata]?.companyId,
            roles: [],
            perms: [],
            settings: roleslist[coldata]?.settings,
          };
          roleslist[coldata] = newitems;
        }
      });
    } else if (rolcolmns?.length === 1) {
      // delete roleslist[rolcolmns[0]];
    }
    setSwitchrole(roleslist);
  }, [userInfo.company_wise_access, userLang]); //companysubsidiery

  const openPop = Boolean(anchorEl);

  const showDrawer = () => {
    const accessList = _.uniq(_.map(activeBU.perms, 'screen_name'));
    if (switching) {
      return false;
    }

    if (accessList.length || userInfo.isAdmin) {
      return true;
    } else {
      return false;
    }
  };

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );

  const attachmentUpload = (e) => {
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let image_as_files = e.target.files[0];
    setProofAttachment(image_as_files);
    setProofAttachmentPath(image_as_base64);
    setProofAttachmentName(image_as_files.name);
  };

  const removeAttachment = (e) => {
    setProofAttachment('');
    setProofAttachmentPath('');
    setProofAttachmentName('');
  };

  var Base64 = {
    _keyStr:
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',
    encode: function (es4) {
      if (!es4) {
        es4 = localStorage.getItem('__active__user__id');
      }
      let e = es4.toString();
      var t = '';
      var n, r, i, s, o, u, a;
      var f = 0;
      e = Base64._utf8_encode(e);
      while (f < e.length) {
        n = e.charCodeAt(f++);
        r = e.charCodeAt(f++);
        i = e.charCodeAt(f++);
        s = n >> 2;
        o = ((n & 3) << 4) | (r >> 4);
        u = ((r & 15) << 2) | (i >> 6);
        a = i & 63;
        if (isNaN(r)) {
          u = a = 64;
        } else if (isNaN(i)) {
          a = 64;
        }
        t =
          t +
          this._keyStr.charAt(s) +
          this._keyStr.charAt(o) +
          this._keyStr.charAt(u) +
          this._keyStr.charAt(a);
      }
      return t;
    },
    decode: function (e) {
      var t = '';
      var n, r, i;
      var s, o, u, a;
      var f = 0;
      // eslint-disable-next-line no-useless-escape
      e = e.replace(/[^A-Za-z0-9\+\/\=]/g, '');
      while (f < e.length) {
        s = this._keyStr.indexOf(e.charAt(f++));
        o = this._keyStr.indexOf(e.charAt(f++));
        u = this._keyStr.indexOf(e.charAt(f++));
        a = this._keyStr.indexOf(e.charAt(f++));
        n = (s << 2) | (o >> 4);
        r = ((o & 15) << 4) | (u >> 2);
        i = ((u & 3) << 6) | a;
        t = t + String.fromCharCode(n);
        if (u !== 64) {
          t = t + String.fromCharCode(r);
        }
        if (a !== 64) {
          t = t + String.fromCharCode(i);
        }
      }
      t = Base64._utf8_decode(t);
      return t;
    },
    _utf8_encode: function (e) {
      e = e.replace(/\r\n/g, '\n');
      var t = '';
      for (var n = 0; n < e.length; n++) {
        var r = e.charCodeAt(n);
        if (r < 128) {
          t += String.fromCharCode(r);
        } else if (r > 127 && r < 2048) {
          t += String.fromCharCode((r >> 6) | 192);
          t += String.fromCharCode((r & 63) | 128);
        } else {
          t += String.fromCharCode((r >> 12) | 224);
          t += String.fromCharCode(((r >> 6) & 63) | 128);
          t += String.fromCharCode((r & 63) | 128);
        }
      }
      return t;
    },
    _utf8_decode: function (e) {
      var t = '';
      var n = 0;
      // eslint-disable-next-line
      var r = (c1 = c2 = 0);
      while (n < e.length) {
        r = e.charCodeAt(n);
        if (r < 128) {
          t += String.fromCharCode(r);
          n++;
        } else if (r > 191 && r < 224) {
          // eslint-disable-next-line
          c2 = e.charCodeAt(n + 1);
          // eslint-disable-next-line
          t += String.fromCharCode(((r & 31) << 6) | (c2 & 63));
          n += 2;
        } else {
          // eslint-disable-next-line
          c2 = e.charCodeAt(n + 1);
          // eslint-disable-next-line
          c3 = e.charCodeAt(n + 2);
          // eslint-disable-next-line
          t += String.fromCharCode(
            // eslint-disable-next-line
            ((r & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63),
          );
          n += 3;
        }
      }
      return t;
    },
  };

  const [employeeId, setEmployeeId] = useState(null);
  const [openBlankEmpPortal, setOpenBlankEmpPortal] = useState(false);

  const { isLoading } = useList(
    'auth/employee-portal-token',
    {},
    {
      enabled: !!employeeId,
      onSuccess: (data) => {
        if (!employeeId) return;

        if (openBlankEmpPortal) {
          window.open(
            `${EMPortal}/gotoEmployee?id=${employeeId}&portal=true&token=${
              data?.data.portalToken
            }&companyId=${activeCompanyId}&locale=${localStorage.getItem(
              'applang',
            )}`,
            '_blank',
            'noreferrer',
          );

          setOpenBlankEmpPortal(false);
        }

        if (!openBlankEmpPortal) {
          window.location.href = `${EMPortal}/gotoEmployee?id=${employeeId}&portal=true&token=${
            data?.data.portalToken
          }&locale=${localStorage.getItem('applang')}`;
        }

        setEmployeeId(null);
      },
    },
  );

  useEffect(() => {
    if (viewAsEmployee && activeUserRole) {
      const emp_id = activeUserRole.emp_id;

      setEmployeeId(emp_id);
      setOpenBlankEmpPortal(true);
    }
  }, [viewAsEmployee]);

  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const logout = () => {
    setIsLoggingOut(true);

    company
      .logout()
      .then((res) => {
        setIsLoggingOut(false);

        handleLogout();

        navigate('/');
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const createAnnouncement = () => {
    let company_id = null;
    if (!activeCompanyId) {
      company_id = localStorage.getItem('__current_company_id');
    }

    if (!subject || !editorState) {
      CustomToast('error', t('Please fill the missing fields'));
    } else {
      const editorJSON = JSON.stringify(
        convertToRaw(editorState.getCurrentContent()),
      );
      const msg = JSON.parse(editorJSON);
      let formData = new FormData();
      formData.append('companyId', company_id);
      formData.append('subject', subject);
      formData.append('message', msg.blocks[0].text);
      formData.append('attachment', proofAttachment);

      announcement
        .postCreateAnnouncement(formData)
        .then((res) => {
          CustomToast('success', t('Announcement added successfully!'));
          showAnnouncementModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  const [isScreenLock, setIsScreenLock] = useState(false);
  const [userName, setUserName] = useState('');

  const handleLockScreen = () => {
    setUserName(userInfo?.currentRole?.user_details?.username);
    company
      .logout()
      .then((res) => {
        CustomToast('success', 'Successfuly logout!');
        document.cookie = 'Beare Token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        setIsScreenLock(true);
      })
      .catch((err) => {
        CustomToast(
          'error',
          err?.response?.data?.data || err?.response?.data?.message,
        );
      });
  };

  const markFavourite = () => {
    const url = window.location.pathname;
    let company_id = activeCompanyId;
    if (!activeCompanyId) {
      company_id = localStorage.getItem('__current_company_id');
    }

    preferenceService
      .setPreference(activeUserRole.user_id, company_id, 'FAV_SCREEN', url)
      .then((result) => {
        let current = { ...userInfo };
        const currentFav = _.map(
          current.currentRole.user_preferences,
          function (p) {
            if (
              p.preference_name === 'FAV_SCREEN' &&
              p.company_id === userInfo.currentRole.company_id
            ) {
              p.value = result.value;
            }
            return p;
          },
        );
        current.currentRole.user_preferences = currentFav;
        updateUserInfo(current);
        return CustomToast(
          'success',
          t('Current Screen is set to default screen.'),
        );
      })
      .catch((error) => {
        return CustomToast('error', error);
      });
  };

  const handleSwitchUserRole = (BU) => {
    const user_id = activeUserRole.user_id;
    const company_id = BU;
    authz
      .switchBU(user_id, company_id)
      .then((result) => {
        showAccountModal(false);
        updateViewAsEmployee(false);
        const switchedBUDetail = userInfo.company_wise_access[BU];

        //return false;
        if (company_id) {
          getCompanyCurrency(company_id)
            .then((res) => {
              localStorage.setItem('company_currency', res?.currency?.name);
              localStorage.setItem(
                'company_currency_ar',
                res?.currency?.name_ar,
              );
            })
            .catch((err) => console.log(err));
        } 
        updateActiveBU(switchedBUDetail, true);
        navigate('/company/switch-view');
      })
      .catch((err) => {
        CustomToast('error', t('Role Switching Failed.'));
        setSwitching(false);
      });
  };

  const getRolename = () => {
    var role_en = activeBU.roles?.filter((e) => e !== 'Employee');
    var role_ar = activeBU.roles_ar?.filter((e) => e !== 'موظف');
    var role_data = i18n.language === 'ar' ? role_ar : role_en;
    var activeroless =
      i18n.language === 'en'
        ? activeUserRole.role_name
        : activeUserRole.role_name_ar;
    return role_data?.length > 0 ? role_data[0] : activeroless;
  };

  const switchLanguage = (_lang) => {
    const lang = _lang;
    const user_id = activeUserRole.user_id;
    authz
      .switchLanguage(user_id, lang)
      .then((result) => {
        moment.locale(lang);
        setlangdata(lang, true);
      })
      .catch((err) => {
        CustomToast('error', 'language Switching Failed.');
        setSwitching(false);
      });
  };

  function setlangdata(newlang, showtoast = false) {
    if (newlang === userLang) return;

    i18n.changeLanguage(newlang);
    setuserLang(newlang);
    localStorage.setItem('applang', newlang);
    if (showtoast) {
      setPageloader(true);
      localStorage.setItem('applang', newlang);
      window.location.reload();
    } else changemainlang(newlang);
  }

  const { dir } = useTranslate();
  const { isHovered, handleMouseEnter, handleMouseLeave } = useHover();

  return (
    <>
      <Backdrop open={isLoading} />
      {pageloader ? (
        <Grid container className="main-loader">
          <ActivityLoader />
        </Grid>
      ) : (
        <div dir={dir}>
          <Grid item xs={12}>
            <Toaster
              toastOptions={{
                style: {
                  maxWidth: 500,
                },
              }}
            />
            {switching ? (
              <ViewController />
            ) : lock ? (
              <Box
                style={{
                  width: '100%',
                  height: '100%',
                  margin: 0,
                }}
              >
                <img
                  src={`${StaticBaseURL}lockscreen.png`}
                  width="100%"
                  height="100%"
                  alt=""
                  onClick={() => setLock(false)}
                />
              </Box>
            ) : (
              <>
                <header
                  className={classNames(
                    'fixed top-0 left-0 bg-white backdrop-blur-sm flex justify-between items-center z-50 h-20 w-full px-4 transition-300',
                    {
                      'bg-opacity-80 shadow': isScrolled,
                      'bg-opacity-100 shadow-[0px 10px 36px #00000005]':
                        !isScrolled,
                    },
                  )}
                >
                  <span className="w-[320px] py-3">
                    <Image
                      src={
                        activeBU?.settings?.company_setting?.logo ??
                        DefaultAvatar
                      }
                      alt=""
                      width={
                        activeBU?.settings?.company_setting?.logo ? 164 : 50
                      }
                      height={35}
                      className="h-[35px] object-contain"
                    />
                  </span>

                  <span className="text-xl font-bold">
                    {t('Welcome to')} {activeBU?.company_name}
                  </span>

                  <span className="flex gap-x-5">
                    <>
                      {show_action_required() ? (
                        <Tooltip title={t('Lock Screen')}>
                          <div
                            sx={{
                              background: '#f5f5fa 0% 0% no-repeat padding-box',
                              borderRadius: '12px',
                            }}
                            onClick={handleLockScreen}
                          >
                            <i
                              className="las la-unlock-alt icon"
                              color="#8F95B2"
                            ></i>
                          </div>
                        </Tooltip>
                      ) : null}
                      <span
                        className="relative icon-bg cursor-pointer"
                        onClick={() => {
                          showNotificationModal(true);
                          setFetchingNotifications(true);
                          getAllNotifications();
                        }}
                      >
                        <div className="absolute top-2 right-4 w-2 h-2 rounded-full bg-red-500"></div>
                        <Icons name="bell" width={20} height={20} />
                      </span>
                      <Popover
                        id={1}
                        open={openPop}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      ></Popover>
                    </>
                    <Tooltip title={t('Mark current screen as default')}>
                      <span
                        className="relative icon-bg cursor-pointer"
                        onClick={markFavourite}
                      >
                        <i className="las la-star" color="#8F95B2"></i>
                      </span>
                    </Tooltip>
                    <Tooltip title={t('Switch your current language')}>
                      <div
                        className="flex items-center justify-center cursor-pointer text-primary text-base rounded-full px-3 py-2 hover:bg-primary-light transition-300"
                        onClick={() =>
                          userLang === 'en'
                            ? switchLanguage('ar')
                            : switchLanguage('en')
                        }
                      >
                        {userLang === 'en' ? 'Ar' : 'En'}
                      </div>
                    </Tooltip>
                    <span
                      className="flex items-center cursor-pointer gap-x-3 company-user-logo"
                      onClick={() => showAccountModal(true)}
                    >
                      <Image
                        src={
                          userInfo?.currentRole?.user_details?.profile_pic ??
                          DefaultHumanAvatar
                        }
                      />
                      <Box className="dash-box-emp-head">
                        <Typography className="font-bold text-lg">
                          {userInfo.currentRole &&
                          userInfo.currentRole.user_details &&
                          userInfo.currentRole.user_details.name
                            ? i18n.language === 'en'
                              ? userInfo?.currentRole?.user_details?.name
                              : userInfo?.currentRole?.user_details.name_ar
                            : userInfo?.currentRole?.user_details?.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '0.7em',

                            color: colors.grey,
                          }}
                        >
                          {getRolename()}
                        </Typography>
                      </Box>
                      <i
                        className="las la-caret-down"
                        style={{
                          fontSize: '0.8em',
                          color: colors.grey,
                        }}
                      ></i>
                    </span>
                  </span>
                </header>

                {!props.hideSidebar ? (
                  <aside
                    className={classNames(
                      'fixed top-0 ltr:left-0 rtl:right-0 overflow-y-auto h-[100dvh] bg-white hidden-scroll pt-16 pb-2 transition-300 z-40',
                      {
                        'w-[245px]': isHovered,
                        'w-[75px]': !isHovered,
                      },
                    )}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <MainMenu
                      open={showDrawer() && !switching}
                      isHovered={isHovered}
                    />
                  </aside>
                ) : null}

                <Box
                  component="main"
                  sx={{
                    backgroundColor: props.backgroundColor || '#F5F5FA',
                    flexGrow: 1,
                  }}
                  className={`shellBox overflow-y-hidden h-full border rtl:pl-3 ltr:pr-3 transition-300 ${
                    props.paddingLeft || !isHovered
                      ? 'ltr:pl-[80px] rtl:pr-[80px]'
                      : 'ltr:pl-[250px] rtl:pr-[250px]'
                  }`}
                >
                  {!props.paddingLeft ? <Toolbar /> : null}
                  {!switching ? (
                    props.fullWidth || true ? (
                      <Box
                        className={`dash-lay-box py-6 ${
                          props.paddingLeft
                            ? 'rtl:pr-[0.5em] ltr:pl-[0.5em]'
                            : 'rtl:pr-[1em] ltr:pl-[1em]'
                        }`}
                        sx={{
                          paddingTop: props.paddingLeft ? '1em' : '2em',
                          paddingBottom: '1em',
                          backgroundColor: 'transparent',
                          width: 'auto',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {props.children}
                      </Box>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: '0.5em',
                        }}
                      >
                        <Container
                          sx={{
                            mt: 4,
                            mb: 2,
                          }}
                          maxWidth="xl"
                          fixed
                          className="shellBox xl:container mx-auto"
                          style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            overflow: 'hidden',
                          }}
                        >
                          {props.children}
                        </Container>
                      </div>
                    )
                  ) : null}
                </Box>
              </>
            )}
            <Modal
              open={announcementModal}
              disableAutoFocus
              onClose={() => showAnnouncementModal(false)}
              closeAfterTransition
            >
              {/* <Fade in={announcement}> */}
              <Box style={styles.modal}>
                <Box
                  style={{
                    display: 'flex',
                    marginBottom: '2em',
                  }}
                >
                  <Box style={{ flex: 1 }}>
                    <Typography
                      className="welcome-text"
                      sx={{ fontSize: '0.9em' }}
                    >
                      {t('Create')}{' '}
                      <Typography display="inline" className="welcome-text2">
                        {t('Announcements')}
                      </Typography>
                    </Typography>
                  </Box>
                  <i
                    className="las la-times"
                    style={{
                      fontSize: '1.5em',
                      color: colors.grey,
                      cursor: 'pointer',
                    }}
                    onClick={() => showAnnouncementModal(false)}
                  ></i>
                </Box>
                <Box style={{ marginBottom: '1em' }}>
                  <CustomTextField
                    label="Subject"
                    variant="outlined"
                    fullWidth
                    value={subject}
                    placeholder="Subject"
                    onChange={(event) => setSubject(event.target.value)}
                    style={{ marginBottom: '1.2em' }}
                  />
                  <Editor
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                  />
                </Box>
                <Grid container spacing={1} style={{ marginTop: '1em' }}>
                  <Grid item>
                    <Button
                      component="label"
                      style={{
                        border: '1px dashed #9095B0',
                        height: '3em',
                        width: '13em',
                        color: '#7C0E6F',

                        fontSize: '0.875em',
                        textTransform: 'capitalize',
                      }}
                    >
                      <input
                        onChange={attachmentUpload}
                        id="raised-button-file"
                        accept="image/*"
                        type="file"
                        hidden
                      />
                      <i
                        className="las la-paperclip"
                        style={{ marginRight: '1em' }}
                      ></i>
                      {t('Attach File')}
                    </Button>
                  </Grid>
                  <Grid item style={{ marginTop: '0.5em' }}>
                    <Typography
                      style={{
                        color: '#081735',

                        fontSize: '14px',
                        fontStyle: 'italic',
                      }}
                    >
                      {proofAttachmentName} &nbsp;&nbsp;
                      {proofAttachmentName ? (
                        <i
                          onClick={removeAttachment}
                          className="las la-times"
                          style={{ color: '#FF5263' }}
                          height="14px"
                          width="14px"
                        ></i>
                      ) : (
                        <></>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{
                    display: 'flex',
                    marginTop: '2em',
                  }}
                >
                  <Box style={{ flex: 1 }}>
                    <TailwindButton
                      className="outline-btn"
                      onClick={() => {
                        showAnnouncementModal(false);
                      }}
                    >
                      {t('Cancel')}
                    </TailwindButton>
                  </Box>

                  <ClockInButton title="Submit" onClick={createAnnouncement} />
                </Grid>
              </Box>
              {/* </Fade> */}
            </Modal>
            {/* {New Notification Modal} */}
            <Modal
              open={notificationModal}
              disableAutoFocus
              onClose={() => showNotificationModal(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <NotificationBox
                unreadCount={unreadCount}
                isFetchingNotifications={isFetchingNotifications}
                onClickViewAll={() => {
                  window.location.href = '/company/Notification';
                  //callupdateviewall();
                }}
                notifications={notifications}
                updateReadUncount={(updateCount) => setUnReadCount(updateCount)}
              />
            </Modal>

            <Modal
              open={langModal}
              disableAutoFocus
              onClose={() => showLangModal(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={langModal}>
                <Box style={styles.langmodal}>
                  <Box
                    style={{
                      padding: '0.8em',
                      marginTop: '0.5em',
                      backgroundColor: '#f5f5fa',
                      borderRadius: '0.6em',
                      display: 'flex',
                    }}
                  >
                    <Typography
                      sx={{
                        flex: 1,
                        fontSize: '0.75em',

                        fontWeight: 'bold',
                        color: colors.grey,
                      }}
                    >
                      English
                    </Typography>
                    <Check
                      style={{
                        color: colors.green,
                        fontSize: '0.8em',
                      }}
                    />
                  </Box>
                  <Box>
                    <Box
                      style={{
                        padding: '0.8em',
                        marginTop: '0.5em',
                        backgroundColor: colors.secondary,
                        borderRadius: '0.6em',
                        display: 'flex',
                      }}
                    >
                      <Typography
                        sx={{
                          flex: 1,
                          fontSize: '0.75em',

                          fontWeight: 'bold',
                          color: colors.grey,
                        }}
                      >
                        Hindi
                      </Typography>
                      <Check
                        style={{
                          color: '#f5f5fa',
                          fontSize: '0.85em',
                        }}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Box
                      style={{
                        padding: '0.8em',
                        marginTop: '0.5em',
                        backgroundColor: colors.secondary,
                        borderRadius: '0.6em',
                        display: 'flex',
                      }}
                    >
                      <Typography
                        sx={{
                          flex: 1,
                          fontSize: '0.75em',

                          fontWeight: 'bold',
                          color: colors.grey,
                        }}
                      >
                        Arabic
                      </Typography>
                      <Check
                        style={{
                          color: colors.green,
                          fontSize: '0.85em',
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Fade>
            </Modal>
            <Modal
              open={accountModal}
              disableAutoFocus
              onClose={() => showAccountModal(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={accountModal}>
                <div
                  dir={dir}
                  className={classNames(
                    'opacity-100 bg-no-repeat bg-white bg-[0% 0%] shadow-md rounded-lg absolute top-20 w-[22rem] h-auto max-h-[85vh] overflow-y-auto border-white',
                    {
                      'left-auto right-[3em]': dir === 'ltr',
                      'right-auto left-[3em]': dir === 'rtl',
                    },
                  )}
                >
                  <Typography
                    style={{
                      fontSize: '0.9em',
                      padding: '1.3em',
                    }}
                  >
                    {t('Switch Your Role')}
                  </Typography>
                  <Divider style={{ marginBottom: '2em' }} />
                  {switchrole &&
                    _.map(Object.keys(switchrole), function (BU) {
                      const currentBU = switchrole[BU];
                      const { settings, company_name } = currentBU;
                      var roles = currentBU?.roles;
                      if (i18n.language === 'ar' && currentBU?.roles_ar)
                        roles = currentBU?.roles_ar;
                      return (
                        <div onClick={() => handleSwitchUserRole(BU)}>
                          <Grid
                            container
                            style={{
                              marginLeft: '2em',
                              marginRight: '2em',
                              backgroundColor: '#f5f5fa',
                              width: 'auto',
                              padding: '0.5em',
                              paddingTop: '1em',
                              paddingBottom: '1em',
                              borderRadius: '0.7em',
                              marginBottom: '1em',
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Grid
                              item
                              xs={2}
                              style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                display: 'flex',
                              }}
                            >
                              {settings?.company_setting ||
                              settings?.favicon ? (
                                settings?.company_setting?.favicon ||
                                settings?.favicon ? (
                                  <div
                                    style={{
                                      width: 28,
                                      height: 28,
                                      backgroundColor: 'transparent',
                                    }}
                                  >
                                    <Image
                                      src={`${
                                        settings?.favicon
                                          ? settings?.favicon
                                          : settings?.company_setting?.favicon
                                      }`}
                                      alt=""
                                      className="rounded-full p-1 border border-dashed border-primary"
                                    />
                                  </div>
                                ) : (
                                  <IconButton>
                                    <i className="las la-briefcase"></i>
                                  </IconButton>
                                )
                              ) : (
                                <></>
                              )}
                            </Grid>
                            <Grid item xs={8}>
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: 'bold',
                                  color: colors.primary,
                                }}
                              >
                                {i18n.language === 'ar' &&
                                currentBU?.company_name_ar?.trim() !== ''
                                  ? currentBU?.company_name_ar
                                  : company_name}
                                {currentBU?.settings?.parent_company_id ===
                                0 ? (
                                  <span className="super-admin-chip">
                                    {t('Parent Company')}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </Typography>
                              <Box
                                style={{
                                  alignItems: 'flex-start',
                                  justifyContent: 'flex-start',
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                {_.map(roles, function (r, index) {
                                  return (
                                    <Typography
                                      sx={{
                                        fontSize: '10px',
                                        color: '#444444',
                                      }}
                                      key={index}
                                    >
                                      {r}
                                      {index < roles.length - 1 ? ',' : ''}
                                    </Typography>
                                  );
                                })}
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              style={{
                                alignSelf: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              {activeBU ? (
                                BU === activeBU?.settings?.company_id ||
                                BU ===
                                  activeBU.settings?.company_setting
                                    ?.company_id ||
                                BU ===
                                  activeBU.settings?.company_setting
                                    ?.companyId ? (
                                  <Check
                                    style={{
                                      color: colors.green,
                                      fontSize: '1em',
                                    }}
                                  />
                                ) : (
                                  <Check
                                    style={{
                                      color: '#f5f5f5',
                                      fontSize: '1em',
                                    }}
                                  />
                                )
                              ) : (
                                ''
                              )}
                            </Grid>
                          </Grid>
                        </div>
                      );
                    })}
                  {!userInfo.isAdmin && canAccessEmpPortal && (
                    <div
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        const emp_id = activeUserRole.emp_id;

                        setEmployeeId(emp_id);
                        setOpenBlankEmpPortal(true);

                        showAccountModal(false);
                      }}
                    >
                      <Grid
                        container
                        style={{
                          marginLeft: '2em',
                          marginRight: '2em',
                          backgroundColor: '#f5f5fa',
                          width: 'auto',
                          padding: '0.5em',
                          paddingTop: '1em',
                          paddingBottom: '1em',
                          borderRadius: '0.7em',
                        }}
                      >
                        <Grid
                          item
                          xs={2}
                          style={{
                            alignSelf: 'center',
                          }}
                        >
                          <img
                            src={`${StaticBaseURL}logo.png`}
                            width={25}
                            height={25}
                            alt=""
                          />
                        </Grid>

                        <Grid item xs={8}>
                          <Typography
                            sx={{
                              fontSize: '0.82em',

                              fontWeight: 'bold',
                              color: colors.primary,
                            }}
                          >
                            {t('My Portal')}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={2}
                          style={{
                            alignSelf: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        ></Grid>
                      </Grid>
                    </div>
                  )}

                  <div className="py-3 px-8 w-full">
                    <TailwindButton
                      className="flex items-center justify-center btn w-full border-solid border-red-50 text-red-500 bg-red-50"
                      disabled={isLoggingOut}
                      disabledClassName="disabled-btn border w-full"
                      onClick={() => logout()}
                    >
                      <span className="w-10"></span>
                      <span>{t('Logout')}</span>

                      <span className="w-10 !cursor-not-allowed">
                        {isLoggingOut && (
                          <CircularProgress className="!w-4 !h-4 mt-1" />
                        )}
                      </span>
                    </TailwindButton>
                  </div>
                </div>
              </Fade>
            </Modal>
            <Modal
              open={quickModal}
              onClose={() => showQuickModal(false)}
              disableAutoFocus={true}
              disableEnforceFocus={true}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={quickModal}>
                <Box style={styles.quickmodal}>
                  <Grid
                    item
                    container
                    style={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                    }}
                  >
                    <Grid
                      item
                      xs={6}
                      style={{
                        backgroundColor: '#f5f5fa',
                        borderRadius: '0.8em',
                        textAlign: 'center',
                        marginRight: '1em',
                        paddingTop: '2em',
                        paddingBottom: '2em',
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <i
                          className="las la-user"
                          style={{
                            fontSize: '2em',
                            color: '#8181A5',
                          }}
                        ></i>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: '0.8em',

                          fontWeight: 'bold',
                          color: colors.grey,
                        }}
                      >
                        {t('Employee')}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        backgroundColor: '#f5f5fa',
                        borderRadius: '0.8em',
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <i
                          className="las la-check-circle"
                          style={{
                            fontSize: '2em',
                            color: '#8181A5',
                          }}
                        ></i>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: '0.8em',

                          fontWeight: 'bold',
                          color: colors.grey,
                          textAlign: 'center',
                        }}
                      >
                        {t('Attendance')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    style={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      marginTop: '1em',
                    }}
                  >
                    <Grid
                      item
                      xs={6}
                      style={{
                        backgroundColor: '#f5f5fa',
                        borderRadius: '0.8em',
                        textAlign: 'center',
                        marginRight: '1em',
                        paddingTop: '2em',
                        paddingBottom: '2em',
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <i
                          className="las la-user-alt"
                          style={{
                            fontSize: '2em',
                            color: '#8181A5',
                          }}
                        ></i>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: '0.8em',

                          fontWeight: 'bold',
                          color: colors.grey,
                        }}
                      >
                        {t('Hire Candidate')}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        backgroundColor: '#f5f5fa',
                        borderRadius: '0.8em',
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <i
                          className="las la-folder-open"
                          style={{
                            fontSize: '2em',
                            color: '#8181A5',
                          }}
                        ></i>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: '0.8em',

                          fontWeight: 'bold',
                          color: colors.grey,
                          textAlign: 'center',
                        }}
                      >
                        {t('Assets Manage')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Fade>
            </Modal>
          </Grid>
          {isScreenLock && (
            <div
              style={{
                position: 'absolute',
              }}
            >
              <LockScreenView
                isModalOpen={isScreenLock}
                closeModal={() => setIsScreenLock(false)}
                name={
                  userInfo.currentRole &&
                  userInfo.currentRole.user_details &&
                  userInfo.currentRole.user_details.name
                }
                user_pic={userInfo?.currentRole?.user_details?.profile_pic}
                user_name={userName}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(Shell);
