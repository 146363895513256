import React from 'react';
import BackdropMUI from '@mui/material/Backdrop';
import { Loader } from './icons/Loader';

export function Backdrop({ open }) {
  return (
    <BackdropMUI
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <Loader height={70} width={70} />
    </BackdropMUI>
  );
}
