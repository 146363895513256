import { FormControlLabel } from '@material-ui/core';
import { Checkbox } from '@mui/material';
import React from 'react';
import { useTranslate } from '../../hooks';

export default function CheckboxesList({
  data = [],
  selectedData = [],
  header: { label, onSelectAll },
  onChange,
  className = '',
  listClassName = '',
  itemClassName = '',
  checkBoxClassName = '',
  size = 'medium',
  labelClassName = '',
}) {
  const { translate } = useTranslate();

  const isSelectAll = data?.length > 0 && selectedData?.length === data?.length;

  return (
    <div className={className}>
      {label ? (
        <div className="p-1 bg-base-gray rounded-md">
          <FormControlLabel
            control={
              <Checkbox
                className="mx-2"
                checked={isSelectAll}
                onChange={() => onSelectAll?.(!isSelectAll)}
                size={size}
              />
            }
            label={
              <div className={`text-sm ${labelClassName}`}>
                {translate(label)}
              </div>
            }
          />
        </div>
      ) : null}
      <div className={`flex flex-col mt-4 overflow-auto ${listClassName}`}>
        {data.map((item) => {
          const checked = selectedData?.some((_item) => +_item === +item.id);

          return (
            <FormControlLabel
              key={item.id}
              className={itemClassName}
              control={
                <Checkbox
                  className={`mx-2 ${checkBoxClassName}`}
                  checked={checked}
                  onChange={() => onChange?.(item.id, !checked)}
                  size={size}
                />
              }
              label={
                <div className={`text-sm w-full ${labelClassName}`}>
                  {item?.label}
                </div>
              }
            />
          );
        })}
      </div>
    </div>
  );
}
