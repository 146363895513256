import { useUpdate } from '../../../../../../../api/react-query';

export function useEditOTAutomationStatus(params = {}) {

  const { mutateAsync, isLoading: isEditing } = useUpdate({
    url: 'config/company/overtime-auto/status',
  });

  return {
    editOTAutomationStatus: mutateAsync,
    isEditing,
  };
}
