import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import { useGetTableLayout } from '../../../../hooks';
import { useSearch, useTranslate } from '../../../../../../hooks';

import { baseDateFormatting } from '../../../../../../helpers/utils';

import DefaultTableBodyCell from '../../../../../../components/table/DefaultTableBodyCell';
import { DateField, Table } from '../../../../../../components';
import ActionField from './components/ActionField';
import SearchSeaction from './components/SearchSeaction';
import LoaderCotainer from '../../../components/LoaderCotainer';
import { Modal, Tooltip, Typography } from '@mui/material';
import EditOvertimeConfPage from './components/EditOvertimeConfPage';
import { useApp } from '../../../../../../context';
import _ from 'lodash';
import AllowanceCell from './components/AllowanceCell';
import { useDeleteOvertimeConfigType, useEditOTAutomationStatus, useEditOvertimeConfigType, useListAllowanceTypes, useListOvertimeConfiTypes, useListShiftTypes, useOTAutomationStatus } from './api';

function OvertimeConf() {
  const [editModal, setEditModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [id, setId] = useState('')
  const [status, setStatus] = useState()
  const { companyId } = useApp()

  const { translate } = useTranslate();
  const navigate = useNavigate();
  const [titleSearch, setTitleSearch] = useSearch('');
  const { layout } = useGetTableLayout({ type: 1 });
  const { overtimeConfigs, count, overtimeConfigsLoading, refetch } = useListOvertimeConfiTypes({
    searchName: titleSearch
  })
  const {OTAutomation, refetch:fetchAutomationStatus } = useOTAutomationStatus({})
  const { allowanceTypes } = useListAllowanceTypes({
    company_id: companyId
  })
  const { editOvertimeConfig, isEditing } = useEditOvertimeConfigType({
    id: id
  })
  const { editOTAutomationStatus } = useEditOTAutomationStatus({})
  const { shiftTypes, shiftTypeLoading } = useListShiftTypes({})

  const { deleteOvertimeConfig, isDeleting } = useDeleteOvertimeConfigType({})
  //edit modal
  const toggleEditModal = () => {
    setEditModal((state) => !state);
  };

  const handleEditModal = (data) => {
    setModalData(() => data);
    toggleEditModal();
  };

  useEffect(() => {
    if (OTAutomation) {
      setStatus(OTAutomation?.overtime_automation)
    }
  }, [OTAutomation])

  const handleAutomationStatus = (data) => {
    editOTAutomationStatus({ value: data })
      .then((res) => {
        fetchAutomationStatus()
      })
  }
  const onEditEmpType = (data) => {
    setId(data?.id)
    let payload = {
      shiftTypeId: data?.shiftType?.id,
      amount: data?.amount,
      amountType: data?.amountType,
      salaryComponent: data?.amountType == 0 ? data?.salaryComponent : [],
      minOTHours: data?.minOTHours,
      maxOTHours: data?.maxOTHours,
      shiftCode: data?.shiftCode,
      dailySalHrs: data?.dailySalHrs
    }
    editOvertimeConfig({ overtimeConfig: payload }).then(() => {
      toggleEditModal();
      refetch();
    });
  };

  const onDelete = ({ data }) =>
    deleteOvertimeConfig(data?.id).then(() => refetch());

  //table columns config
  const columns = useMemo(
    () => [
      {
        name: 'typeId',
        label: translate('Type Id'),
        options: {
          customBodyRender: (value) => {
            return <DefaultTableBodyCell value={value} />;
          },
        },
      },
      {
        name: 'shiftType',
        label: translate('Shift Type'),
        options: {
          customBodyRender: (value) => {
            return <DefaultTableBodyCell value={value} />;
          },
        },
      },
      {
        name: 'minOTHour',
        label: translate('Min OT Hour'),
        options: {
          customBodyRender: (value) => {
            return <DefaultTableBodyCell value={value} />;
          },
        },
      },
      {
        name: 'maxOTHour',
        label: translate('Max OT Hour'),
        options: {
          customBodyRender: (value) => {
            return <DefaultTableBodyCell value={value} />;
          },
        },
      },
      {
        name: 'dailySalHrs',
        label: translate('Daily Salary Hour'),
        options: {
          customBodyRender: (value) => {
            return <DefaultTableBodyCell value={value} />;
          },
        },
      },
      {
        name: 'amount',
        label: translate('Amount'),
        options: {
          customBodyRender: (value) => {
            return <DefaultTableBodyCell value={value} />;
          },
        },
      },
      {
        name: 'salaryComponent',
        label: translate('Allowances'),
        options: {
          customBodyRender: (value) => {
            console.log(value)
            return (
             <AllowanceCell value={value}/>
            )
          },
        },
      },
      {
        name: 'created_at',
        label: translate('Created At'),
        options: {
          customBodyRender: (value) => {
            return <DateField value={value} format={baseDateFormatting} />;
          },
        },
      },
      {
        name: 'updated_at',
        label: translate('Updated At'),
        options: {
          customBodyRender: (value) => {
            return <DateField value={value} format={baseDateFormatting} />;
          },
        },
      },
      {
        name: 'action',
        label: translate('Action'),
        options: {
          setCellHeaderProps: () => ({
            align: 'center',
            style: { fontWeight: '600' },
          }),
        },
      },
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  //formating table api resoponse data
  const tableData = useMemo(
    () => {
      const res = overtimeConfigs?.map((item) => {
        let getAllowance = [];
        const allowanceIds = (item?.allowanceId || []).map(Number)
        if (allowanceIds) {
          getAllowance = allowanceTypes?.filter(allowance => allowanceIds?.includes(allowance?.id));
        }
        return {
          typeId: item?.id,
          shiftType: item?.shiftTypeName,
          minOTHour: item?.minOTHour,
          maxOTHour: item?.maxOTHour,
          dailySalHrs: item?.dailySalHrs,
          salaryComponent: getAllowance,
          amount: item?.amountType == 0 ? `${item?.amount}%` : item?.amount,
          created_at: item?.created_at,
          updated_at: item?.updated_at,
          action: (
            <ActionField
              onEdit={() => {
                handleEditModal(item);
              }}
              onReject={() => onDelete({ data: item })}
              isRejecting={isDeleting}
              className="justify-center"
              newCancelLayout={true}
            />
          ),
        };
      });

      return res;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // [data, isDeleting],
  );

  const onCreate = () => {
    navigate('create', {
      state: { compEmpTypeList: "data" },
    });
  };

  return (
    <div>
      <SearchSeaction
        handleSearchTitle={setTitleSearch}
        openAddDesignation={onCreate}
        status={status}
        handleAutomationStatus={handleAutomationStatus}
      />
      {overtimeConfigsLoading ? (
        <LoaderCotainer />
      ) : (
        <div className="mt-4">
          <Table
            columns={columns}
            data={tableData || []}
            count={count}
            options={{ ...layout }}
          />
        </div>
      )}

      <Modal open={editModal} onClose={toggleEditModal}>
        <EditOvertimeConfPage
          toggleModal={toggleEditModal}
          customHandler={onEditEmpType}
          seletedData={modalData}
          isEditing={isEditing}
          shiftTypes={shiftTypes}
          shiftTypeLoading={shiftTypeLoading}
          allowanceTypes={allowanceTypes}
        />
      </Modal>
    </div>
  );
}

export default OvertimeConf;
