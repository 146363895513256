import ToggleButton from '../../../../../components/toggle/ToggleButton';
import { useTranslation } from 'react-i18next';
import {
  useUnPublishedCycles,
  useUpdatePublished,
} from '../../../../../api/PMS_APIs/assignedKpi';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import ConfirmPublishedCycleModal from '../../Modals/ConfirmPublishedCycleModal/ConfirmPublishedCycleModal';
import { DateField } from '../../../../../components';
import { baseDateFormatting } from '../../../../../helpers/utils';

const Published = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [isConfirmPublished, setIsConfirmPublished] = useState(false);
  const [cyclePublishedData, setCyclePublishedData] = useState({});
  const [filters, setFilters] = useState({});
  const [checkedIndex, setCheckedIndex] = useState(null);

  const { data: cycles } = useUnPublishedCycles(filters);

  const { mutateAsync: updateCyclePublished } = useUpdatePublished({
    msg: t('published cycle updated'),
  });

  const handleFilters = async (event) => {
    setFilters({
      search: event.target.value,
    });
  };

  const handleConfirm = (id, value, index) => {
    setCheckedIndex(index);
    setIsConfirmPublished(true);

    setCyclePublishedData({
      id: id,
      published: value,
    });
  };

  const handleClose = () => {
    setIsConfirmPublished(false);
    setCheckedIndex(null);
  };

  const handlePublished = async () => {
    await updateCyclePublished(cyclePublishedData);
    setIsConfirmPublished(false);
    setCheckedIndex(null);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex mb-5 justify-center">
      <div className=" border  bg-white shadow rounded w-full">
        <div className="py-5 px-4 mb-8  flex gap-3">
          <button className="" onClick={handleBack}>
            <i className="las la-arrow-left text[16px] font-bold"></i>
          </button>
          <h2 className="font-bold text-lg">{t('Mark Cycle as Published')}</h2>
        </div>
        <div className="flex justify-center">
          <div className="bg-gray-200 py-1 px-4  w-2/3 rounded-t-md">
            <div className="flex gap-2 border-gray-300 bg-gray-50 rounded-lg my-5 px-2 py-1 w-72 border">
              <div className="h-6">
                <i className="las la-search text-2xl text-gray-300"></i>
              </div>
              <input
                type="text"
                className="bg-gray-50 border-0 outline-0 h-8 w-64"
                placeholder={t('Search here...')}
                onChange={async (event) => await handleFilters(event)}
              />
            </div>
          </div>
        </div>
        <div className="h-[38rem] flex justify-center">
          <div className="w-2/3 border border-t-0  my-0 overflow-y-auto">
            <div className="px-4 mt-6">
              {cycles.length >= 0 ? (
                cycles?.map((cycle, index) => (
                  <div
                    className="flex justify-between gap-4 bg-gray-50 p-2 shadow-md rounded-md my-2 w-full"
                    key={cycle.id}
                  >
                    <div className="text-md font-bold w-80 ">{cycle.title}</div>
                    <div className="flex gap-1 text-gray-400">
                      <span className="font-bold">
                        <DateField
                          value={cycle.start_date}
                          format={baseDateFormatting}
                        />
                      </span>
                      -
                      <span className="font-bold">
                        <DateField
                          value={cycle.end_date}
                          format={baseDateFormatting}
                        />
                      </span>
                    </div>
                    <div>
                      <ToggleButton
                        handleCheck={(e) => {
                          handleConfirm(cycle.id, e.target.checked, index);
                        }}
                        checked={checkedIndex === index}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <h2 className="font-bold text-center my-10">Oops ! No data</h2>
              )}
            </div>
          </div>
        </div>
      </div>
      {isConfirmPublished && (
        <ConfirmPublishedCycleModal
          isOpen={isConfirmPublished}
          onClose={handleClose}
          handlePublished={handlePublished}
        />
      )}
    </div>
  );
};

export default Published;
