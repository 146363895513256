import React, { useEffect, useState } from 'react';
import { preferenceService } from '../../services/preferences';
import ActivityLoader from '../ActivityLoader';
import { Card, CardContent, Container } from '@mui/material';
import { CustomToast } from '../../common/Methodcommon';
import _ from 'lodash';
import { useNavigate } from 'react-router';
import { useList } from '../../api/react-query';
import { Backdrop } from '../../components';
import { EMPortal } from '../../common/constants';
import company from '../../api/company';

const styles = {
  box: {
    width: 700,
    height: 700,
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    left: '50%',
    top: '51%',
    transform: 'translate(-50%, -50%)',
  },
  text: {
    fontSize: 34,
    fontWeight: 200,
    color: '#7C0E6F',
    marginLeft: 20,
  },
};
export default function ViewController(props) {
  const navigate = useNavigate();

  const isFav = () => {};
  let menuItems = [
    {
      icon: null,
      title: 'Main',
      link: null,
    },
    {
      icon: <i className="las la-chart-pie" style={{ fontSize: '1.2em' }}></i>,
      title: 'Dashboard',
      link: '/company/dashboard',
      header: 'Main',
      is_fav: isFav('/company/dashboard'),
    },

    {
      icon: <i className="las la-user-cog" style={{ fontSize: '1.2em' }}></i>,
      title: 'HR Admin',
      link: '/company/HRAdmin',
      header: 'Main',
      is_fav: isFav('/company/HRAdmin'),
    },
    {
      icon: <i className="las la-user" style={{ fontSize: '1.2em' }}></i>,
      title: 'Employee Data',
      link: '/company/EmployeeData',
      is_fav: isFav('/company/EmployeeData'),
      header: 'Main',
    },
    {
      icon: <i className="las la-user" style={{ fontSize: '1.2em' }}></i>,
      title: 'hire-employee',
      link: '/company/HireEmployee',
      is_fav: isFav('/company/EmployeeData'),
      header: 'Main',
    },
    {
      icon: <i className="las la-shield-alt" style={{ fontSize: '1.2em' }}></i>,
      title: 'Attendance',
      mainMenu: '/company/Attendance/AttendanceList',
      is_fav: isFav('/company/EmployeeData'),
      subMenu: [
        {
          icon: <i className="las la-circle" style={{ fontSize: '0.5em' }}></i>,
          title: 'Attendance List',
          link: '/company/Attendance/AttendanceList',
        },
        {
          icon: <i className="las la-circle" style={{ fontSize: '0.5em' }}></i>,
          title: 'Week Off',
          link: '/company/Attendance/WeekOff',
        },
        {
          icon: <i className="las la-circle" style={{ fontSize: '0.5em' }}></i>,
          title: 'Time Shift',
          link: '/company/Attendance/TimeShift',
        },
        {
          icon: <i className="las la-circle" style={{ fontSize: '0.5em' }}></i>,
          title: 'Shift & Schedule',
          link: '/company/Attendance/ShiftAndSchedule',
        },
        {
          icon: <i className="las la-circle" style={{ fontSize: '0.5em' }}></i>,
          title: 'Holidays',
          link: '/company/Attendance/Holidays',
        },
        {
          icon: <i className="las la-circle" style={{ fontSize: '0.5em' }}></i>,
          title: 'geofencing',
          link: '/company/Attendance/GeofencingLocation',
        },
      ],

      header: 'Main',
    },
    {
      icon: <i className="las la-user" style={{ fontSize: '1.2em' }}></i>,
      title: 'Leave Allotment',
      link: '/company/Attendance/leave-allotment',
      is_fav: isFav('/company/Attendance/leave-allotment'),
      header: 'Main',
    },
    {
      icon: (
        <i
          className="las la-bullhorn"
          color="#8F95B2"
          style={{ fontSize: '1.2em' }}
        ></i>
      ),
      title: 'Announcement',
      link: '/company/Announcement',
      is_fav: isFav('/company/Announcement'),

      header: 'Main',
    },
    {
      icon: (
        <i
          className="las la-tasks"
          color="#8F95B2"
          style={{ fontSize: '1.2em' }}
        ></i>
      ),
      title: 'To-Do Task',
      link: '/company/ToDoTasks',
      is_fav: isFav('/company/ToDoTasks'),

      header: 'Main',
    },
    {
      icon: <i className="las la-bullseye" style={{ fontSize: '1.2em' }}></i>,
      title: 'Action Required',
      link: '/company/action-required',
      is_fav: isFav('/company/action-required'),

      header: 'Main',
    },

    {
      icon: null,
      title: 'Admin Support',
      link: null,
    },
    {
      icon: <i className="las la-pen-alt" style={{ fontSize: '1.2em' }}></i>,
      title: 'Assets',
      link: '/company/Assets',
      header: 'Admin Support',
      is_fav: isFav('/company/Assets'),
    },
    {
      icon: <i className="las la-briefcase" style={{ fontSize: '1.2em' }}></i>,
      title: 'Docs Management',
      link: '/company/PROWork',
      is_fav: isFav('/company/PROWork'),

      header: 'Admin Support',
    },
    {
      icon: <i className="las la-briefcase" style={{ fontSize: '1.2em' }}></i>,
      title: 'Training',
      link: '/company/Training',
      is_fav: isFav('/company/Training'),

      header: 'Admin Support',
    },
    {
      icon: null,
      title: 'Letter Generation',
      link: null,
    },
    {
      icon: <i className="las la-signature" style={{ fontSize: '1.2em' }}></i>,
      title: 'Signatures',
      link: '/company/Signatures',
      is_fav: isFav('/company/Signatures'),
      header: 'Letter Generation',
    },
    {
      icon: <i className="las la-stamp" style={{ fontSize: '1.2em' }}></i>,
      title: 'Company Stamps',
      link: '/company/stamps',
      is_fav: isFav('/company/stamps'),

      header: 'Letter Generation',
    },
    {
      icon: <i className="las la-file-alt" style={{ fontSize: '1.2em' }}></i>,
      title: 'Company Letters',
      link: '/company/LetterGenerator',
      is_fav: isFav('/company/LetterGenerator'),

      header: 'Letter Generation',
    },
    {
      icon: null,
      title: 'Payroll',
      link: null,
    },
    {
      icon: <i className="las la-chart-bar" style={{ fontSize: '1.2em' }}></i>,
      title: 'Dashboard',
      link: '/company/Payrolldashboard',
      is_fav: isFav('/company/Payrolldashboard'),

      header: 'Payroll',
    },
    {
      icon: (
        <i
          className="las la-hand-holding-usd"
          style={{ fontSize: '1.2em' }}
        ></i>
      ),
      title: 'Payroll Settlement',
      link: '/company/PayrollSettlement',
      is_fav: isFav('/company/PayrollSettlement'),

      header: 'Payroll',
    },
    {
      icon: <i className="las la-tools" style={{ fontSize: '1.2em' }}></i>,
      title: 'Payroll Setup',
      link: '/company/PayrollSetup',
      is_fav: isFav('/company/PayrollSetup'),

      header: 'Payroll',
    },
    {
      icon: (
        <i className="las la-user-shield" style={{ fontSize: '1.2em' }}></i>
      ),
      title: 'Payroll Admin',
      link: '/company/PayrollAdmin',
      is_fav: isFav('/company/PayrollAdmin'),

      header: 'Payroll',
    },
    {
      icon: (
        <i
          className="las la-file-invoice-dollar"
          style={{ fontSize: '1.2em' }}
        ></i>
      ),
      title: 'FnF',
      link: '/company/FnF',
      is_fav: isFav('/company/FnF'),

      header: 'Payroll',
    },
    {
      icon: null,
      title: 'Reports',
      link: null,
    },
    {
      icon: <i className="las la-chart-area" style={{ fontSize: '1.2em' }}></i>,
      title: 'Attendance Report',
      link: '/company/AttendanceReport',
      is_fav: isFav('/company/AttendanceReport'),

      header: 'Reports',
    },
    {
      icon: <i className="las la-chart-bar" style={{ fontSize: '1.2em' }}></i>,
      title: 'Payroll Report',
      link: '/company/PayrollReport',
      is_fav: isFav('/company/PayrollReport'),

      header: 'Reports',
    },
    // {
    //   icon: <i className="las la-chart-bar" style={{ fontSize: '1.2em' }}></i>,
    //   title: 'AI Report',
    //   link: '/company/AIReport',
    //   is_fav: isFav('/company/AIReport'),

    //   header: 'Reports',
    // },
    {
      icon: null,
      title: 'Super Admin',
      link: null,
    },
    {
      icon: <i className="las la-city" style={{ fontSize: '1.2em' }}></i>,
      title: 'Company',
      link: '/company/company-setup',
      is_fav: isFav('/company/company-setup'),

      header: 'Super Admin',
    },
    {
      icon: (
        <i className="las la-project-diagram" style={{ fontSize: '1.2em' }}></i>
      ),
      title: 'Org Structure',
      link: '/company/organisation-structure',
      is_fav: isFav('/company/organisation-structure'),

      header: 'Super Admin',
    },
    {
      icon: (
        <i className="las la-network-wired" style={{ fontSize: '1.2em' }}></i>
      ),
      title: 'Workflow',
      link: '/company/workflows',
      is_fav: isFav('/company/workflows'),

      header: 'Super Admin',
    },

    {
      icon: null,
      title: 'Performance',
      link: null,
    },
    {
      icon: <i className="las la-chart-area" style={{ fontSize: '1.2em' }}></i>,
      title: 'Emp. Performance',
      link: '/company/EmployeePerfomance',
      header: 'Performance',
    },
    {
      icon: <i className="las la-chart-area" style={{ fontSize: '1.2em' }}></i>,
      title: 'Configuration',
      link: '/company/PerfomanceConfiguration',
      header: 'Performance',
    },
    {
      icon: <i className="las la-file-alt" style={{ fontSize: '1.2em' }}></i>,
      title: 'Reports',
      link: '/company/report',
    },
    {
      icon: null,
      title: 'Others',
      link: null,
    },
    {
      icon: <i className="lab la-orcid" style={{ fontSize: '1.2em' }}></i>,
      title: 'Reference Id',
      link: '/company/ReferencesId',
      is_fav: isFav('/company/ReferencesId'),
      header: 'Others',
    },
  ];

  const [employeeId, setEmployeeId] = useState(null);

  const { isLoading } = useList(
    'auth/employee-portal-token',
    {},
    {
      enabled: !!employeeId,
      onSuccess: async (data) => {
        const res = await company.logout();

        if (res) {
          window.location.href = `${EMPortal}/gotoEmployee?id=${employeeId}&portal=true&token=${
            data?.data.portalToken
          }&locale=${localStorage.getItem('applang')}`;

          setEmployeeId(null);
          localStorage.clear();
        }
      },
    },
  );

  useEffect(() => {
    const user_id = localStorage.getItem('__active__user__id');
    const company_id = localStorage.getItem('__current_company_id');
    const user_info = localStorage.getItem('__user__info');

    const activeUserInfo = JSON.parse(
      localStorage.getItem('__active__user__role'),
    );

    let url_default = '/company/dashboard';
    const parsed = JSON.parse(user_info);
    const emp_id = parsed?.currentRole?.emp_id;

    if (!activeUserInfo) {
      navigate('/login');
      return;
    }

    if (activeUserInfo?.acl?.length > 0) {
      const screen = activeUserInfo.acl[0]['screen_name'];
      const screens = _.find(menuItems, function (d) {
        return d.title === screen;
      });
      if (screens) {
        url_default = screens.link ? screens.link : screens.mainMenu;
        window.location.replace(url_default);
      }
    } else if (!parsed?.isAdmin) {
      setEmployeeId(emp_id);
    } else {
      if (parsed?.isAdmin) {
        preferenceService
          .getPreference(user_id, company_id)
          .then((res) => {
            const url = _.find(res, function (df) {
              return df.preference_name === 'FAV_SCREEN';
            });
            if (url) {
              window.location.replace(url.value);
            } else {
              window.location.replace(url_default);
            }
          })
          .catch((err) => {
            CustomToast('error', err);
          });
      } else {
        navigate('/login');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Backdrop open />;
  }

  return (
    <div>
      <Container>
        <Card elevation={0} style={styles.box}>
          <CardContent>
            <ActivityLoader />
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
