import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import api from '../../core';
import { API_VERSION } from '../constants';
import { useApp } from '../../../context';

export const useList = (url, params = {}, config, apiVersion = API_VERSION) => {
  const _params = {};
  const { companyId, rowsPerPage } = useApp();
  const [search] = useSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value) _params[key] = value;
  });

  _params['companyId'] = companyId;
  _params['page'] = params?.page ?? +search.get('page') + 1;
  _params['locale'] = localStorage.getItem('applang');
  _params['limit'] = params?.limit ?? rowsPerPage;

  const context = useQuery(
    [url, _params],
    ({ queryKey }) => fetcher({ queryKey, apiVersion }),
    {
      enabled:
        config?.enabled !== undefined ? (url ? true : false) : config?.enabled,
      ...config,
    },
  );

  return context;
};

export const fetcher = async ({ queryKey, apiVersion }) => {
  const [url, params] = queryKey;
  return await api.get(`${apiVersion}/${url}`, {
    params: { ...params },
  });
};
