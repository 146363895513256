import React, { useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { useSearchParams } from 'react-router-dom';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import { useApp } from '../../context';
import { options } from '../../helpers/utils';
import { pagination_list } from '../../common/enum';
import { Loader } from '../icons/Loader';
import { useTranslate } from '../../hooks';

export default function Table({
  columns,
  data,
  count,
  options: customOptions = {},
  hidePagination = false,
  customRef,
  isLoadingData,
  onRowPerPage,
  setPage,
  page,
}) {
  const { changeRowsPerPage, rowsPerPage } = useApp();
  const { translate } = useTranslate();
  const [search, setSearch] = useSearchParams();

  const onChangeRowsPerPage = (numberOfRows) => {
    changeRowsPerPage(numberOfRows);

    if (onRowPerPage) onRowPerPage(numberOfRows);
    search.set('page', 0);

    setSearch(search, {
      replace: true,
    });
  };
  return (
    <MUIDataTable
      ref={customRef}
      columns={columns}
      data={data}
      options={{
        ...options,
        serverSide: true,
        page: +search.get('page'),
        rowsPerPage,
        pagination: !hidePagination,
        count,
        sort: false,
        rowsPerPageOptions: pagination_list.table,
        onChangePage: (page) => {
          search.set('page', page);

          setSearch(search, {
            replace: true,
          });
          if(setPage) setPage(page)
        },
        onChangeRowsPerPage,
        tableBodyMaxHeight: '67dvh',
        textLabels: {
          body: {
            noMatch: isLoadingData ? (
              <Loader width={50} height={50} />
            ) : (
              <div className="flex flex-col items-center text-gray-500">
                <FilterAltIcon className="!w-8 !h-8" />
                <div className="font-bold">{translate('No Data Found')}</div>
              </div>
            ),
          },
        },
        ...customOptions,
      }}
    />
  );
}
