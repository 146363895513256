import React from 'react';
import { DefaultHumanAvatar } from '../../../../common/constants';

export const EmployeeProfile = ({ name, profileImage }) => {
  return (
    <div className="flex justify-center gap-x-3 items-center">
      <img
        className="rounded-full w-10 h-10 border"
        src={profileImage || DefaultHumanAvatar}
        alt=""
      />
      <span>{name}</span>
    </div>
  );
};
