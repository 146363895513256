import React from 'react';

import { convertUtcToDate } from '../helpers/utils';
import moment from 'moment';

function hasTimezone(dateString) {
  return dateString?.toString()?.includes('Z');
}

export function DateField({
  value,
  format,
  className = 'text-xs',
  convertToUTC = false,
  offset,
}) {
  let result = null;
  let _convertToUTC = convertToUTC;

  if (!convertToUTC && hasTimezone(value)) {
    _convertToUTC = true;
  }

  if (moment(value).isValid()) {
    result = convertUtcToDate(value, format, offset, _convertToUTC);
  } else {
    result = convertUtcToDate(
      `1970-01-01 ${value}`,
      format,
      offset,
      _convertToUTC,
    );
  }

  return <p className={className}>{result}</p>;
}
