import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from 'react';
import { authz } from '../../services/authz';
import UserContext from '../../context/UserContext';
import './initializer.css';
import { Card, CardContent, Container } from '@material-ui/core';
import { useContext } from 'react';
import organisation from '../../api/organisation';
import ActivityLoader from '../ActivityLoader';
import { Toaster, toast } from 'react-hot-toast';
import { getCompanyCurrency } from '../../api/core';
import company from '../../api/company';
import { BaseURL } from '../../common/constants';
import { useNavigate } from 'react-router';

const styles = {
  box: {
    width: 700,
    height: 700,

    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    position: 'absolute',
    left: '50%',
    top: '51%',
    transform: 'translate(-50%, -50%)',
  },
  text: {
    fontSize: 34,
    fontWeight: 200,
    color: '#7C0E6F',
    marginLeft: 20,
  },
};
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Initializer() {
  const navigate = useNavigate();

  const {
    updateUserInfo,
    updateActiveBU,
    updateActiveUserRole,
    setUserContextDirty,
    setUserContextReady,
    updateActiveUserId,
    updateNotifications,
  } = useContext(UserContext);

  const logout = () => {
    document.cookie =
      'laravel_session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    company
      .logout()
      .then(() => {
        localStorage.clear();
        window.location.replace(BaseURL);
      })
      .catch((err) => {
        window.location.replace(BaseURL);
      });
  };
  useEffect(() => {
    const queryString = window.location.search;
    let user_id = null;
    if (queryString) {
      const urlParams = new URLSearchParams(queryString);
      user_id = urlParams.get('user_id');
    }
    localStorage.setItem('userId', user_id);
    authz
      .fetchUserContextService(user_id)
      .then((result) => {
        const usrInfo = result;
        const defaultBU = result.currentRole.defaultBU;
        const defaultRole = result.currentRole;
        const activeBUAccess = result.company_wise_access[defaultBU];

        if (result.currentRole.defaultBU) {
          getCompanyCurrency(result.currentRole.defaultBU)
            .then((res) => {
              localStorage.setItem('company_currency', res?.currency?.name);
              localStorage.setItem(
                'company_currency_ar',
                res?.currency?.name_ar,
              );
            })
            .catch((err) => console.log(err));
        }

        const activeBUInfo = JSON.stringify(activeBUAccess);
        const activeUserRole = JSON.stringify(defaultRole);
        const userKundali = JSON.stringify(usrInfo);

        localStorage.setItem('__user__info', userKundali);
        localStorage.setItem('__active__user__role', activeUserRole);
        localStorage.setItem('__active__BU__info', activeBUInfo);
        localStorage.setItem('__active__user__id', user_id);
        localStorage.setItem(
          '__current_company_id',
          defaultBU
            ? defaultBU
            : activeBUAccess?.settings?.company_setting?.company_id
            ? activeBUAccess.settings?.company_setting?.company_id
            : activeBUAccess.settings?.company_id,
        );

        organisation
          .getMyNotifications(user_id, defaultBU)
          .then((result) => {
            const ns = _.map(result, function (d) {
              return {
                title: d.notifiable_type,
                description: d.attachments ? d.attachments : null,
                time: moment(d.created_at).fromNow(),
                is_read: d.read_at === 1 ? true : false,
                id5: d.id,
                type: d.type,
                datafrom: d.datafrom,
                profilepic: d?.ProfilePic,
                notifiable_for_username: d?.notifiable_for_username,
                created_at: d?.created_at,
              };
            });

            updateNotifications(ns);
          })
          .catch((err) => {
            console.log(err);
          });
        updateUserInfo(usrInfo);
        updateActiveUserRole(defaultRole);
        updateActiveBU(activeBUAccess);
        setUserContextDirty(false);
        setUserContextReady(true);
        updateActiveUserId(user_id);

        navigate('/company/switch-view');
      })
      .catch((er) => {
        toast.error(er?.response?.data?.data);
        logout();
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Toaster
        toastOptions={{
          style: {
            maxWidth: 500,
          },
        }}
      />
      <Card elevation={0} style={styles.box}>
        <CardContent>
          <ActivityLoader />
        </CardContent>
      </Card>
    </Container>
  );
}
