export const getItemId = ({ seletedItem, list, selectertKey, returnField }) => {
  const filtered = list?.find((item) => item[selectertKey] === seletedItem);
  return filtered?.[returnField];
};

export const checkData = ({ data, list }) => {
  if (list?.find((item) => item?.name === data?.name)) {
    throw new Error('Item already exist');
  }
};

export const removeDuplicates = ({ existingList, suggetionsList }) => {
  // eslint-disable-next-line array-callback-return
  return suggetionsList?.filter((item) => {
    if (!item?.immutable && !existingList.includes(item.name)) {
      return item;
    }
  });
};

export const converBooleanToString = (value) => (value === true ? 'yes' : 'no');
export const converNumberToBoolean = (value) => (value === 1 ? true : false);
