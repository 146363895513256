import api from './core';

const company = {
  getDetails,
  getCountries,
  getStates,
  getCities,
  getStateById,
  getCityById,
  updateBasicInfo,
  getCurrencies,
  updateCompanySettings,
  updateBrandSettings,
  updateUser,
  updateStatus,
  updatePassword,
  logout,
  getBillingAddress,
  addBillingAddress,
  updateBillingAddress,
  getAllDepartments,
  updateLeaveSettings,
  getContractTypes,
  getLeaveTypes,
  getAllowanceTypes,
  updateLocationSettings,
  getAddedLocationList,
  deleteLocationType,
  petchLocationSettings,
  getCompanyGender,
  getCompanyMaritalStatus,
  getCompanyBenefits,
  getlocationBycompanyId,
  getEmployeeTypecompanyId,
  getEmployeeCategorycompanyId,
  getContractTypecompanyId,
  getGradecompanyId,
  getPayrollTypecompanyId,
  getTicketFrequencycompanyId,
  getRelationshipTypecompanyId,
  getInsurancePoliciesByInsuranceId,
  postLocationBlockList,
  getEmpBlockedLocationList,
  getBlockedLocationList,
  petchBlockedLocation,
  getEmployeDetailscompanyId,
  updateBanksettings,
  updateAttendancesetting,
  getcompanypackageList,
  getEnablegeogencelocEmp,
};

function getDetails(id) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/company/getdetails/${id}?locale=` +
          localStorage.getItem('applang'),
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getCountries() {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/location/selectCountries?locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function updateLeaveSettings(payload) {
  return new Promise((resolve, reject) => {
    api
      .put(`/v1/company/updateLeaveSettings`, payload)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getContractTypes(id) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/contractTypes?company_id=${id}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getLeaveTypes(id) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/getCompanyLeaveTypes?company_id=${id}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getAllowanceTypes(id) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/getAllowanceTypes?company_id=${id}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getStates(countryId) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/location/selectStates/id/${countryId}?locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getCities(stateId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/location/selectCity/id/${stateId}`)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getStateById(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/location/getStateById/id/${id}`)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getCityById(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/location/getCityById/id/${id}`)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function updateBasicInfo(payload) {
  return new Promise((resolve, reject) => {
    api
      .put(
        `/v1/company/updateinfo?locale=` + localStorage.getItem('applang'),
        payload,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getCurrencies() {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/currencies`)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function updateCompanySettings(payload) {
  return new Promise((resolve, reject) => {
    api
      .put(`/v1/company/updatesettings`, payload)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function updateBrandSettings(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`/v1/company/updatebrandimages`, payload)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

//location setting details
function updateLocationSettings(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(
        `/v1/locations/add?locale=${localStorage.getItem('applang')}`,
        payload,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getAddedLocationList(company_ID) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/locations?company_id=${company_ID}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function deleteLocationType(LocationId) {
  return new Promise((resolve, reject) => {
    api
      .delete(
        `/v1/locations/remove?id=${LocationId}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function petchLocationSettings(payload) {
  return new Promise((resolve, reject) => {
    api
      .put(
        `/v1/locations/update?locale=${localStorage.getItem('applang')}`,
        payload,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function postLocationBlockList(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`/v1/locations/block`, payload)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getEmpBlockedLocationList(company_ID) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/locations/list?company_id=${company_ID}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getBlockedLocationList(LocID) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/locations/list?location_id=${LocID}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function petchBlockedLocation(payload) {
  return new Promise((resolve, reject) => {
    api
      .put(`/v1/locations/unblock`, payload)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

//location setting details end

function updateUser(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(
        `/v1/company/updateuser?locale=${localStorage.getItem('applang')}`,
        payload,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function updatePassword(payload) {
  return new Promise((resolve, reject) => {
    api
      .put(`/v1/company/updatepassword`, payload)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function updateStatus(payload) {
  return new Promise((resolve, reject) => {
    api
      .put(`/v1/company/setStatus`, payload)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function logout() {
  const companyId = localStorage.getItem('__current_company_id');

  if (!companyId) {
    window.location.href = '/login';
    return new Promise((resolve, reject) => reject(401));
  }

  return new Promise((resolve, reject) => {
    api
      .post(`/logout`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getBillingAddress(cID) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/getBillingAddress?companyId=${cID}`)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getAllDepartments(company_ID) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/department/select?companyId=${company_ID}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function addBillingAddress(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`/v1/addBillingAddress`, payload)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function updateBillingAddress(payload) {
  return new Promise((resolve, reject) => {
    api
      .put(`/v1/updateBillingAddress`, payload)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getCompanyGender(companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/company/getGenders?locale=${localStorage.getItem(
          'applang',
        )}&company_id=${companyId}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getCompanyMaritalStatus(companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/company/getMaritalStatus?company_id=${companyId}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getCompanyBenefits(companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/company/getBenefits?company_id=${companyId}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getlocationBycompanyId(companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/locationByCompanyId?companyId=${companyId}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getEmployeeTypecompanyId(companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/company/getEmployeeTypes?company_id=${companyId}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getEmployeeCategorycompanyId(companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/company/getEmployeeCategories?company_id=${companyId}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getContractTypecompanyId(companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/company/getContractTypes?company_id=${companyId}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getGradecompanyId(companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/company/getGrades?company_id=${companyId}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getPayrollTypecompanyId(companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/company/getPayrollTypes?company_id=${companyId}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getTicketFrequencycompanyId(companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/company/getTicketFrequency?company_id=${companyId}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getRelationshipTypecompanyId(companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/company/getRelationshipType?company_id=${companyId}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getInsurancePoliciesByInsuranceId(insuranceId) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/insurance/getPoliciesByInsurerId?insuranceId=${insuranceId}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getEmployeDetailscompanyId(company_id, userId, payoutypeID) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/company/getEmployeeDetails?company_id=${company_id}&locale=${localStorage.getItem(
          'applang',
        )}&userId=${userId}&bankexctype=${payoutypeID}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function updateBanksettings(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(
        `/v1/company/updatebank?locale=${localStorage.getItem('applang')}`,
        payload,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function updateAttendancesetting(payload) {
  return new Promise((resolve, reject) => {
    api
      .put(
        `/v1/company/updateattendancesetting?locale=${localStorage.getItem(
          'applang',
        )}`,
        payload,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getcompanypackageList(companyID) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/getcompanypackagedetails?company_id=${companyID}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getEnablegeogencelocEmp(companyID) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/getcompanypackagedetails?company_id=${companyID}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
export default company;
