import React from 'react';

export default function Close() {
  return (
    <path
      d="M21216.732-20949.143l-6,6,6-6-6-6,6,6,6-6-6,6,6,6Z"
      transform="translate(-21210.232 20955.643)"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    />
  );
}
