import { useCreate } from '../../../../../../../api/react-query';

export function useCreateOvertimeConfig() {
  const {
    mutateAsync: createOvertimeConfig,
    isLoading: isCreating,
    error,
  } = useCreate({
    url: 'config/overtime-auto',
  });

  return {
    createOvertimeConfig,
    isCreating,
    error,
  };
}
