import { useInfiniteQuery } from 'react-query';

import { API_VERSION } from '../constants';
import { useApp } from '../../../context';
import { fetcher } from './use-list';

export const useInfiniteList = (
  url,
  params = {},
  config,
  apiVersion = API_VERSION,
) => {
  const _params = {};
  const { companyId, rowsPerPage } = useApp();

  Object.entries(params).forEach(([key, value]) => {
    if (value) _params[key] = value;
  });

  _params['companyId'] = companyId;
  _params['locale'] = localStorage.getItem('applang');
  _params['limit'] = params?.limit ?? rowsPerPage;

  const context = useInfiniteQuery(
    [url, _params],
    async ({ queryKey, pageParam = 1 }) => {
      const resolvedQueryKey = [
        queryKey[0],
        { ...queryKey[1], page: pageParam },
      ];
      return await fetcher({ queryKey: resolvedQueryKey, apiVersion });
    },
    {
      enabled:
        config?.enabled !== undefined ? (url ? true : false) : config?.enabled,
      ...config,
      getNextPageParam: (lastPage) => {
        const metaData = lastPage?.data?.meta;
        const hasNextPage = metaData?.current_page < metaData?.last_page;

        if (hasNextPage) return metaData?.current_page + 1;

        return hasNextPage;
      },
    },
  );

  return context;
};
