import React from 'react';

export default function Bell() {
  return (
    <path
      d="M12-18.75a1.449,1.449,0,0,0-1.066.434A1.449,1.449,0,0,0,10.5-17.25l.023.188A5.933,5.933,0,0,0,8.2-15.891,6.007,6.007,0,0,0,6.586-13.84,5.852,5.852,0,0,0,6-11.25V-4.5a.73.73,0,0,1-.211.539.73.73,0,0,1-.539.211H4.5v1.5H9.891a2.258,2.258,0,0,0-.141.75A2.162,2.162,0,0,0,10.418.082,2.162,2.162,0,0,0,12,.75,2.162,2.162,0,0,0,13.582.082,2.162,2.162,0,0,0,14.25-1.5a2.258,2.258,0,0,0-.141-.75H19.5v-1.5h-.75a.73.73,0,0,1-.539-.211A.73.73,0,0,1,18-4.5v-6.539a6.261,6.261,0,0,0-.574-2.637A6.222,6.222,0,0,0,15.82-15.82a5.862,5.862,0,0,0-2.344-1.242l.023-.187a1.449,1.449,0,0,0-.434-1.066A1.449,1.449,0,0,0,12-18.75Zm-.328,3h.469a4.334,4.334,0,0,1,2.2.691,4.541,4.541,0,0,1,1.582,1.723,4.815,4.815,0,0,1,.574,2.3V-4.5a2.258,2.258,0,0,0,.141.75H7.359A2.258,2.258,0,0,0,7.5-4.5v-6.75a4.407,4.407,0,0,1,.551-2.168,4.479,4.479,0,0,1,1.512-1.617A4.5,4.5,0,0,1,11.672-15.75ZM12-2.25a.73.73,0,0,1,.539.211.715.715,0,0,1,.211.527.755.755,0,0,1-.211.539.7.7,0,0,1-.527.223.734.734,0,0,1-.539-.223.734.734,0,0,1-.223-.539.7.7,0,0,1,.223-.527A.741.741,0,0,1,12-2.25Z"
      transform="translate(-4.5 18.75)"
    />
  );
}
