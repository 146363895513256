import React from 'react';

import { Modal } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

import DeleteModal from '../../../../components/DeleteModal';

function ActionField({
  onEdit,
  onReject,
  onView,
  children,
  isRejecting,
  className,
}) {
  const [isOpenConfirmReject, setIsOpenConfirmReject] = React.useState(false);

  const closeModal = () => {
    setIsOpenConfirmReject(false);
  };

  return (
    <>
      <Modal onClose={closeModal} open={isOpenConfirmReject}>
        <DeleteModal
          isSubmitting={isRejecting}
          closeModal={closeModal}
          onSubmit={() => {
            onReject().then(() => closeModal());
          }}
        />
      </Modal>

      <div className={`flex flex-wrap gap-x-3 ${className}`}>
        {onView ? (
          <span
            title="View"
            onClick={onView}
            className="flex justify-center items-center border rounded-md cursor-pointer py-1.5 px-1.5"
          >
            <VisibilityIcon className="text-primary !text-sm" />
          </span>
        ) : null}
        {onEdit ? (
          <span title="Edit" onClick={onEdit}>
            <i className="las la-pen text-success table-icon cursor-pointer"></i>
          </span>
        ) : null}
        {onReject ? (
          <span title="Reject" onClick={() => setIsOpenConfirmReject(true)}>
            <i className="las la-trash-alt text-error table-icon cursor-pointer"></i>
          </span>
        ) : null}
        {children}
      </div>
    </>
  );
}

export default ActionField;
