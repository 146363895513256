import { useMemo } from 'react';

import { useInfiniteList } from '../../list';

export function useListEmployees({ params, config }) {
  const { isLoading, isFetching, fetchNextPage, hasNextPage, data, isError } =
    useInfiniteList(
      'lookups/employees',
      params,
      {
        enabled: params.departmentIds?.length > 0 ? true : false,
        ...config,
      },
      'v2',
    );

  const _data = useMemo(() => {
    if (data?.pages && !isError) {
      const result = data?.pages?.reduce((prevPage, currentPage) => {
        return [...prevPage, ...currentPage?.data?.data];
      }, []);
      return result;
    }

    return [];
  }, [data?.pages, isError]);

  return {
    employees: _data,
    isEmployeeListLoading: isLoading,
    isEmployeeListUpdating: isFetching,
    employeeCount: data?.pages?.[0]?.data?.meta?.total,
    hasMoreEmployee: hasNextPage,
    employeeCurrentPage: data?.pageParams?.at(-1) || 1,
    fetchMoreEmployee: fetchNextPage,
    isEmployeeListError: isError,
  };
}
