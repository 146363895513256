import React from 'react';

export default function SmallPerson() {
  return (
    <path
      d="M0-14.062a2.751,2.751,0,0,0-1.406.378,2.793,2.793,0,0,0-1.028,1.028,2.751,2.751,0,0,0-.378,1.406,2.608,2.608,0,0,0,.29,1.213,3.045,3.045,0,0,0,.782.967A3.486,3.486,0,0,0-2.927-7.849a3.227,3.227,0,0,0-.448,1.661v1.916l.158.176.967.967V.563h1.125V-2.25h2.25V.563H2.25V-3.129L3.217-4.1l.158-.176V-6.187a3.227,3.227,0,0,0-.448-1.661A3.486,3.486,0,0,0,1.74-9.07a3.045,3.045,0,0,0,.782-.967,2.608,2.608,0,0,0,.29-1.213,2.751,2.751,0,0,0-.378-1.406,2.793,2.793,0,0,0-1.028-1.028A2.751,2.751,0,0,0,0-14.062Zm0,1.125a1.627,1.627,0,0,1,1.2.492,1.627,1.627,0,0,1,.492,1.2,1.627,1.627,0,0,1-.492,1.2A1.627,1.627,0,0,1,0-9.562a1.627,1.627,0,0,1-1.2-.492,1.627,1.627,0,0,1-.492-1.2,1.627,1.627,0,0,1,.492-1.2A1.627,1.627,0,0,1,0-12.937Zm0,4.5a2.231,2.231,0,0,1,1.134.3,2.189,2.189,0,0,1,.817.817,2.231,2.231,0,0,1,.3,1.134v1.441L2-4.5H-2l-.246-.246V-6.187a2.231,2.231,0,0,1,.3-1.134,2.189,2.189,0,0,1,.817-.817A2.231,2.231,0,0,1,0-8.437Z"
      transform="translate(3.375 14.063)"
    />
  );
}
