import { useList } from '../../../../../../../api/react-query';

export function useListAllowanceTypes(params = {}) {
  const {
    data,
    isLoading,
    error,
  } = useList(
    'getAllowanceTypes',
    { ...params },
    "",
    'v1'
  )

  return {
    allowanceTypes: data?.data?.data,
    allowanceTypesLoading: isLoading,
    allowanceTypesError: error,
  };
}
