import React from 'react';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { Button as TailwindButton } from '../components/buttons';

const ClockInButton = (props) => {
  return (
    <>
      {props?.loader ? (
        <LoadingButton
          style={{
            height: '2.875em',
            width: '14.25em',
            borderRadius: '6px',
            font: 'normal normal bold 14px/20px',
            fontSize: '0.875em',
            letterSpacing: '0px',
            textTransform: 'none',
          }}
          color="secondary"
          loading
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="outlined"
        >
          {props.title}
        </LoadingButton>
      ) : (
        <TailwindButton
          disabled={props.disabled}
          onClick={props.onClick}
          startIcon={props.startIcon}
          fullWidth={props.fullWidth}
          className={`primary-btn ${props.className}`}
        >
          {props.title}
        </TailwindButton>
      )}
    </>
  );
};
export default ClockInButton;
