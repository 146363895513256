/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';

import { useList } from '../../api/react-query';
import { AutoComplete } from '../inputs';
import { useTranslate } from '../../hooks';

export function Timezone({
  value,
  defaultValue,
  errorText,
  className,
  onChange,
  label,
  autoCompleteProps,
  inputProps,
  endpoint,
  ...props
}) {
  const { data, isLoading } = useList(
    endpoint || 'lookups/timezones',
    {},
    { cacheTime: 20000 },
  );

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { translate } = useTranslate();

  const _defaultValue = useMemo(() => {
    if (defaultValue && typeof defaultValue === 'object') return defaultValue;

    const res = data?.data?.find(
      (timezone) => timezone.timezone === (defaultValue || userTimeZone),
    );

    if (res) {
      const formattedValue = {
        id: res.timezone,
        label: res.timezone,
        offset: res.offset,
      };

      onChange?.(formattedValue);
      return formattedValue;
    }

    return null;
  }, [data?.data, defaultValue]);

  if (defaultValue && !_defaultValue) return null;

  return (
    <AutoComplete
      value={value || null}
      defaultValue={_defaultValue || defaultValue || ''}
      onChange={onChange}
      errorText={errorText}
      showError={errorText}
      isLoading={isLoading}
      clearable={false}
      className={className}
      data={data?.data?.map((timezone) => ({
        label: timezone.timezone,
        id: timezone.timezone,
        offset: timezone.offset,
      }))}
      label={label || translate('timezones')}
      autoCompleteProps={autoCompleteProps}
      inputProps={inputProps}
      {...props}
    />
  );
}
