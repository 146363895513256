import api, { fileUploadV2, fileUpload_companyV2 } from '../core';

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const CommonApi = {
  uploadBulkFiles,
  getFilePath,
  fileUpload,
  fileUpload_company,
};

function uploadBulkFiles(payload, type) {
  var companyID = localStorage.getItem('__current_company_id');
  var user_id = localStorage.getItem('userId');
  return new Promise((resolve, reject) => {
    api
      .post(
        `/v2/importBulk?event=${type}&companyId=${companyID}&userId=${user_id}&timeZone=${timeZone}`,
        payload,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getFilePath(pathname, fullPath = false) {
  if (!pathname) return;

  var companyID = localStorage.getItem('__current_company_id');
  var user_id = localStorage.getItem('userId');
  return new Promise((resolve, reject) => {
    api
      .post(
        `/v2/company/getSignedFilepath?companyId=${companyID}&userId=${user_id}`,
        {
          filepath: pathname,
          fullpath: fullPath,
        },
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function fileUpload(formData) {
  return new Promise((resolve, reject) => {
    fileUploadV2(formData)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function fileUpload_company(formData) {
  return new Promise((resolve, reject) => {
    fileUpload_companyV2(formData)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

export default CommonApi;
