import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Box, Typography, Button, Collapse } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import UserContext from '../../context/UserContext';
import organisation from '../../api/organisation';
import Empty from '../Empty';
import CommonApi from '../../api/v2/common';
import classNames from 'classnames';
import { Icons } from '../../components';
import { formatTimeDifference } from '../../helpers/utils';
import { useTranslate } from '../../hooks';
import { Loader } from '../../components/icons/Loader';
import { EXPORT_FILE, LOGS_FILE } from '../../common/constants';
import { downloadExcelFile, showFile } from '../../common/file/download';

const styles = {
  notifModal: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '20px 13px 40px #0000001A',
    borderRadius: '10px',
    position: 'absolute',
    top: '80px',
    right: '5em',
    width: '420px',
    height: '470px',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '28px',
  },
  notifModal_ar: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '20px 13px 40px #0000001A',
    borderRadius: '10px',
    position: 'absolute',
    top: '80px',
    left: '5em',
    width: '420px',
    height: '470px',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '28px',
  },
};
export default function NotificationBox(props) {
  const { notifications, updateNotifications } = React.useContext(UserContext);

  const notification_mark_read = (params) => {
    if (!params?.is_read) {
      organisation
        .readMyNotifications(params?.id, params?.datafrom)
        .then((result) => {
          // eslint-disable-next-line
          var filternew = myNotifications.findIndex((e) => e.id === params?.id);

          if (filternew >= 0) {
            // eslint-disable-next-line
            myNotifications[filternew]['is_read'] = 1;
          }
          // eslint-disable-next-line
          if (unreadCount > 1) setUnReadCount(unreadCount - 1);
          // eslint-disable-next-line
          setMyNotifications(myNotifications);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const get_name = (description) => {
    if (description) return t('Click here to download');
    return t('View');
  };
  
  const { t, i18n } = useTranslation();
  const [showYestNotifications, setShowYestNotifications] =
    React.useState(false);
  const callupdatedmarkedread = (id, table) => {
    const uid = localStorage.getItem('__active__user__id');
    const cid = localStorage.getItem('__current_company_id');
    organisation
      .readMyNotifications(id, table, uid, cid)
      .then((result) => {
        const updatedNotifications = _.map(notifications, (d) => {
          if (d.id === result?.id) {
            return { ...d, read_at: result?.read_at };
          }
          return d;
        });
        updateNotifications(updatedNotifications);
        window.location.href = '/company/Notification';
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleViewNotification = (data) => {
    callupdatedmarkedread(data?.id, data?.datafrom);
  };

  const handleLinkClick = async (notification) => {
    try {
      if(notification.description.includes(EXPORT_FILE)) {
         await downloadExcelFile(notification.description , notification.name);
         return;
      }
      if(notification.description.includes(LOGS_FILE)) {
        await showFile(notification.description);
        return;
      }
      CommonApi.getFilePath(notification.description)
        .then((res) => {
          window.open(res, '_blank');
        })
        .catch((err) => {
          console.error('API request failed');
        });
      // Check if the notification is already read, if not, mark it as read
      if (!notification.is_read) {
        await notification_mark_read(notification);
      }
    } catch (error) {
      console.error('Error occurred while calling the API:', error);
    }
  };

  const { dir } = useTranslate();

  return (
    <Box
      style={i18n.language === 'ar' ? styles.notifModal_ar : styles.notifModal}
      dir={dir}
    >
      {props.isFetchingNotifications ? (
        <div className="flex justify-center items-center h-full">
          <Loader width={140} height={140} />
        </div>
      ) : (
        <>
          <Grid container>
            <Grid item style={{ flexGrow: '1' }}>
              <Typography
                style={{
                  fontSize: '1em',
                  color: '#081735',
                  fontWeight: 'bold',
                }}
              >
                {t('Notifications')}
              </Typography>
              <Typography
                style={{
                  fontSize: '0.8em',
                  color: '#8F95B2',
                }}
                className="mt-1"
              >
                {t(`You have {{length}} unread messages`, {
                  length: props?.unreadCount, //notifications.length,
                })}
              </Typography>
            </Grid>
            <Grid item>
              <i className="las la-check-double" style={{ color: '#66D4A8' }} />
            </Grid>
          </Grid>

          {/* {Notification body} */}
          {props?.notifications?.length > 0 ? (
            <div className="flex flex-col justify-between min-h-[370px]">
              <div>
                <div className="h-[1px] w-full border-t border-dashed my-3"></div>

                <p>{t('Today')}</p>
                <ul className="space-y-5 mt-2">
                  {_.map(props?.notifications, function (notification) {
                    const date = moment(notification?.created_at);
                    const isToday = date.isSame(moment(), 'day');

                    if (isToday) {
                      const title = notification?.title || notification?.type;

                      const formattedDate = formatTimeDifference(
                        notification?.created_at,
                      );

                      return (
                        <li
                          onClick={(e) => {
                            notification.description
                              ? handleLinkClick(notification)
                              : handleViewNotification(notification);
                          }}
                          className="flex items-center w-full gap-x-2 cursor-pointer"
                          title={get_name(notification.description)}
                        >
                          <span
                            className={classNames(
                              'flex justify-center items-center w-[38px] h-[38px] rounded-md',
                              {
                                'text-green-500 bg-green-50':
                                  notification?.status === 'Approved',
                                'text-red-500 bg-red-50':
                                  notification?.status === 'Rejected',
                                'text-orange-500 bg-orange-50':
                                  notification?.status === 'Pending',
                              },
                            )}
                          >
                            <Icons
                              width="13"
                              height="13"
                              name={
                                notification?.status === 'Approved'
                                  ? 'double-check'
                                  : notification?.status === 'Pending'
                                    ? 'clock'
                                    : 'close'
                              }
                            />
                          </span>

                          <span className="w-4/5">
                            <div className="text-sm text-gray-500 whitespace-pre-wrap">
                              {title}
                            </div>

                            <div className="flex items-center gap-x-1 mt-1 text-xs text-gray-400">
                              <Icons
                                name="clock"
                                width={11}
                                height={11}
                                viewBox={[16, 16]}
                              />{' '}
                              {formattedDate}
                            </div>
                          </span>
                        </li>
                      );
                    }
                  })}
                </ul>

                <Box style={{ marginTop: '10px' }} />
                <Grid
                  container
                  spacing={2}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Grid item>
                    <Typography
                      style={{
                        color: '#081735',
                        fontWeight: 'bold',

                        fontSize: '14px',
                      }}
                    >
                      {t('Yesterday')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <i
                      className={`las ${
                        showYestNotifications ? 'la-minus' : 'la-plus'
                      }`}
                      style={{
                        color: '#081735',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                      }}
                      onClick={() => {
                        setShowYestNotifications(!showYestNotifications);
                      }}
                    />
                  </Grid>
                </Grid>
                <Box style={{ marginTop: '10px' }} />
                <Collapse
                  in={showYestNotifications}
                  timeout="auto"
                  unmountOnExit
                >
                  <ul className="space-y-5">
                    {_.map(props?.notifications, function (notification) {
                      const date = moment(notification?.created_at);
                      const isYesterday = date.isSame(
                        moment().subtract(1, 'day'),
                        'day',
                      );

                      if (isYesterday) {
                        const title = notification?.title || notification?.type;

                        const formattedDate = formatTimeDifference(
                          notification?.created_at,
                        );

                        return (
                          <li
                            onClick={() => {
                              notification.description
                                ? handleLinkClick(notification)
                                : handleViewNotification(notification);
                            }}
                            className="flex items-center w-full gap-x-2 cursor-pointer"
                            title={get_name(notification.description)}
                          >
                            <span
                              className={classNames(
                                'flex justify-center items-center w-[38px] h-[38px] rounded-md',
                                {
                                  'text-green-500 bg-green-50':
                                    notification?.status === 'Approved',
                                  'text-red-500 bg-red-50':
                                    notification?.status === 'Rejected',
                                  'text-orange-500 bg-orange-50':
                                    notification?.status === 'Pending',
                                },
                              )}
                            >
                              <Icons
                                width="13"
                                height="13"
                                name={
                                  notification?.status === 'Approved'
                                    ? 'double-check'
                                    : notification?.status === 'Pending'
                                      ? 'clock'
                                      : 'close'
                                }
                              />
                            </span>

                            <span className="w-4/5">
                              <div className="text-sm text-gray-500 whitespace-pre-wrap">
                                {title}
                              </div>

                              <div className="flex items-center gap-x-1 mt-1 text-xs text-gray-400">
                                <Icons
                                  name="clock"
                                  width={11}
                                  height={11}
                                  viewBox={[16, 16]}
                                />{' '}
                                {formattedDate}
                              </div>
                            </span>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </Collapse>
              </div>

              <div className="w-full flex flex-col items-center">
                <div className="h-[1px] w-full border-t border-dashed my-4"></div>

                <Button
                  style={{
                    borderRadius: '0.5em',
                    color: '#7C0E6F',
                    textTransform: 'capitalize',
                    border: '1px dashed #7C0E6F',
                    backgroundColor: '#7C0E6F1A',
                  }}
                  className="w-32"
                  onClick={() => {
                    props.onClickViewAll();
                  }}
                >
                  {t('View All')}
                </Button>
              </div>
            </div>
          ) : (
            <Box>
              <Empty />
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
