import React from 'react';
import Lottie from 'react-lottie';
import * as animationData from '../assets/animation/global_loader.json';

export default class ActivityLoader extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isStopped: false, isPaused: false };
    }

    render() {

        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
            },
        };

        return (
            <Lottie
                options={defaultOptions}
                height={this.props?.height ?? 300}
                width={this.props?.width ?? 300}
                isStopped={this.state.isStopped}
                isPaused={this.state.isPaused}
            />
        );
    }
}
