import React from 'react';

export default function Sick() {
  return (
    <path
      d="M4.5-13.5a1.622,1.622,0,0,0-1.187.5,1.622,1.622,0,0,0-.5,1.187,1.622,1.622,0,0,0,.5,1.187,1.622,1.622,0,0,0,1.187.5,1.622,1.622,0,0,0,1.187-.5,1.622,1.622,0,0,0,.5-1.187A1.622,1.622,0,0,0,5.687-13,1.622,1.622,0,0,0,4.5-13.5Zm0,1.125a.548.548,0,0,1,.4.158.537.537,0,0,1,.158.4.566.566,0,0,1-.158.4.522.522,0,0,1-.4.167.551.551,0,0,1-.4-.167.551.551,0,0,1-.167-.4.522.522,0,0,1,.167-.4A.556.556,0,0,1,4.5-12.375ZM1.441-10.67l-2.021.229a1.051,1.051,0,0,0-.773.492l-.018-.018-.018.035-1.3,1.723.879.668,1.336-1.74.035-.035,1.23-.141L-.158-7.295a3.839,3.839,0,0,0-1.74.483A4.005,4.005,0,0,0-3.234-5.625H-1.67A2.733,2.733,0,0,1,0-6.187a2.751,2.751,0,0,1,1.406.378A2.793,2.793,0,0,1,2.435-4.781a2.751,2.751,0,0,1,.378,1.406,2.751,2.751,0,0,1-.378,1.406A2.793,2.793,0,0,1,1.406-.94,2.751,2.751,0,0,1,0-.562a2.739,2.739,0,0,1-1.257-.3,2.717,2.717,0,0,1-.976-.826H-3.551A3.829,3.829,0,0,0-2.118-.053,3.839,3.839,0,0,0,0,.563,3.859,3.859,0,0,0,1.978.035,3.907,3.907,0,0,0,3.41-1.4a3.859,3.859,0,0,0,.527-1.978,4.314,4.314,0,0,0-.053-.562H4.992L4.131-.7l1.09.281.967-3.621L6.17-4.184a1.1,1.1,0,0,0-.4-.633,1.107,1.107,0,0,0-.712-.246H3.551a3.643,3.643,0,0,0-.633-.932L3.85-7.945v-.018a1.611,1.611,0,0,0-.018-1.169,1.669,1.669,0,0,0-.773-.888l-.949-.527v.018A1.153,1.153,0,0,0,1.441-10.67Zm.51,1.318.58.316a.547.547,0,0,1,.264.3.549.549,0,0,1,0,.387l-.773,1.6A4.432,4.432,0,0,0,1-7.172ZM-5.062-5.062v1.125h3.938V-5.062ZM-6.75-3.375V-2.25H.563V-3.375Z"
      transform="translate(6.75 13.5)"
    />
  );
}
