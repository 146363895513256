import React from 'react';

export default function Grid() {
  return (
    <>
      <g id="quick">
        <g id="Rectangle_57" fill="none">
          <rect width="13" height="13" rx="2" stroke="none" />
          <rect
            x="0.75"
            y="0.75"
            width="11.5"
            height="11.5"
            rx="1.25"
            fill="none"
          />
        </g>
        <g id="Rectangle_60" transform="translate(0 16)" fill="none">
          <path
            d="M4,0H9a4,4,0,0,1,4,4V9a4,4,0,0,1-4,4H0a0,0,0,0,1,0,0V4A4,4,0,0,1,4,0Z"
            stroke="none"
          />
          <path
            d="M4,.75H9A3.25,3.25,0,0,1,12.25,4V9A3.25,3.25,0,0,1,9,12.25H1.5a.75.75,0,0,1-.75-.75V4A3.25,3.25,0,0,1,4,.75Z"
            fill="none"
          />
        </g>
      </g>
      <g id="quick-2" transform="translate(16)">
        <g id="Rectangle_57-2" fill="none">
          <rect width="13" height="13" rx="2" stroke="none" />
          <rect
            x="0.75"
            y="0.75"
            width="11.5"
            height="11.5"
            rx="1.25"
            fill="none"
          />
        </g>
        <g id="Rectangle_60-2" transform="translate(0 16)" fill="none">
          <rect width="13" height="13" rx="2" stroke="none" />
          <rect
            x="0.75"
            y="0.75"
            width="11.5"
            height="11.5"
            rx="1.25"
            fill="none"
          />
        </g>
      </g>
    </>
  );
}
