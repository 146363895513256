import React from 'react';

const ToggleButton = ({ handleCheck, checked, label, className, disabled }) => {
  return (
    <label
      className={`relative inline-flex items-center cursor-pointer ${className}`}
    >
      <input
        type="checkbox"
        value=""
        checked={checked}
        className="sr-only peer outline-0"
        onChange={(e) => handleCheck(e)}
        disabled={disabled}
      />
      <div
        className="w-11 h-6 bg-red-600/60 outline-0 border-red-300
              peer-focus:ring-0
              rounded-full peer  peer-checked:after:translate-x-full
              rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white
              peer-checked:after:bg-green-100
              after:content-[''] after:absolute after:top-[2px] after:start-[2px]
              after:bg-white after:border-gray-200 after:border after:rounded-full after:h-5
              after:w-5 after:transition-all  peer-checked:bg-green-200"
      ></div>
      {label && (
        <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
          {label}
        </span>
      )}
    </label>
  );
};
export default ToggleButton;
