import { t } from 'i18next';
import * as Yup from 'yup';

export const addOvertime = Yup.object({
  shiftType: Yup.mixed().required(t('required-field')),
  amount: Yup.mixed().required(t('required-field')),
  minOTHours: Yup.number().required(t('required-field')).min(0.1, t('min-value-required')).max(24, t('max-value-24')),
  maxOTHours: Yup.number().required(t('required-field')).min(Yup.ref('minOTHours'), t('max-should-be-greater-than-min')).max(24, t('max-value-24')),
  shiftCode: Yup.mixed().required(t('required-field')),
  dailySalHrs: Yup.number().required(t('required-field')),
});

export const editOvertime = Yup.object({
  amount: Yup.mixed().required(t('required-field')),
  minOTHours: Yup.number().required(t('required-field')).min(0.1, t('min-value-0.1')).max(24, t('max-value-24')),
  maxOTHours: Yup.number().required(t('required-field')).min(Yup.ref('minOTHours'), t('max-should-be-greater-than-min')).max(24, t('max-value-24')),
  dailySalHrs: Yup.number().required(t('required-field')),
});
