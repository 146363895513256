import api from './core';

const announcement = {
  getAllAnnouncement,
  postCreateAnnouncement,
  patchAnnouncement,
  sendAnnouncement,
  getAttachedFile,
  sendAnnouncementToFilterEmp,
};
function sendAnnouncementToFilterEmp(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`/v2/sendAnnouncementToFilterEmp`, payload)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getAttachedFile(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/downloadAnnouncement/${id}`)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getAllAnnouncement(company_id) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/getAnnoucements?companyId=${company_id}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function postCreateAnnouncement(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`/v1/postAnnouncement`, payload)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function patchAnnouncement(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`/v1/editAnnouncement`, payload)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function sendAnnouncement(payload) {
  return new Promise((resolve, reject) => {
    api
      .put(`/v1/sendAnnouncement`, payload)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
export default announcement;
