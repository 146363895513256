import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { debounce } from 'lodash';

export const useSearch = (initValue = null, delay = 500) => {
  const [query, setQuery] = useState(initValue);
  const [search, setSearch] = useSearchParams();

  useEffect(() => {
    const debounced = debounce(() => {
      if (query !== null) {
        search.set('search', query);
        search.set('page', 0);

        setSearch(search, {
          replace: true,
        });
      }
    }, delay);

    debounced();

    return () => {
      debounced.cancel();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, delay]);

  return [search.get('search'), setQuery];
};
