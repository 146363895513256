import { useTranslate } from '../../../hooks';
import api from '../../core';
import { API_VERSION } from '../constants';
import useGenericMutation from '../use-generic-mutation';

export function useDelete({
  url,
  params = {},
  customInvalidateQueriesUrl,
  msg,
  apiVersion = API_VERSION,
}) {
  const { translate } = useTranslate();

  params['locale'] = localStorage.getItem('applang');

  return useGenericMutation({
    func: (id) => api.delete(`${apiVersion}/${url}/${id}`, { params }),
    url,
    params,
    customInvalidateQueriesUrl,
    msg: msg ?? translate('deleted-successfully'),
  });
}
