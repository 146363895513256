import React from 'react';

import { Icons } from '../icons';
import { useTranslate } from '../../hooks';
import classNames from 'classnames';

export function SearchInput({
  className,
  placeholder,
  onChange,
  value,
  containerClassName,
}) {
  const { translate, isRTL } = useTranslate();

  return (
    <div className={`relative w-fit ${containerClassName}`}>
      <Icons
        name="search"
        width={20}
        height={20}
        className={classNames(
          'absolute top-1/2 -translate-y-1/2 transform text-gray-400',
          {
            'right-6': isRTL,
            'left-6': !isRTL,
          },
        )}
      />

      <input
        className={classNames(
          `p-[10px] outline-none border border-border rounded-md ${className}`,
          {
            'pr-14': isRTL,
            'pl-14': !isRTL,
          },
        )}
        placeholder={placeholder ?? translate('Search here…')}
        onChange={onChange}
        value={value}
      />
    </div>
  );
}
