import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { UserContext } from '../../context';
import _ from 'lodash';

const PrivateRoute = (props) => {
  const { userInfo, activeBU } = useContext(UserContext);
  const [checkNow, setCheckNow] = useState(false);

  useEffect(() => {
    if (activeBU.perms) {
      setCheckNow(true);
    }
  }, [activeBU.perms]);
  const hasAccess = _.find(activeBU.perms, function (perm) {
    return perm.screen_name === props.access;
  });
  return checkNow ? (
    userInfo.isAdmin || hasAccess || props.path == 'Notification' ? (
      props.component
    ) : (
      <Navigate to="/forbidden" />
    )
  ) : (
    <></>
  );
};

export default PrivateRoute;
