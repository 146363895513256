import { Tooltip } from '@mui/material';
import { useTranslate } from "../../../../../../../hooks"
import _ from "lodash"

function AllowanceCell ({value}){
    const {translate} = useTranslate()
    return (
        <Tooltip
        slotProps={{
          tooltip: {
            className: '!bg-base-gray !min-w-[130px]',
          },
        }}
        title={
          <div className={`flex flex-col gap-[4px] text-black text-nowrap`}>
            {translate(`Allowance Types: `)} <br />
            {value?.length > 0 ? (
              _.map(value, (item, index) => (
                <span key={item.id}>
                    {item?.name}
                    {index < value?.length - 1 ? ', ' : '. '}
                </span>
              ))
            ) : (
              translate('No Allowance Types')
            )}
          </div>

        }
      >
        <div className="flex justify-between max-w-[130px]">
          <p className="text-xs text-text italic font-semibold">
            {value?.length > 0 && translate(value[0]?.name)}...
          </p>
          <i class="las la-info-circle table-icon cursor-pointer text-secondary"></i>
        </div>
      </Tooltip>
    )
}

export default AllowanceCell;