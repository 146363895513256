import React from 'react';

export const Button = ({
  children,
  className,
  disabled,
  disabledClassName,
  ...props
}) => {
  return (
    <button
      className={disabled ? disabledClassName || 'disabled-btn' : className}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};
