export const salaryDays = [
  { label: '30 Days', value: '30', id: 30 },
  { label: 'Calender Days', value: 'calenderDays', id: 0 },
];

export const exitReasons = [
  { label: 'Termination', value: 'termination' },
  { label: 'Resignation', value: 'resignation' },
];

export const INPUT_TYPES = {
  boolean: 'Enable',
  string: 'Text',
};

export const ROLE_SEQUENSE_TYPE = {
  isDirectManager: '1',
  rootManager: '2'
};

export const CONFIG_ID = {
  maritalStatus: 19,
  benefits: 5,
  relationship: 20,
  assetType: 13,
  awardType: 15,
  documentType: 3,
  grade: 9,
  insuranceType: 14,
  passwordPolicy: 44,
};
