import { useQuery } from 'react-query';

import { API_VERSION } from '../constants';
import { fetcher } from '../list/use-list';

export function useGetOne(url, config, apiVersion = API_VERSION) {
  const context = useQuery(
    [url],
    ({ queryKey }) => fetcher({ queryKey, apiVersion }),
    {
      enabled:
        config?.enabled !== undefined ? (url ? true : false) : config?.enabled,
      ...config,
    },
  );

  return context;
}
