import React from 'react';
import { Loader } from '../icons/Loader';

//TODO: remove duplication of this comp on v2 folder
export function LoaderCotainer({ className }) {
  return (
    <div className={`h-[92%] flex items-center ${className}`}>
      <Loader height={150} width={150} />
    </div>
  );
}
