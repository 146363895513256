import moment from 'moment';
import { lazy } from 'react';
import 'moment/locale/ar';
import { t } from 'i18next';

import i18n from '../i18n';
import { arabicLocalization } from '../common/Methodcommon';

moment.locale(i18n.language);

moment.updateLocale('en', {
  meridiem: (hours) => {
    return hours < 12 ? i18n.t('am') : t('pm');
  },
});

export function getAmount(amount, addcurrency = true) {
  let _currency = null;
  if (localStorage.getItem('applang') === 'en') {
    _currency = localStorage.getItem('company_currency');
  } else {
    _currency = localStorage.getItem('company_currency_ar');
  }
  if (_currency) {
    const number = Number(amount);
    const roundedNumber = Math.floor(number * 100) / 100;
    const _finalNumber = parseFloat(roundedNumber).toFixed(2);
    const _number = Number(_finalNumber);
    const final_amount = _number.toLocaleString('en-US', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    return addcurrency ? `${_currency} ${final_amount}` : `${final_amount}`;
  } else {
    const number = Number(amount);
    const roundedNumber = Math.floor(number * 100) / 100;

    return `${roundedNumber}`;
  }
}

export function daysDifference(startDateStr, endDateStr) {
  //expecting both dates in YYYY-MM-DD
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  const differenceInMilliseconds = endDate - startDate;

  const differenceInDays = Math.floor(
    differenceInMilliseconds / (1000 * 60 * 60 * 24),
  );

  if (differenceInDays >= 0) {
    const daysLeft = differenceInDays;
    return { differenceInDays, daysLeft };
  } else {
    const differenceInDays = Math.floor(
      (startDate - endDate) / (1000 * 60 * 60 * 24),
    );
    const daysLeft = 0;
    return { differenceInDays, daysLeft };
  }
}

export function getCurrencyName() {
  let _currency = null;
  //const _currency = localStorage.getItem("company_currency");
  if (localStorage.getItem('applang') === 'en') {
    _currency = localStorage.getItem('company_currency');
  } else {
    _currency = localStorage.getItem('company_currency_ar');
  }
  if (_currency) {
    return `${_currency}`;
  } else return;
}

export const getPreviousYears = () => {
  /*  Function will return current year and last 2 years*/
  const current_year = moment(new Date()).format('YYYY');

  let year = Number(current_year);
  const years = [];
  for (let i = 0; i < 3; i++) {
    const obj = { id: year, label: year, color: '#7C0E6F' };

    years.push(obj);
    year--;
  }
  return years;
};

export const ALL_CONSANTS = {
  linear: 'linear', // Constant using in the Creating KPI in Evaluation Modes
};

export function formatTimeDifference(date) {
  const _date = moment(date);

  const currentTime = moment();

  const minutes = currentTime.diff(_date, 'minutes');

  if (minutes < 1) {
    return i18n.t('few-seconds-ago');
  } else if (minutes < 60) {
    return `${minutes} ${minutes === 1 ? i18n.t('min') : i18n.t('mins')}`;
  } else if (minutes < 1440) {
    // 1440 minutes in a day (24 hours)
    const hours = Math.floor(minutes / 60);
    return `${hours} ${hours === 1 ? i18n.t('hour') : i18n.t('hour')}`;
  } else {
    const days = Math.floor(minutes / 1440);
    return `${days} ${days === 1 ? i18n.t('day') : i18n.t('days')}`;
  }
}

export const baseDateFormat = (date, format) => {
  const momentClone = moment(date);

  momentClone.locale(i18n.language);

  return momentClone.format(format ?? 'YYYY-MM-DD');
};

export const convertUtcToDate = (
  _date,
  format,
  _offset,
  convertToUTC = true,
) => {
  let date = null;

  if (!moment(_date).isValid()) {
    date = new Date(`1970-01-01 ${_date}`);
  }

  if (moment(_date).isValid()) {
    if (convertToUTC) {
      date = new Date(
        new Date(_date).toLocaleString('en', { timeZone: 'UTC' }),
      );
    } else {
      date = new Date(_date);
    }
  }

  const offset = _offset ?? Math.abs(date.getTimezoneOffset());

  const epoch = date.getTime();

  const epochWithOffset = epoch + offset * 60 * 1000;

  const result = baseDateFormat(epochWithOffset, format);

  return result;
};

export const convertDateToUTC = (_date, format, _offset, resetTime) => {
  let date = null;

  if (!moment(_date).isValid()) {
    date = new Date(`1970-01-01 ${_date}`);
  }

  if (moment(_date).isValid()) {
    date = new Date(_date);
  }

  if (resetTime) date.setHours(0, 0, 0, 0);

  const offset = _offset ?? Math.abs(date.getTimezoneOffset());

  const epoch = date.getTime();

  const epochWithOffset = epoch - offset * 60 * 1000;

  const result = moment(epochWithOffset).format(format);

  return result;
};

export const adjustClockOut = (clockIn, clockOut) => {
  const clockInTime = moment.utc(clockIn);

  const clockOutTime = moment.utc(clockOut);

  if (clockInTime.isAfter(clockOutTime)) {
    clockOutTime.add(1, 'day');

    return clockOutTime.format(baseDateTimeFormatting);
  }

  return clockOutTime.format(baseDateTimeFormatting);
};

export function getCurrentTimeZone() {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timeZone;
}

export const lazyLoadWithRetry = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed') || 'false',
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem('page-has-been-force-refreshed', 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        window.localStorage.setItem('page-has-been-force-refreshed', 'true');
        alert('New version has been released, we are going to reload the page');
        return window.location.reload();
      }

      throw error;
    }
  });

export const booleanToStr = (value) => (value ? t('Yes') : t('No'));

export const getItemId = ({ seletedItem, list, selectertKey, returnField }) => {
  const filtered = list?.find((item) => item[selectertKey] === seletedItem);
  return filtered?.[returnField];
};

export const options = {
  search: false,
  download: false,
  print: false,
  viewColumns: false,
  elevation: 0,
  filter: false,
  selectableRowsHeader: false,
  selectableRows: false,
  textLabels: i18n.language === 'ar' ? arabicLocalization : '',
};

export const baseTimeFormatting = 'hh:mm A';
export const time24Formatting = 'HH:mm';

export const baseDateFormatting = 'DD MMM, YYYY';
export const dayMonthFormatting = 'DD MMM';
export const defaultDateFormatting = 'YYYY-MM-DD';
export const baseDateTimeFormatting = 'YYYY-MM-DD HH:mm';
