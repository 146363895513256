import { useList } from '../../../../../../../api/react-query';

export function useListOvertimeConfiTypes(params = {}) {
  const {
    data,
    isLoading,
    error,
    refetch
  } = useList(
    'config/overtime-auto',
    { ...params },
    '',
  )

  return {
    overtimeConfigs: data?.data?.data,
    count: data?.data?.totalCount,
    overtimeConfigsLoading: isLoading,
    overtimeConfigsError: error,
    refetch
  };
}
