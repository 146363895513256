import { useList } from '../../../../../../../api/react-query';

export function useListShiftTypes(params = {}) {
  const {
    data,
    isLoading,
    error,
    refetch
  } = useList(
    'config/getShiftType',
    { ...params },
    '',
  )

  return {
    shiftTypes: data?.data?.data,
    shiftTypeLoading: isLoading,
    shiftTypeError: error,
    shiftRefetch:refetch
  };
}
