import React from 'react';
import Lottie from 'react-lottie';
import * as animationData from '../../assets/lottie-icon/empty.json';

function Empty(props) {
  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Lottie
      options={defaultOptions}
      height={props.height ?? 35}
      width={props.width ?? 25}
      isStopped={true}
      isPaused={true}
      style={{
        cursor: 'inherit',
      }}
    />
  );
}

export default Empty;
