import { useEffect, useState } from 'react';
import { debounce } from 'lodash';

export function useDebounce(initialQuery) {
  const [query, setQuery] = useState(initialQuery);
  const [debouncedQuery, setDebouncedQuery] = useState(initialQuery);

  useEffect(() => {
    const debounced = debounce(() => {
      setDebouncedQuery(query);
    }, 500);

    debounced();

    return () => {
      debounced.cancel();
    };
  }, [query]);
  return [debouncedQuery, setQuery];
}
