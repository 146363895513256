import React from 'react';
import CompanyConfPage from '../pages/v2/company-configurtaion/pages/comapny-conf-page-container/CompanyConfPage';
import { Outlet } from 'react-router';
import Shell from '../pages/Shell';
import { Loader } from '../components/icons/Loader';

import TopNavBar from '../pages/v2/company-configurtaion/layout/TopNavBar';
import SideMenu from '../pages/v2/company-configurtaion/pages/comapny-conf-page-container/components/side-menu/SideMenu';

function CompanyConfigLayout() {
  return (
    <Shell backgroundColor="#f5f5fa" hideSidebar paddingLeft="1px">
      <div className="h-full">
        <div className="mt-20 ltr:mr-5 rtl:ml-5 w-full shadow-md z-20 bg-white p-[0.7rem] h-[60px] br-company-conf flex gap-3 items-center">
          <TopNavBar />
        </div>
        <div className="flex gap-[0.7rem] overflow-hidden pb-1">
          <div class="max-h-full mt-5 shadow-md z-20 bg-white p-[0.9rem] w-[277px] br-company-conf side-menu-container">
            <SideMenu />
          </div>
          <React.Suspense
            fallback={
              <div className="flex justify-center items-center w-full h-full">
                <Loader width={120} height={120} />
              </div>
            }
          >
            <CompanyConfPage>
              <Outlet />
            </CompanyConfPage>
          </React.Suspense>{' '}
        </div>
      </div>
    </Shell>
  );
}

export default CompanyConfigLayout;
