import React, { useEffect, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CalendarMonth } from '@mui/icons-material';
import { TextField } from '@material-ui/core';

import {
  baseDateTimeFormatting,
  convertDateToUTC,
  convertUtcToDate,
} from '../../helpers/utils';

import './styles.css';
import { useTranslate } from '../../hooks';

export function DatePickerInput({
  views,
  value,
  onChange,
  label,
  inputFormat,
  disableFuture = false,
  minDate,
  readOnly,
  disabled,
  className = '',
  supportUTC = true,
  defaultValue,
  timezoneOffset,
}) {
  const [date, setDate] = useState(null);
  const { translate } = useTranslate();

  const handleChange = (_date) => {
    setDate(_date);

    if (supportUTC) {
      const result = convertDateToUTC(
        _date,
        baseDateTimeFormatting,
        timezoneOffset,
        true,
      );

      onChange?.(result);
    } else {
      const selectedDate = new Date(_date);

      onChange?.(selectedDate);
    }
  };

  useEffect(() => {
    if (defaultValue && !date && supportUTC) {
      const date = convertUtcToDate(
        defaultValue,
        baseDateTimeFormatting,
        timezoneOffset,
        false,
      );

      setDate(date);
    } else if (defaultValue && !supportUTC) {
      setDate(defaultValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, timezoneOffset]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label || ''}
        readOnly={readOnly}
        disabled={disabled}
        value={date}
        minDate={minDate}
        disableFuture={disableFuture}
        className={`w-[140px] padding-0 ${className}`}
        views={views ?? ['year', 'month', 'day']}
        inputFormat={inputFormat ?? 'DD MMM, YYYY'}
        InputProps={{
          disableUnderline: true,
          className: '!text-sm px-2',
        }}
        renderInput={({ ...params }) => (
          <TextField
            className={`w-[130px] ${className}`}
            {...params}
            inputProps={{
              ...params.inputProps,
              placeholder: inputFormat ?? translate('DD MMM, YYYY'.toLowerCase()),
            }}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          />
        )}
        components={{
          OpenPickerIcon: CalendarMonth,
        }}
        onChange={handleChange}
      />
    </LocalizationProvider>
  );
}
