import React, { useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  Modal,
  TextField,
  styled,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import user from '../../api/user';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Compdirection from '../Common/CompDirection';
import BoxDirection from '../Common/BoxDirection';
import { CustomToast } from '../../common/Methodcommon';
import { Button } from '../../components';

const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#7C0E6F',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#7C0E6F',
    color: '#7C0E6F',
  },
  '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    font: 'normal normal normal 14px/24px',
  },
  '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
    fontSize: '0.85em',
  },
});
const ForgetPasswordPresenter = (props) => {
  const [email, setEmail] = useState('');
  const [fetchedUsers, setFetchedUsers] = useState([]);
  const [selectedUserForSendingMail, setSelectedUserForSendingMail] =
    useState('');
  const { t } = useTranslation();

  const handleSubmit = (email) => {
    if (email) {
      user
        .getUsers(email)
        .then((res) => {
          if (res?.response === 'success') {
            if (res?.data?.length > 0) {
              setFetchedUsers(res?.data);
            } else {
              CustomToast('error', t('No user against this email.'));
              setFetchedUsers([]);
            }
          } else {
            return;
          }
        })
        .catch((err) => {
          CustomToast('error', err?.response?.data?.message);
          console.log(err);
        });
    }
  };

  const handleSendInstruction = () => {
    if (selectedUserForSendingMail) {
      user
        .send_password_reset_link(selectedUserForSendingMail, 'portal')
        .then((res) => {
          CustomToast(
            'success',
            t('Recover Password Email has been sent to your account.'),
          );
          props.closeModal();
        })
        .catch((err) => {
          console.log(err?.response);
          CustomToast('error', err?.response?.data?.message);
        });
    }
  };
  return (
    <Modal open={props.isOpen} onClose={props.closeModal}>
      <Box
        style={{
          height: 'auto',
          width: '426px',
          padding: '1.75em',
          borderRadius: '0.938em',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: '#ffffff 0% 0% no-repeat padding-box',
          outline: 'none',
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            textAlign: 'center',
          }}
        >
          <Box
            style={{
              backgroundColor: '#FFF7E5',
              padding: '10px',
              width: 'auto',
              height: 'auto',
            }}
          >
            <Typography
              style={{
                fontSize: '12px',
                color: '#FFB400',
              }}
            >
              {t(
                'login.No worries! Just enter your email and we’ll send you a reset password link.',
              )}
            </Typography>
          </Box>
        </Grid>

        <BoxDirection>
          <Grid
            container
            spacing={2}
            style={{
              marginTop: '10px',
            }}
          >
            <Grid item xs={12}>
              <Compdirection>
                <StyledTextField
                  type="string"
                  label={t('Email')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                />
              </Compdirection>
            </Grid>
            {fetchedUsers?.length > 0 && (
              <Grid
                item
                xs={12}
                style={{
                  overflowY: 'auto',
                  padding: '0.5 em',
                  maxHeight: '200px',
                  height: 'auto',
                  marginTop: '1em',
                }}
              >
                <RadioGroup
                  name="radio-buttons-group"
                  value={selectedUserForSendingMail}
                  onChange={(e) => {
                    setSelectedUserForSendingMail(e.target.value);
                  }}
                >
                  {_.map(fetchedUsers, (user) => {
                    return (
                      <Box
                        style={{
                          height: '50px',
                        }}
                      >
                        <FormControlLabel
                          value={user.id}
                          control={
                            <Radio
                              sx={{
                                '&.Mui-checked': {
                                  color: '#7C0E6F',
                                },
                              }}
                            />
                          }
                          label={user.username}
                        />
                      </Box>
                    );
                  })}
                </RadioGroup>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              style={{
                textAlign: 'center',
              }}
            >
              {fetchedUsers?.length > 0 ? (
                <Button
                  className="primary-btn w-full"
                  disabledClassName="disabled-btn w-full"
                  onClick={() => handleSendInstruction()}
                >
                  {t('login.Send Instructions')}
                </Button>
              ) : (
                <Button
                  className="primary-btn w-full"
                  disabled={email === ''}
                  disabledClassName="disabled-btn w-full"
                  onClick={() => handleSubmit(email)}
                >
                  {t('login.Search User')}
                </Button>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                textAlign: 'center',
              }}
            >
              <Typography
                style={{
                  color: '#9E9E9E',
                  cursor: 'pointer',
                }}
                onClick={() => props.closeModal()}
              >
                {t('login.Back to Login')}
              </Typography>
            </Grid>
          </Grid>
        </BoxDirection>
      </Box>
    </Modal>
  );
};
export default ForgetPasswordPresenter;
