/* eslint-disable eqeqeq */
import { createContext, useState, useEffect } from 'react';

const UserContext = createContext();

export function UserProvider({ children }) {
  /**
   * Basic User Info (From User Table.) for the logged in User.
   */

  const [userContextReady, setUserContextReady] = useState(false);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem('__user__info')) || {},
  );

  const [accessToken, setAccessToken] = useState('');

  const [viewAsEmployee, setViewAsEmployee] = useState(false);
  const [, setActiveUserId] = useState(undefined);
  const [activeCompanyId, setActiveCompanyId] = useState(undefined);
  const [notifications, setNotifications] = useState([]);

  /**
   * Active User Role - What role the User has taken currently. He can always switch the role.
   */
  const [activeUserRole, setActiveUserRole] = useState({});
  const [activeBU, setActiveBU] = useState({});
  const [allAssignedModules, setAllAssignedModules] = useState();
  const updateUserInfo = (info) => {
    if (info != {}) {
      setUserInfo(JSON.parse(JSON.stringify(info)));
      localStorage.setItem('__user__info', JSON.stringify(info));
    }
  };
  const updateUserSettings = (info, type, locale = 'en') => {
    const current_company = localStorage.getItem('__current_company_id');
    switch (type) {
      case 'basic_settings':
        if (current_company === info?.company_id) {
          if (locale === 'ar')
            activeUserRole.company_name_ar = info?.company_name;
          else activeUserRole.company_name = info?.company_name;
          if (activeUserRole?.company_setting?.company_name) {
            if (locale === 'ar')
              activeUserRole.company_setting.company_name_ar =
                info?.company_name;
            else
              activeUserRole.company_setting.company_name = info?.company_name;
          }
          updateActiveUserRole(activeUserRole); // __active__user__info , __active__user__role

          activeBU.company_name = info?.company_name;
          if (activeBU?.settings?.company_name) {
            if (locale === 'ar')
              activeBU.settings.company_name_ar = info?.company_name;
            else activeBU.settings.company_name = info?.company_name;
          }
          activeBU.address = info?.address;
          updateActiveBU(activeBU); // __active__BU__info

          if (userInfo && userInfo.currentRole) {
            if (locale === 'ar')
              userInfo.currentRole.company_name_ar = info?.company_name;
            else userInfo.currentRole.company_name = info?.company_name;
            if (userInfo?.currentRole?.company_setting) {
              if (locale === 'ar')
                userInfo.currentRole.company_setting.company_name_ar =
                  info?.company_name;
              else
                userInfo.currentRole.company_setting.company_name =
                  info?.company_name;
            }
          }
        }

        if (
          userInfo &&
          userInfo.company_wise_access &&
          userInfo.company_wise_access[info?.company_id]
        ) {
          if (locale === 'ar')
            userInfo.company_wise_access[info?.company_id].company_name_ar =
              info?.company_name;
          else
            userInfo.company_wise_access[info?.company_id].company_name =
              info?.company_name;
          if (userInfo.company_wise_access[info?.company_id]?.settings) {
            if (locale === 'ar')
              userInfo.company_wise_access[
                info?.company_id
              ].settings.company_name_ar = info?.company_name;
            else
              userInfo.company_wise_access[
                info?.company_id
              ].settings.company_name = info?.company_name;
          }
          updateUserInfo(userInfo); // __user__info
        }
        break;
      case 'brand_settings':
        if (Number(current_company) === Number(info?.company_id)) {
          const UserDatas = getImages(
            activeUserRole?.company_setting?.company_setting,
            info,
          );
          if (UserDatas)
            activeUserRole.company_setting.company_setting = UserDatas;
          updateActiveUserRole(activeUserRole); // __active__user__info , __active__user__role

          const UseractiveDatas = getImages(
            activeBU?.settings?.company_setting,
            info,
          );
          if (UseractiveDatas)
            activeBU.settings.company_setting = UseractiveDatas;
          updateActiveBU(activeBU); // __active__BU__info

          if (
            userInfo &&
            userInfo.currentRole?.company_setting?.company_setting
          ) {
            const userInfoDatas = getImages(
              userInfo.currentRole.company_setting.company_setting,
              info,
            );
            if (userInfoDatas)
              userInfo.currentRole.company_setting.company_setting =
                userInfoDatas;
            updateUserInfo(userInfo); // __user__info
          }
        }

        if (
          userInfo &&
          userInfo.company_wise_access &&
          userInfo.company_wise_access[info?.company_id]
        ) {
          const userInfoDatas = getImages(
            userInfo.company_wise_access[info?.company_id]?.settings
              ?.company_setting,
            info,
          );
          if (userInfoDatas)
            userInfo.company_wise_access[
              info?.company_id
            ].settings.company_setting = userInfoDatas;
          updateUserInfo(userInfo); // __user__info
        }
        break;
      case 'user_settings':
        if (
          userInfo &&
          userInfo.currentRole &&
          userInfo.currentRole?.user_details
        ) {
          if (userInfo.currentRole?.user_details?.id === info?.userid) {
            userInfo.currentRole.user_details.name = info?.name;
            userInfo.currentRole.user_details.profile_pic = info?.profile_pic;
          }
          updateUserInfo(JSON.parse(JSON.stringify(userInfo))); // __user__info
        }
        break;

      default:
        return null;
    }
  };
  function getImages(company_setting, info) {
    if (company_setting) {
      company_setting['logo'] = info?.logo;
      //if (company_setting?.landing_logo)
      company_setting['landing_logo'] = info?.landing_logo;
      // if (company_setting?.favicon)
      company_setting['favicon'] = info?.favicon;
    }
    return company_setting;
  }
  const updateViewAsEmployee = (flag) => {
    setViewAsEmployee(flag);
  };

  const updateNotifications = (n) => {
    setNotifications(n);
    localStorage.setItem('__notifications__', JSON.stringify(n));
  };
  const updateActiveBU = (bu, setcompany = false) => {
    setActiveBU(JSON.parse(JSON.stringify(bu)));
    setActiveCompanyId(
      bu.settings?.company_setting?.company_id
        ? bu.settings?.company_setting?.company_id
        : bu.settings?.company_id,
    );
    if (setcompany)
      localStorage.setItem(
        '__current_company_id',
        bu.settings?.company_setting?.company_id
          ? bu.settings?.company_setting?.company_id
          : bu.settings?.company_id,
      );
    localStorage.setItem('__active__BU__info', JSON.stringify(bu));
  };
  const updateActiveUserRole = (role) => {
    if (role != {}) {
      setActiveUserRole(role);
      localStorage.setItem('__active__user__info', JSON.stringify(role));
      localStorage.setItem('__active__user__role', JSON.stringify(role));
    }
  };

  const setUserContextDirty = (isDirty) => {
    setUserContextReady(!isDirty);
  };

  const updateActiveUserId = (id) => {
    setActiveUserId(id);
  };

  const updateAssignedLicenseModules = (all_modules) => {
    setAllAssignedModules(all_modules);
  };

  const handleLogout = () => {
    setUserInfo(null);
    localStorage.clear();
  };

  const handleLogin = (accessToken) => {
    setAccessToken(accessToken);
  };

  useEffect(() => {
    const info = localStorage.getItem('__user__info');
    const activeRole = localStorage.getItem('__active__user__role');
    const bu = localStorage.getItem('__active__BU__info');
    const nots = localStorage.getItem('__notifications__');

    if (info && activeRole && bu) {
      const userInfoContext = JSON.parse(info);
      const activeUserRoleContext = JSON.parse(activeRole);
      const activBU = JSON.parse(bu);
      setUserInfo(userInfoContext);
      setActiveUserRole(activeUserRoleContext);
      updateActiveBU(activBU);

      setUserContextReady(true);
    }
    if (nots) {
      updateNotifications(JSON.parse(nots));
    }
  }, []);
  return (
    <UserContext.Provider
      value={{
        userContextReady,
        userInfo,
        activeUserRole,
        activeBU,
        accessToken,
        updateUserInfo,
        updateActiveUserRole,
        setUserContextDirty,
        updateActiveBU,
        viewAsEmployee,
        updateViewAsEmployee,
        setUserContextReady,
        updateActiveUserId,
        activeCompanyId,
        notifications,
        updateNotifications,
        updateUserSettings,
        updateAssignedLicenseModules,
        allAssignedModules,
        handleLogin,
        handleLogout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default UserContext;
