import { useTranslation } from 'react-i18next';

export function useTranslate() {
  const { t, i18n } = useTranslation();

  return {
    translate: t,
    dir: i18n.language === 'ar' ? 'rtl' : 'ltr',
    language: i18n.language,
    isRTL: i18n.language === 'ar' ? true : false,
  };
}
