import React from 'react';

export default function Search() {
  return (
    <path
      id="Search"
      d="M18.821,19.858l-.084-.069L15.61,16.738a9.7,9.7,0,0,1-6,2.06A9.518,9.518,0,0,1,0,9.4,9.518,9.518,0,0,1,9.612,0a9.518,9.518,0,0,1,9.611,9.4,9.251,9.251,0,0,1-2.531,6.349l3.091,3.015a.712.712,0,0,1,0,1.023.751.751,0,0,1-.963.071ZM1.48,9.4a8.052,8.052,0,0,0,8.131,7.951A8.052,8.052,0,0,0,17.743,9.4,8.052,8.052,0,0,0,9.612,1.448,8.052,8.052,0,0,0,1.48,9.4Z"
    />
  );
}
