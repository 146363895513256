import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Pusher from 'pusher-js';

import './assets/sass/app.scss';
import './assets/css/index.css';

window.Pusher = Pusher;

ReactDOM.render(
  <Suspense fallback={<div>Loading...</div>}>
    <App />
  </Suspense>,
  document.getElementById('root'),
);
