import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';

import { ModalActions } from '../../../../components';
import { useTranslate } from '../../../../hooks';

function DeleteModal({ closeModal, isSubmitting, onSubmit }) {
  const { translate } = useTranslate();

  return (
    <div className="w-[350px] min-h-[230px] p-5 bg-white rounded-md app-modal base-modal">
      <div className="flex flex-col items-center gap-4 h-full">
        <div className="ml-auto cursor-pointer" onClick={closeModal}>
          <CloseIcon className="text-gray-400" />
        </div>
        <div className="p-2 rounded-full bg-orange-50 shrink-0">
          <WarningIcon className="!w-8 !h-8 text-orange-300" />
        </div>
        <p className="font-bold welcome-text text-2xl">
          {translate(`Warning!`)}
        </p>
        <p className="font-semibold welcome-text text-sm">
          {translate(`Are You Sure You want to delete?`)}
        </p>
        <div className="mt-[56px] w-full">
          <ModalActions
            className="justify-between "
            cancelButtonProps={{ className: 'min-w-[122px] rounded-md' }}
            applyButtonProps={{ className: 'min-w-[122px] rounded-md' }}
            title={translate('confirm')}
            isSubmitting={isSubmitting}
            onCancel={closeModal}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </div>
  );
}

export default DeleteModal;
