import { useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import 'quill/dist/quill.snow.css';
import './styles.css';

export const RichEditor = ({
  initialContent,
  readOnly,
  onChange,
  errorMsg,
  placeholder,
  value,
  quillRef,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (quillRef) {
      quillRef.current = ref.current;
    }
  }, [quillRef]);

  return (
    <>
      <ReactQuill
        theme={readOnly ? 'bubble' : 'snow'}
        readOnly={readOnly}
        defaultValue={initialContent}
        className="h-[83%]"
        onChange={onChange}
        value={value}
        modules={RichEditor.modules}
        formats={RichEditor.formats}
        placeholder={placeholder}
        ref={ref}
      />

      {errorMsg ? (
        <div className="text-red-500 mt-1 mx-1">{errorMsg}</div>
      ) : null}
    </>
  );
};

RichEditor.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }],
    [{ size: ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    [{ align: [] }],
    ['link', 'image'],
    ['clean'],
  ],
};

RichEditor.formats = [
  'header',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'color',
  'code-block',
  'image',
];
