import { useList } from '../../../../../../../api/react-query';

export function useOTAutomationStatus(params = {}) {
  const {
    data,
    isLoading,
    error,
    refetch
  } = useList(
    'config/company/overtime-auto/status',
    { ...params },
    '',
  )

  return {
    OTAutomation: data?.data?.data,
    OTAutomationLoading: isLoading,
    OTAutomationError: error,
    refetch
  };
}
