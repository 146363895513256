import useGenericMutation from '../use-generic-mutation';
import { API_VERSION } from '../constants';
import api from '../../core';
import { useTranslate } from '../../../hooks';
import { useApp } from '../../../context';

export const useCreate = ({
  url,
  params = {},
  customInvalidateQueriesUrl,
  msg,
  apiVersion = API_VERSION,
}) => {
  const  { translate } = useTranslate();
  const {companyId} = useApp()

  params['locale'] = localStorage.getItem('applang');
  params['companyId'] = companyId;

  return useGenericMutation({
    func: (data) => api.post(`${apiVersion}/${url}`, data, { params }),
    url,
    params,
    customInvalidateQueriesUrl,
    msg: msg ?? translate('created-successfully'),
  });
};
