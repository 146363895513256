import api from './core';
const user = {
  getDetails,
  login,
  getUsers,
  send_password_reset_link,
  reset_password_with_reset_token,
  verify_user_reset_token,
  getCompanyLicence,
  getCompanyLicenceWithPackage,
  sendEmailForLiscenceInquiry,
  getActualCompanyData,
  canAccessEmployeePortal,
  canCreateNewEmpoyee,
  canByPassUser,
  resetPasswordByUsername,
  verifyOtp,
  resetPasswordToken,
};

function getDetails() {
  return new Promise((resolve, reject) => {
    api
      .post('/profile')
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function login(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`/auth/login`, payload)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getUsers(user_email) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/fetch_user?user_email=${user_email}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function send_password_reset_link(id, src) {
  return new Promise((resolve, reject) => {
    api
      .post(`/v1/send_password_reset_link?id=${id}&src=${src}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function verify_user_reset_token(token) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/verify_user_reset_token?token=${token}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function reset_password_with_reset_token(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`/v1/reset_password_with_reset_token`, payload)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getCompanyLicence(companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/cb_package/getCompanyLicence?company_id=${companyId}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getActualCompanyData(companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/cb_package/getActualCompanyData?company_id=${companyId}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getCompanyLicenceWithPackage(companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/cb_package/getCompanyLicenceWithPackage?company_id=${companyId}`,
      )
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function sendEmailForLiscenceInquiry(companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/cb_package/sendMailToSales?company_id=${companyId}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function canAccessEmployeePortal(companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/access/employee_portal_access?company_id=${companyId}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function canCreateNewEmpoyee(companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/access/canCreateNewEmpoyee?company_id=${companyId}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function canByPassUser(companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/access/canByPassUser?company_id=${companyId}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function resetPasswordByUsername(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`/auth/reset-password-expiry`, payload)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function verifyOtp(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`/auth/validate-otp`, payload)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function resetPasswordToken(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`/auth/reset-password-token`, payload)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

export default user;
