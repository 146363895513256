import preferences from "../api/prefrences";
export const preferenceService = {
    setPreference,
    getPreference
};

/**
 * 
 * @param {String} userId 
 * @param {String} companyId 
 * @param {String} prefName 
 * @param {String} prefValue 
 */

function setPreference(userId, companyId, prefName, prefValue) {
    return new Promise((resolve, reject) => {
        if (!userId) {
            return reject("userId is missing");
        }
        if (!companyId) {
            return reject("companyId is missing");
        }
        if (!prefName) {
            return reject("prefName is missing");
        }
        if (!prefValue) {
            return reject("companyId is missing");
        }
        preferences.setPreferece(prefName, prefValue, companyId, userId)
            .then(result => {
                return resolve(result);
            }).catch(err => {
                return reject(err);
            })
        
    })
}
    
function getPreference(userId, companyId) { 
    return new Promise((resolve, reject) => {
        if (companyId) {
            preferences.getPreferenceByUserIdByCompanyId(userId, companyId)
                .then(result => {
                    return resolve(result);
                }).catch(err => {
                    return reject(err);
                })
        } else { 
            preferences
                .getPreferenceByUserId(userId)
                .then((result) => {
                    return resolve(result);
                })
                .catch((err) => {
                    return reject(err);
                });
        }
    });
}