import React from 'react';

export default function Calendar2() {
  return (
    <path
      d="M-3.937-12.937v.563h-2.25V0H6.188V-12.375H3.938v-.562H2.813v.563H-2.812v-.562ZM-5.062-11.25h1.125v.563h1.125v-.562H2.813v.563H3.938v-.562H5.063v1.125H-5.062Zm0,2.25H5.063v7.875H-5.062ZM2.408-7.717-.562-4.746-1.846-6.029l-.809.809L-.967-3.533l.4.387.4-.387L3.217-6.908Z"
      transform="translate(6.188 12.938)"
    />
  );
}
