import useGenericMutation from '../use-generic-mutation';
import { API_VERSION } from '../constants';
import api from '../../core';
import { useTranslate } from '../../../hooks';

export const useUpdate = ({
  url,
  id = '',
  msg,
  customInvalidateQueriesUrl,
  params = {},
  apiVersion = API_VERSION,
}) => {
  const { translate } = useTranslate();

  params['locale'] = localStorage.getItem('applang');

  return useGenericMutation({
    func: (data) => api.put(`${apiVersion}/${url}/${id}`, data, { params }),
    url,
    msg: msg ?? translate('updated-successfully'),
    customInvalidateQueriesUrl,
    params,
  });
};
