import { useList } from '../../list';

export function useListDepartments({ params = {} }) {
  const { data, isLoading, refetch, isFetching, isRefetching, isError } =
    useList('department/select', { ...params }, '', 'v1');

  return {
    data: data?.data?.data,
    isLoading: isLoading || isFetching || isRefetching,
    refetch,
    isError,
    total: data?.data?.data?.length,
  };
}
