import React from 'react';

import { useTranslate } from '../../hooks';
import classNames from 'classnames';
import { MenuItem, Select, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { colors } from '../../helpers/colors';
import { useTranslation } from 'react-i18next';

export function SelectOption({
    className,
    placeholder,
    onChange,
    value,
    containerClassName,
    name,
    options,
    width,
    height
}) {
    const { translate } = useTranslate();
    return (
        <div className={`relative w-fit ${containerClassName}`}>
            <div className="container mx-auto px-4 h-full">
                <div className="flex items-center justify-center h-full w-full">
                    <div
                        className="relative mb-2 flex items-center after:w-[8px] after:h-[8px] after:border-black/70 after:border-b after:border-r after:transform after:rotate-45 after:absolute after:right-3">
                        <select required
                            className={`text-gray-600 bg-white px-3 py-2 transition-all cursor-pointer hover:border-blue-600/30 border border-gray-200 rounded-lg outline-blue-600/50 appearance-none invalid:text-black/30 ${width} ${height}`}
                            value={value}
                            onChange={(event) =>
                                onChange(event.target.value)
                            }
                            disableUnderline>
                            {(options || []).map((lt, i) => {
                                return (
                                    <option value={lt.id}>
                                        {translate(lt.label)}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
}
