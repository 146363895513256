import React from 'react';
import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import classNames from 'classnames';

import { useTranslate } from '../../../../hooks';

function TopNavBar() {
  const navigate = useNavigate();
  const { translate, isRTL } = useTranslate();

  return (
    <>
      <KeyboardBackspaceIcon
        className={classNames('text-gray-400 transform cursor-pointer', {
          'rotate-180': isRTL,
        })}
        onClick={() => navigate('/company/company-setup')}
      />
      <p className="font-bold">{translate('Organisation Setup')}</p>
    </>
  );
}

export default TopNavBar;
