import React from 'react';
import { RadioGroup } from '@mui/material';

export function CustomRadioGroup({
  onChange,
  defaultValue,
  children,
  ...props
}) {
  return (
    <RadioGroup
      defaultValue={defaultValue}
      name="radio-buttons-group"
      onChange={onChange}
      {...props}
    >
      {children}
    </RadioGroup>
  );
}
