import React, { useState } from 'react';
import { LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import { withStyles } from '@mui/styles';

import Compdirection from '../../pages/Common/CompDirection';
import { baseDateTimeFormatting, convertUtcToDate } from '../../helpers/utils';
import { colors } from '../../helpers/colors';

const CssTextField = withStyles({
  root: {
    '& .muiltr-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '15px',
    },
    '& label.Mui-focused': {
      color: colors.primary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: colors.primary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#0000003b',
      },
      '&:hover fieldset': {
        borderColor: colors.primary,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.primary,
      },
    },
  },
})(TextField);

export function TimePickerInput({
  label,
  value = null,
  error,
  helperText,
  onChange,
  onBlur,
  placeholder,
  disabled,
  defaultValue,
  timezoneOffset,
  supportUTC = true,
  variant = 'standard',
  className = 'w-[100px]',
}) {
  const [time, setTime] = useState(null);

  const handleChange = (_date) => {
    onChange?.(_date);
    setTime(_date);
  };

  React.useEffect(() => {
    if (defaultValue && supportUTC) {
      const time = convertUtcToDate(
        defaultValue,
        baseDateTimeFormatting,
        timezoneOffset,
        false,
      );

      setTime(time);
    } else if (defaultValue && !supportUTC && !time) {
      setTime(defaultValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezoneOffset, defaultValue]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Compdirection>
        <MobileTimePicker
          label={label}
          componentsProps={{
            actionBar: {
              actions: ['clear', 'cancel', 'accept'],
            },
          }}
          value={time || value}
          disabled={disabled}
          onAccept={handleChange}
          onChange={(value) => {
            setTime(value);
          }}
          renderInput={(params) => (
            <CssTextField
              {...params}
              variant={variant}
              error={error}
              className={`h-fit ${className}`}
              InputProps={{
                className: `!text-sm !font-bold !text-primary ${className}`,
              }}
              placeholder={placeholder}
              helperText={helperText}
              onBlur={onBlur}
              focused={value}
            />
          )}
        />
      </Compdirection>
    </LocalizationProvider>
  );
}
