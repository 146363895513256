export const colors = {
    primary: "#7C0E6F",
    secondary: "#F5F5FA66",
    grey: "#8181A5",
    lightgrey: "#E6E8F0",
    green: "#21D8A5",
    black: "#081735",
    red: "#FF5263",
    lightprimary: "#f2e6f0",
    barText: "#0C2372",
    white: "#fff",
    success: "#4CAF50",
};
