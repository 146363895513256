export default function BaseRoute({ children }) {
  const pathname = window.location.pathname;
  const userId = localStorage.getItem('userId');
  const userRoles = localStorage.getItem('__active__user__role');

  if (!userId && !pathname.includes('login')) {
    window.location.href = `/login`;
  }

  if (userId && !userRoles) {
    window.location.href = `/company/init?user_id=${userId}`;
  }

  if (
    (userRoles && pathname === '/') ||
    (userRoles && pathname.includes('login'))
  ) {
    window.location.href = '/company/switch-view';
  }

  const appIsUnderMaintenance =
    process.env.REACT_APP_UNDER_MAINTENANCE === 'true' ? true : false;

  if (appIsUnderMaintenance && window.location.pathname !== '/500') {
    window.location.href = '/500';
    return;
  }

  return children ? <>{children}</> : null;
}
