import React from 'react';

export default function DoubleCheck() {
  return (
    <path
      d="M13.1-11.654,6.469-5.027,4.9-6.592,4.1-5.783,5.66-4.219,4.5-3.059.967-6.592l-.809.809L4.1-1.846l.4.387.4-.387L6.469-3.41,8.033-1.846l.4.387.4-.387,8.947-9-.809-.809-8.543,8.6-1.16-1.16L13.9-10.846Z"
      transform="translate(-0.158 11.654)"
    />
  );
}
