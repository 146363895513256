import { useEffect } from 'react';

// props : expecting current value of ref. pass like ref.current
export function useInfinityScrollTrigger({ ref, onReachBottom }) {
  useEffect(() => {
    const scrollListener = (e) => {
      if (
        Math.round(ref?.scrollTop) !== 0 &&
        e?.target?.scrollHeight - e?.target?.clientHeight <=
          Math.round(e?.target?.scrollTop) + 2
      ) {
        onReachBottom?.();
      }
    };

    if (ref) ref?.addEventListener('scroll', scrollListener);

    return () => {
      if (ref) ref?.removeEventListener('scroll', scrollListener);
    };
  }, [onReachBottom, ref]);

  return null;
}
