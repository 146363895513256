import coreApi from './core';

const refreshToken = async (api) => {
  const response = await api({
    method: 'POST',
    url: `auth/refresh-token`,
  });

  return response;
};

const refreshAndRetryQueue = [];

// Flag to prevent multiple token refresh requests
let isRefreshing = false;

export const refreshTokenInterceptor = async (error, api) => {
  const originalRequest = error.config;

  if (
    error?.response?.status === 401 &&
    originalRequest?.url?.includes('refresh-token')
  ) {
    refreshAndRetryQueue.length = 0;
    localStorage.clear();

    window.location.href = '/login';

    return null;
  }

  if (error.response && error.response.status === 401) {
    if (!isRefreshing) {
      isRefreshing = true;
      try {
        await refreshToken(coreApi);

        refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
          api
            .request(config)
            .then((response) => resolve(response))
            .catch((err) => reject(err));
        });

        refreshAndRetryQueue.length = 0;

        return api(originalRequest);
      } catch (refreshError) {
        throw refreshError;
      } finally {
        isRefreshing = false;
      }
    }

    return new Promise((resolve, reject) => {
      refreshAndRetryQueue.push({
        config: originalRequest,
        resolve,
        reject,
      });
    });
  }

  return Promise.reject(error);
};
