import { useState } from 'react';

export function useValidation(validationRoles, formData) {
  const [errors, setErrors] = useState({});

  const validate = async () => {
    try {
      await validationRoles.validate(formData, { abortEarly: false });

      setErrors({});

      return true;
    } catch (error) {
      const newErrors = {};

      error.inner?.forEach((error) => {
        newErrors[error.path || 'list'] = error.message;
      });

      setErrors(newErrors);

      return false;
    }
  };

  return {
    errors,
    validate,
  };
}
