import React from 'react';
import './ForbiddenPage.css'; // Import the CSS file
import { Container } from '@mui/material';

const ForbiddenPage = () => {
  return (
    <div
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <Container
        fixed
        maxWidth="xl"
        style={{
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <img
          src={require('../assets/images/403.png')}
          width={'100%'}
          height={'auto'}
          alt=""
        />
      </Container>
    </div>
  );
};

export default ForbiddenPage;
