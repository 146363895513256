import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
  // status: {
  //   danger: "#e53e3e",
  // },
  palette: {
    primary: {
      main: "#febd2f",
    },

    secondary: {
      main: "#f5f5f5",
      contrastText: "#fff",
    },
  },
  typography: {
    h3: {
      color: "#3f3232",
    },
    h4: {
      color: "#3f3232",
    },
    h5: {
      color: "#3f3232",
    },
    h6: {
      color: "#00000",
      fontWeight: 300,
    },
  },
  components: {
    MuiTypography:{
      styleOverrides: {
        // root:{
        //   color:'#8181a5',
        //   fontSize:'14px',
        //   paddingLeft:'0.5em',
        //   fontWeight:'400'
        // },
        body1:{
          color:'#8181a5',
          fontSize:'14px',
          // paddingLeft:'0.5em',
          fontWeight:'400'
        },
      }
    },
    MuiListItemText:{
      styleOverrides: {
        primary:{
          color:'#8181a5',
          fontWeight:'400',
          font:'normal normal bold 14px',
          letterSpacing: '0px',
          opacity:1
        }
      }
    },
    MuiSvgIcon:{
      styleOverrides: {
        root:{
          fontSize:'22px',
          color:'#8181a5',
        }
      }
    },
    MuiListItemButton:{
      styleOverrides: {
        root:{
          width:'242px',
          height:'60px'
        }
      }
    },
    MuiStepIcon: {
      styleOverrides:{
        root: {
          color: '#21D8A5', // or 'rgba(0, 0, 0, 1)'
          '&$active': {
              color: '#21D8A5',
          },
          '&$completed': {
              color: '#21D8A5',
          },
      },
      }
      
  },

  

  }
});
theme = responsiveFontSizes(theme);

export default theme;
