import * as Yup from 'yup';

export const loginValidation = Yup.object({
  username: Yup.string().required('*Username is required'),
  password: Yup.string().required('*Password is required'),
});

export const otpValidation = Yup.object({
  otp: Yup.string().required('*OTP is required')
});


export const validatePassword = (inputPassword) => {
  // Define a regular expression pattern for the password format
  const passwordPattern =
    /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9a-zA-Z]).{8,}$/;

  if (!passwordPattern.test(inputPassword)) {
    return 'Password should be: Uppercase, one special character, and Alphanumeric, minimum 8 characters.';
  } else {
    return null;
  }
};