import * as React from 'react';
import { RADIO_CHOICES } from '../../../../../../common/constants';
import { useTranslation } from 'react-i18next';
import { DateField } from '../../../../../../components';
import { baseDateFormatting } from '../../../../../../helpers/utils';
import { EmployeeProfile } from '../../../CommonComponents/EmployeeProfile';
const Download = ({ evaluations, logo }) => {
  const { t } = useTranslation();
  const questionIsAnswered = (answers, optionId) => {
    return !!answers.find((answer) => answer.answerOptionId === optionId);
  };

  const getMaxScore = (options, questionId) => {
    const score = options.find(
      (option) => option.questionId === questionId && option.max_score !== 0,
    );
    return score?.max_score;
  };

  const getScore = (answers, questionId) => {
    return answers.find((answer) => answer.questionId === questionId)?.score;
  };
  return (
    <>
      <div className="" id="QuestionnaireForm">
        <div className="bg-white p-5 border rounded-md ">
          <div className="flex justify-end w-full">
            <div className="h-20 w-48">
              <img src={logo.companyLogo} alt="" className="max-h-40" />
            </div>
          </div>
          <div className="flex justify-center">
            <h2 className="text-3xl font-bold ">
              {evaluations?.[0]?.kpi.title}
            </h2>
          </div>
          <div className="flex justify-between mt-10 mx-2">
            <div className="flex flex-col">
              <h2 className="text-primary text-lg font-bold">
                {evaluations?.[0]?.employee.name}
              </h2>
              <h2 className="text-gray-500 text-md">
                {t('Emp Id')} :{' '}
                <span className="font-bold">
                  {evaluations?.[0]?.employee.staffId}
                </span>{' '}
              </h2>
              <h2 className="text-gray-500 text-md">
                {t('Designation')} :{' '}
                <span className="font-bold">
                  {evaluations?.[0]?.employee.designationName}
                </span>
              </h2>
            </div>
            <div className="py-3">
              <div className=" flex gap-0">
                <span className="mx-1 text-md text-gray-500">
                  {t('Start Date')} :{' '}
                </span>
                <DateField
                  className="font-bold text-gray-500 text-md"
                  value={evaluations?.[0]?.cycle.startDate}
                  format={baseDateFormatting}
                />
              </div>
              <div className="flex gap-0">
                <span className="mx-1 text-md text-gray-500">
                  {t('End Date')} :{' '}
                </span>
                <DateField
                  className="font-bold text-gray-500 text-md"
                  value={evaluations?.[0]?.cycle.endDate}
                  format={baseDateFormatting}
                />
              </div>
            </div>
          </div>

          {evaluations.map((evaluation, key) => {
            return (
              <>
                <div className="border rounded-md px-10 py-4 mt-5" key={key}>
                  <div className="flex justify-between my-5">
                    <div className="flex flex-col">
                        <EmployeeProfile
                          name={evaluation.evaluator?.name}
                          profileImage={evaluation.evaluator?.profileImage}
                        />
                      <div className="-mt-2 px-12">
                        <span className="text-gray-500 ml-1 text-sm">
                          {evaluation.evaluator.roleName}
                        </span>
                      </div>
                    </div>
                    <div>
                      <span className="mt-1 bg-green-100/70 px-2 py-1 rounded-md text-xs  text-green-400">
                        {t('Evaluator')}
                      </span>
                    </div>
                  </div>
                  <h2 className="font-bold text-primary text-3xl py-4">
                    {evaluation.questionnaire.title}
                  </h2>
                  <p className="">
                    <span className="text-lg font-bold">{t('Note')} : </span>
                    <span className="text-gray-500 text-lg">
                      {evaluation.questionnaire.description}
                    </span>
                  </p>
                  {evaluation.questionnaire.questions.map((question) => (
                    <>
                      <div className="mt-10">
                        {question.type === RADIO_CHOICES ? (
                          <>
                            <div className="flex justify-between gap-4">
                              <div className="w-25">
                                <h2 className="font-bold text-gray-950 text-2xl">
                                  {question.title}
                                </h2>
                                <p className="py-3 text-gray-400 text-lg">
                                  {question.description}
                                </p>
                                <div className="flex flex-col mt-5 justify-start mx-4 gap-2">
                                  {question.answerOptions.map((option, key) => {
                                    return (
                                      <div
                                        className="bg-gray-400/10 p-2 rounded"
                                        key={key}
                                      >
                                        <input
                                          id={
                                            'default-radio-' +
                                            evaluation.evaluator.id
                                          }
                                          type="radio"
                                          checked={questionIsAnswered(
                                            question.answers,
                                            option.id,
                                          )}
                                          name={
                                            'default-radio-' +
                                            evaluation.evaluator.id
                                          }
                                          value=""
                                          className="w-4 h-4
                                text-primary bg-gray-100 border-gray-300
                                focus:ring-primary dark:focus:ring-primary
                                dark:ring-offset-gray-800 mx-1"
                                        />
                                        <label
                                          htmlFor={
                                            'default-radio-' +
                                            evaluation.evaluator.id
                                          }
                                          className="ms-2 w-4 h-4 text-gray-600 rounded-md dark:text-gray-300"
                                        >
                                          <span className=" text-lg">
                                            {option.title}
                                          </span>
                                        </label>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                              <div className="mt-12">
                                <span className="h-10 w-25 px-5 py-2 bg-green-100/20 rounded-lg border border-gray-300">
                                  {' '}
                                  {getMaxScore(
                                    question.answerOptions,
                                    question.id,
                                  )}{' '}
                                </span>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="flex justify-between gap-4">
                              <div className="flex-1">
                                <h2 className="font-bold text-gray-950 mt-10 text-2xl">
                                  {question.title}
                                </h2>
                                <p className="text-gray-400 text-lg">
                                  {question.description}
                                </p>
                                <div className="flex flex-col mt-5 justify-start mx-4 gap-3">
                                  <div className="flex flex-col">
                                    <label className="text-lg font-bold text-gray-500 my-2">
                                      {t('Short Answer')}
                                    </label>
                                    <textarea
                                      disabled
                                      className="h-32 outline-0 border-2 border-gray-300 rounded-md p-2"
                                      value={question.answers[0]?.value}
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-24">
                                <span className="h-10 w-25 px-5 py-2 bg-green-100/20 rounded-lg border border-gray-300">
                                  {' '}
                                  {getScore(question.answers, question.id)}{' '}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  ))}
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default Download;
