import React from 'react';
import Switch from '@mui/material/Switch';
import classNames from 'classnames';

export function ToggleInput({
  checked,
  label,
  value,
  defaultChecked,
  onChange,
  name,
  disabled,
}) {
  return (
    <div className="flex items-center gap-x-1">
      <Switch
        value={value}
        checked={checked}
        defaultChecked={defaultChecked}
        name={name}
        disabled={disabled}
        color="success"
        onChange={(e) => onChange?.(e.target.checked)}
      />

      <span
        className={classNames('text-sm', {
          'text-gray-400': !checked,
          'text-green-500': checked,
        })}
      >
        {label}
      </span>
    </div>
  );
}
