import { FILE_EXTENSION_CSV, FILE_EXTENSION_XLS } from '../constants';

export const downloadExcelFile = async (path , name = "excel") => {
  try {
    const response = await fetch(path);
    const blob = await response.blob();
    let fileExtension = FILE_EXTENSION_XLS;
    if(path.includes(FILE_EXTENSION_CSV)) {
      fileExtension = FILE_EXTENSION_CSV;
    }

    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = `${name}_${new Date().getTime()}.${fileExtension}`;
    downloadLink.click();
    window.URL.revokeObjectURL(downloadLink.href);
  } catch (error) {
    console.error('Error downloading Excel file', error);
  }
}
export const downloadPdfFile = async (path , name = "download") => {
  try {
    const response = await fetch(path);
    const blob = await response.blob();

    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = `${name}_${new Date().getTime()}.pdf`;
    downloadLink.click();
    window.URL.revokeObjectURL(downloadLink.href);
  } catch (error) {
    console.error('Error downloading Excel file', error);
  }
}

export const showFile = async ( url) => {
  const showLink = document.createElement('a');
  showLink.href = url;
  showLink.setAttribute('target' , '_blank');
  showLink.click();
}