import React from 'react';

export function useGetTableLayout({ type }) {
  const tableLayout = (type) => {
    switch (type) {
      // for all company configuration table
      case 1: {
        return {
          customRowRender: (data, dataIndex) => {
            return (
              <tr>
                {data?.map((column) => {
                  return (
                    <td className="text-xs px-4 py-3">
                      <p className="text-xs text-text">{column}</p>
                    </td>
                  );
                })}
              </tr>
            );
          },
          setTableProps: () => {
            return {
              className: 'table-v2',
            };
          },
          tableBodyHeight: '70vh',
        };
      }

      // for shift and schedule table
      case 2: {
        return {
          customRowRender: (data) => {
            return (
              <tr>
                {data?.map((column) => {
                  return (
                    <td
                      align="center"
                      className="text-xs px-1 py-1 bg-white text-text text-center"
                    >
                      {column}
                    </td>
                  );
                })}
              </tr>
            );
          },
          setTableProps: () => {
            return {
              className: 'table-shif-and-schedule',
            };
          },
          tableBodyHeight: '68vh',
          pagination: false,
        };
      }

      // for swap shift table
      case 3: {
        return {
          setTableProps: () => {
            return {
              className: 'swap-shift-table',
            };
          },
          tableBodyHeight: '65vh',
        };
      }

      // for view shift Details
      case 4: {
        return {
          customRowRender: (data) => {
            return (
              <tr>
                {data?.map((column) => {
                  return (
                    <td className="text-xs p-3 bg-white" align="center">
                      {column}
                    </td>
                  );
                })}
              </tr>
            );
          },
          setTableProps: () => {
            return {
              className: 'view-shift',
            };
          },
          pagination: false,
        };
      }

      default: {
        return {};
      }
    }
  };

  return { layout: tableLayout(type) };
}
