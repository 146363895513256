import React from 'react';

function SubItems({ item, isSelected, handleClick }) {
  return (
    <p
      className={`text-xs py-2 px-4 cursor-pointer text-text tracking-[0.2px] ${
        isSelected
          ? 'bg-primary-light rounded-md sub-item !text-primary font-semibold'
          : ''
      }`}
      onClick={handleClick}
    >
      {item?.title}
    </p>
  );
}

export default SubItems;
