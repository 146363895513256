import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';

export default function Alert({ snackbarMsg, toggle, className, autoHideDuration = 4000 }) {
  return (
    <Snackbar
      open={snackbarMsg ? true : false}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      autoHideDuration={autoHideDuration}
      onClose={() => toggle(null)}
    >
      <SnackbarContent message={snackbarMsg} className={className} />
    </Snackbar>
  );
}
