import React from 'react';

import Empty from '../icons/Empty';

//TODO: remove duplication of this comp on v2 folder
export function EmptyList({ title, message, className }) {
  return (
    <div
      className={`h-[73vh] flex items-center justify-center flex-col gap-2 ${className}`}
    >
      <Empty height={120} width={180} />
      {title && <p className="text-sm font-semibold">{title}</p>}
      {message && (
        <p className="text-xs text-text max-w-[300px] text-center leading-[21px]">
          {message}
        </p>
      )}
    </div>
  );
}
