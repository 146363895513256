import { useMutation } from 'react-query';
import toast from 'react-hot-toast';

import { queryClient } from '../../common/queryClient';

const useGenericMutation = ({
  func,
  url,
  params,
  customInvalidateQueriesUrl,
  msg,
}) => {
  return useMutation(func, {
    mutationKey: [url, params],
    onMutate: async (data) => {
      return data;
    },

    onError: (err, _, context) => {
      const errors = err?.response?.data?.errors?.code;
      if (Array.isArray(errors)) {
        const message = errors.reduce((prevValue, currentValue) => {
          return `${prevValue ? `${prevValue}, ${currentValue}` : currentValue}`;
        }, '');

        toast.error(message, {
          className: 'bg-red-400 text-white',
        });
      } else {
        const errorMessage = err?.response?.data?.message || err?.response?.data?.data;
        toast.error(errorMessage, {
          className: 'bg-red-400 text-white',
        });
    }

      queryClient.setQueryData([customInvalidateQueriesUrl || url], context);
    },

    onSuccess: (data) => {
      queryClient.invalidateQueries([customInvalidateQueriesUrl || url]);
      if (data?.data?.message) toast.success(data?.data?.message)
      else if (msg) toast.success(msg);
    },
  });
};

export default useGenericMutation;
