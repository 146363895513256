import { useGetOne } from '../../view';

export function useGetTimeShift({ id, onSuccess }) {
  const { data, isLoading, isFetching, isRefetching } = useGetOne(
    `shift-management/shift-patterns/${id}`,
    {
      onSuccess,
    },
  );

  return {
    data: data?.data?.data,
    isLoading: isLoading || isFetching || isRefetching,
  };
}
