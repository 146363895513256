import { useInfiniteQuery } from 'react-query';

import { API_VERSION } from '../constants';
import { useApp } from '../../../context';
import api from '../../core';

export const useInfiniteListSimplePagination = (
  url,
  params = {},
  config,
  apiVersion = API_VERSION,
) => {
  const _params = {};
  const { companyId, rowsPerPage } = useApp();

  Object.entries(params).forEach(([key, value]) => {
    if (value) _params[key] = value;
  });

  _params['companyId'] = companyId;
  _params['locale'] = localStorage.getItem('applang');
  _params['limit'] = rowsPerPage;

  const context = useInfiniteQuery(
    [url, _params],
    async ({ queryKey, pageParam = 0 }) => {
      const resolvedQueryKey = [
        queryKey[0],
        {
          ...queryKey[1],
          skip: pageParam * rowsPerPage,
        },
      ];
      return {
        data: await fetcher({ queryKey: resolvedQueryKey, apiVersion }),
        pageParam,
      };
    },
    {
      enabled:
        config?.enabled !== undefined ? (url ? true : false) : config?.enabled,
      ...config,
      getNextPageParam: (lastPage) => {
        const { data, pageParam } = lastPage;

        return data?.data?.data?.data?.length > 0 ? pageParam + 1 : false;
      },
    },
  );

  return context;
};

const fetcher = async ({ queryKey, apiVersion }) => {
  const [url, params] = queryKey;
  return {
    data: await api.get(`${apiVersion}/${url}`, {
      params: { ...params },
    }),
    pageParam: params.page,
  };
};
