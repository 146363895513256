import React from 'react';
import Compdirection from './pages/Common/CompDirection';
import { UserProvider } from './context/UserContext';
import { RouterProvider } from 'react-router-dom';
import { router } from './router/router';
import { AppProvider } from './context';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './common/queryClient';

const App = () => {
  return (
    <Compdirection>
      <QueryClientProvider client={queryClient}>
        <UserProvider>
          <AppProvider>
            <RouterProvider router={router} />
          </AppProvider>
        </UserProvider>
      </QueryClientProvider>
    </Compdirection>
  );
};

export default App;
