import { useState } from 'react';

export function useModalActions() {
  const [isLoading, setIsLoading] = useState(false);

  const toggleLoading = (_isLoading) => {
    setIsLoading(_isLoading);
  };

  return {
    isLoading,
    toggleLoading,
  };
}
