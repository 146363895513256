import React from 'react';
import ToggleButton from '../toggle/ToggleButton';
import { useTranslate } from '../../hooks';

//TODO: remove duplication of this comp on v2 folder
export function ToggleWithLabel2({ label, isActive, handleChange, className }) {
  const { translate, isRTL } = useTranslate();

  return (
    <div
      className={`${className ? className : 'w-full h-[50px]'} flex items-center gap-2 rounded-md p-3 border-[1px] border-[#d0d1d3]`}
    >
      {label && <p className="text-xs">{label}</p>}
      {isActive ? (
        <p
          className={`text-[#4CAF50] text-xs ${isRTL ? 'mr-auto' : 'ml-auto'} font-semibold`}
        >
          {translate('Active')}
        </p>
      ) : (
        <p
          className={`text-[#af4c4c] text-xs ${isRTL ? 'mr-auto' : 'ml-auto'} font-semibold`}
        >
          {translate('Inactive')}
        </p>
      )}
      <ToggleButton checked={isActive} handleCheck={handleChange} label={''} />
    </div>
  );
}
