import React, { useState } from 'react';
import {
  Grid,
  Modal,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import toast from 'react-hot-toast';
import user from '../api/user';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import FetchImage from './Common/FetchImage';
import { StaticBaseURL } from '../common/constants';

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#00000041',
    borderRadius: '2em',
    color: '#7C0E6F',
    border: '0px',
  },
  '& input': {
    fontSize: '0.85em',
    borderRadius: '6px',
    backgroundColor: '#FFFFFF41',
    border: '0px',
    color: 'white',
  },
});
const LockScreenView = (props) => {
  const [pswrd, setPswrd] = useState('');
  const { t } = useTranslation();

  const handleUnlock = () => {
    if (props?.user_name) {
      const payload = {
        username: props?.user_name,
        password: pswrd,
      };
      user
        .login(payload)
        .then((res) => {
          const _userId = Cookies.get('user-id');
          window.location.href = `/company/init?user_id=${_userId}`;
        })
        .catch((err) => {
          console.log('err while getting login', err);
        });
    } else {
      toast?.error(t('No user found!'));
    }
  };
  //--->>> EMP LOCK SCREEN
  return (
    <Modal
      open={props.isModalOpen}
      onClose={props.closeModal}
      disableEscapeKeyDown={true}
    >
      <Grid
        container
        style={{
          backgroundImage: 'url(/public/images/login_bg.png)',
          position: 'relative',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Grid
          item
          xs={7}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img src={`${StaticBaseURL}login_logo.png`} alt='' />
        </Grid>
        <Grid
          className="User-name"
          item
          xs={4}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <FetchImage
            type={'avatar'}
            src={`${props?.user_pic}`}
            style={{
              width: '120px',
              height: '120px',
              border: '2px solid white',
            }}
          />

          <Typography
            style={{
              color: '#FFFFFF',
              fontSize: '20px',
              marginTop: '0.5em',
              fontWeight: 'bold',
            }}
          >
            {props?.name}
          </Typography>
          <StyledTextField
            type="password"
            fullWidth={false}
            value={pswrd}
            onChange={(e) => setPswrd(e.target.value)}
            style={{ marginTop: '0.5em' }}
            inputProps={{
              style: {
                height: '10px',
              },
            }}
            onKeyPress={(e) => {
              if (e?.which === 13) {
                handleUnlock(pswrd);
              }
            }}
          />
          <Typography
            style={{
              marginTop: '1em',
              
              fontSize: '14px',
              color: '#FFFFFF',
            }}
          >
            {t('Enter Password')}
          </Typography>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default LockScreenView;
