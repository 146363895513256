import { MutationCache, QueryCache, QueryClient } from 'react-query';
import { CustomToast } from './Methodcommon';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
  mutationCache: new MutationCache({
    onError: (error, _variables, _context, mutation) => {
      if (mutation.options.onError) return;
    },
  }),
  queryCache: new QueryCache({
    onError: (err) => {
      CustomToast(
        'error',
        err?.response?.data?.data ||
          err?.response?.data?.message ||
          'Something went wrong!',
      );
    },
  }),
});
