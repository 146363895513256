import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import classNames from 'classnames';

import { useTranslate, useValidation } from '../../../../../../../hooks';
import { useCreateOvertimeConfig, useListAllowanceTypes, useListShiftTypes } from '../api';

import { addOvertime } from '../validations';
import { checkData } from '../../../../utils';

import { AutoComplete, Button, CustomRadioGroup, RadioButton, TextInput, ToggleWithLabel2 } from '../../../../../../../components';
import { LoadingButton } from '../../../../../../../components/buttons';
import LoaderCotainer from '../../../../components/LoaderCotainer';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import AddedListItem from '../../../../components/list/AddedListItem';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { CustomToast } from '../../../../../../../common/Methodcommon';
import { colors } from '../../../../../../../helpers/colors';
import { Box, ButtonBase, Typography } from '@mui/material';
import _ from 'lodash';
import { useApp } from '../../../../../../../context';
import useCompOffList from '../../../core-hr-pages/comp-off/api/use-comp-off-list';

function AddOvertimeConfPage() {
  const { translate, isRTL } = useTranslate();
  const navigate = useNavigate();
  const { companyId } = useApp()
  const [openModel, closeModel] = useState(false)
  const { allowanceTypes, allowanceTypesLoading } = useListAllowanceTypes({
    company_id: companyId
  })
  const { shiftTypes, shiftTypeLoading } = useListShiftTypes({})

  const [form, setForm] = useState({
    shiftType: null,
    amount: '',
    amountType: '',
    salaryComponent: [],
    maxOTHours: '',
    minOTHours: '',
    shiftCode: '',
    amountType: 0,
    name: '',
    dailySalHrs: ''
  });
  const [addList, setAddList] = useState({ fromSuggetion: [], fromForm: [] });

  const { errors, validate } = useValidation(addOvertime, {
    shiftType: form?.shiftType,
    amount: form?.amount,
    minOTHours: form?.minOTHours,
    maxOTHours: form?.maxOTHours,
    shiftCode: form?.shiftCode,
    dailySalHrs: form?.dailySalHrs
  });

  const wholeList = [...addList?.fromSuggetion, ...addList?.fromForm];

  const { createOvertimeConfig, isCreating } = useCreateOvertimeConfig({})
  const { data: compOffList, isLoading: compOffListLoading, } = useCompOffList();

  const handleSave = async (e) => {
    e.preventDefault();
    if (
      addList?.fromForm?.length === 0 &&
      addList?.fromSuggetion?.length === 0
    ) {
      return;
    }
    let payload = {
      overtimeConfig: wholeList?.map((item) => ({
        shiftTypeId: item?.shiftType?.id,
        amount: item?.amount,
        amountType: item?.amountType,
        salaryComponent: item?.amountType == 0 ? item?.salaryComponent : [],
        minOTHours: item?.minOTHours,
        maxOTHours: item?.maxOTHours,
        shiftCode: item?.shiftCode,
        dailySalHrs: item?.dailySalHrs,
      }))
    }
    createOvertimeConfig(payload).then((res) => navigate(-1))
  };

  useEffect(() => {
    if (form?.shiftType) {
      setForm((prevForm) => ({
        ...prevForm,
        name: form?.shiftType?.label || '',
      }));
    }
  }, [form?.shiftType]);

  const handleChange = ({ key, value }) => {
    setForm((prev) => ({
      ...prev,
      [key]: value,
    }));
  };


  const addFromForm = async (e) => {
    e.preventDefault();
    if (await validate()) {
      try {
        checkData({ data: form, list: wholeList });

        if (form.amountType == 0 && form.salaryComponent.length == 0 ) CustomToast('error', 'Please select the salary component')
        else {
          setAddList((prev) => ({
            ...prev,
            fromForm: [...prev?.fromForm, { name: form?.name, ...form }],
          }));
        }
        closeModel(false)
      } catch (error) {
        CustomToast('error', error?.message);
      }
    }
  };

  const deleteFromFormAdded = (suggestion, deleteIndex) => {
    setAddList((prev) => {
      let newList = [];
      newList = prev?.fromForm?.filter((item, index) => deleteIndex !== index);
      return { ...prev, fromForm: newList };
    });
  };

  const onDeleteAll = () => {
    setAddList(() => ({ fromForm: [], fromSuggetion: [] }));
  };

  return (
    <div className="bg-white overflow-y-auto">
      <span className="flex items-center gap-x-2 mb-3 h-[26px]">
        <span className="flex gap-1">
          <KeyboardBackspaceIcon
            className={classNames(
              'text-gray-400 transform cursor-pointer mr-2',
              {
                'rotate-180': isRTL,
              },
            )}
            onClick={() => navigate(-1)}
          />
          <p className="welcome-text font-bold">{translate('Add')}</p>
          <p className="welcome-text2 font-bold">{translate('Overtime Configuration')}</p>
        </span>
      </span>
      <div className="w-full add-configuration-section-card-container">
        {/* <div className="w-[33%] flex flex-col gap-[14px]">
          <div className="w-[100%]">
            {isLoading ? (
              <LoaderCotainer />
            ) : (
              <SuggestionList
                handleSuggestionSelect={addFromSuggetions}
                selectedCount={sugSeletedCount}
                suggetionCount={suggetionCount}
                suggetionsList={suggetionsList}
                onAddAll={onAddAll}
              />
            )}
          </div>
        </div> */}
        <div className="w-[33%]">
          <form
            className="flex flex-col gap-[11px] add-configuration-section-card overflow-y-auto w-[100%] rounded-md !border-border !border-2 p-3"
          >
            <p className="text-primary text-sm tracking-[0.6px] font-semibold">
              {translate('Add Overtime Configuration')}
            </p>
            <AutoComplete
              isLoading={shiftTypeLoading}
              label={translate('Shift Type')}
              data={shiftTypes?.map((item, index) => ({
                id: item?.id,
                label: item?.name,
              }))}
              onChange={(option) => {
                handleChange({ key: 'shiftType', value: option });
              }}
              errorText={errors?.shiftType}
              showError={errors?.shiftType}
              className={`w-full h-[56px] border-[${colors?.lightgrey}]`}
              inputProps={{
                fullWidth: true,
              }}
              value={
                shiftTypes?.find((item) => item.key === form.shiftType) ||
                form?.shiftType
              }
            />
            <Box className='flex flrx-row spacing-1 h-75'>
              <TextInput
                fullWidth
                type='number'
                error={errors?.minOTHours}
                helperText={errors?.minOTHours}
                defaultValue={form?.minOTHours}
                label={translate('Min Hour')}
                step='0.1'
                onChange={(e) => {
                  let value = parseFloat(e?.target?.value);
                  if (!isNaN(value)) {
                    value = parseFloat(value.toFixed(1));
                    if (value % 1 === 0) {
                      value = parseFloat(value.toFixed(0));
                    }
                  }
                  handleChange({
                    key: `minOTHours`,
                    value: value,
                  });
                }}
              />
              <Typography
                className='grid content-center px-3 text-base'
              >
                {"and"}
              </Typography>
              <TextInput
                fullWidth
                type='number'
                label={translate('Max Hour')}
                error={errors?.maxOTHours}
                helperText={errors?.maxOTHours}
                defaultValue={form?.maxOTHours}
                step='0.1'
                onChange={(e) => {
                  let value = parseFloat(e?.target?.value);
                  if (!isNaN(value)) {
                    value = parseFloat(value.toFixed(1));
                    if (value % 1 === 0) {
                      value = parseFloat(value.toFixed(0));
                    }
                  }
                  handleChange({
                    key: `maxOTHours`,
                    value: value,
                  });
                }}
              />
            </Box>
            <CustomRadioGroup
              className='flex flex-row'
              defaultValue={form?.amountType}
              onChange={(e) => {
                handleChange({
                  key: 'amountType',
                  value: e?.target?.value
                })
              }}
            >
              <RadioButton
                value={1}
                key={'fixed'}
                label={translate('Fixed Amount')}
              />
              <RadioButton
                value={0}
                key={'percentage'}
                label={translate('%Percentage')}
              />
            </CustomRadioGroup>
            {form.amountType == 1 &&
              <TextInput
                fullWidth
                type='number'
                label={translate('Enter Amount')}
                error={errors?.amount}
                helperText={errors?.amount}
                defaultValue={form?.amount}
                onChange={(e) => {
                  handleChange({
                    key: `amount`,
                    value: e?.target?.value,
                  });
                }}
              />
            }
            {form.amountType == 0 &&
              <TextInput
                fullWidth
                type='number'
                label={translate('Enter Percentage')}
                error={errors?.amount}
                helperText={errors?.amount}
                defaultValue={form?.amount}
                onChange={(e) => {
                  handleChange({
                    key: `amount`,
                    value: e?.target?.value
                  });
                }}
              />
            }
            {form.amountType == 0 &&
              <Box>
                <Typography className='text-sm tracking-[0.6px]'>
                  {translate('Salary Components')}
                </Typography>
                <Box
                  className='bg-slate-100 rounded-lg py-2 px-1'
                >
                  {_.map(allowanceTypes, (allowance) => {
                    const checked = _.some(form?.salaryComponent, (e) => {
                      return e == allowance?.id
                    })
                    return (
                      <ButtonBase
                        sx={{
                          padding: '0.3em 0.6em',
                          margin: '0.2em 0em 0.2em 0.7em',
                          border: !checked ? '2px solid #7C0E6F' : '2px solid white',
                          borderRadius: '1em',
                          color: !checked ? '#7C0E6F' : 'white',
                          fontSize: '11px',
                          backgroundColor: !checked ? 'white' : '#7C0E6F'
                        }}
                        onClick={(e) => {
                          const newSalaryComponent = checked
                            ? form.salaryComponent.filter(e => e !== allowance?.id)
                            : [...form.salaryComponent, allowance?.id];

                          handleChange({
                            key: 'salaryComponent',
                            value: newSalaryComponent,
                          });
                        }}
                      >
                        {allowance?.name}
                      </ButtonBase>
                    )
                  })}
                </Box>
              </Box>
            }
            <TextInput
                fullWidth
                type='number'
                label={translate('Daily Salary Hours')}
                error={errors?.dailySalHrs}
                helperText={errors?.dailySalHrs}
                defaultValue={form?.dailySalHrs}
                onChange={(e) => {
                  handleChange({
                    key: `dailySalHrs`,
                    value: e?.target?.value
                  });
                }}
              />
            <LoadingButton
              className="bulk-import-btn !border-solid gap-x-1 shrink-0 px-5 rounded-[4px] w-full"
              type="submit"
              onClick={addFromForm}
            >
              {translate('Add')}
            </LoadingButton>
          </form>
        </div>
        <div className="w-[33%] flex flex-col gap-[14px]">
          <div className="w-[100%] rounded-md !border-border !border-2 p-3 add-configuration-section-card ">
            <div className="w-full flex justify-between">
              <p className="text-primary text-sm tracking-[0.6px] font-semibold">
                {translate('Added')}
              </p>
              <Button
                className={`outline-btn min-w-[75px] shrink-0 px-2 pr-3 rounded-[4px] h-7 flex items-center gap-[5px]`}
                onClick={onDeleteAll}
              >
                <i className="las la-trash-alt text-error cursor-pointer !text-[16px]"></i>
                {translate('Delete All')}
              </Button>
            </div>
            <div className="overflow-y-auto flex flex-col mt-3 gap-2 mb-0 w-full added-list-section px-1">
              {addList?.fromForm?.map((item, index) => (
                <AddedListItem
                  key={item?.name}
                  value={item}
                  handleDelete={() => deleteFromFormAdded(item, index)}
                />
              ))}
              {/* {addList?.fromSuggetion?.map((item, index) => (
                <AddedListItem
                  key={item?.name}
                  value={item}
                  handleDelete={() => deleteFromSuggetions(item, index)}
                />
              ))}*/}
              {addList?.fromForm?.length === 0 && (
                <div className="flex h-full items-center justify-center flex-col">
                  <FilterAltIcon className="!w-20 !h-20 text-gray-400" />
                  <p className="text-sm w-[80%] text-center">
                    {translate('Please add new type')}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-end gap-4 w-full">
            <Button
              className={`outline-btn min-w-[75px] rounded-md`}
              onClick={() => navigate(-1)}
            >
              {translate('Cancel')}
            </Button>
            <LoadingButton
              className="primary-btn min-w-[75px] rounded-md"
              onClick={handleSave}
              isLoading={isCreating}
            >
              {translate('Save')}
            </LoadingButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOvertimeConfPage;
