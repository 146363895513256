import React, { useState } from 'react';
import { SearchInput } from '../../../../../../../components';
import SubItems from './SubItems';

import { useLocation, useNavigate } from 'react-router';

import { sideMenuList } from '../../utils';
import { getSubUrl } from '../../utils';
import _ from 'lodash';

export const defaultParentRoute = '/company-config/';

function SideMenu() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [search, setSearch] = useState('')

  const handleClick = (seletedItem) => {
    navigate(defaultParentRoute + seletedItem?.path);
  };

  const filter = () => {

    if (search.length > 0) {
      const filteredList = _.map(sideMenuList, (menu) => {
        const filteredSubItems = _.filter(menu?.subItems, (subItems) => {
          return subItems.title.toLowerCase().includes(search.toLowerCase())
        })
        return { ...menu, subItems: filteredSubItems }
      });

      return _.filter(filteredList, (menus) => menus.subItems.length > 0);
    } else {
      return sideMenuList;
    }
  };

  return (
    <>
      <SearchInput
        containerClassName={'w-full max-w-sm'}
        className={
          'w-full max-w-sm py-2 h-10 border-gray-800 border-opacity-25'
        }
        onChange={(e) => setSearch(e.target.value)}
      />
      <div className="flex gap-0 flex-col mt-[11px] overflow-y-auto hidden-scroll max-h-[77dvh]">
        {_.map(filter(), function (menu, menuIndex) {
          return (
            <div key={menu.title}>
              <p className="text-blue-800 text-md uppercase py-2 px-4 font-semibold tracking-[.05em]">
                {menu.title}
              </p>
              {_.map(menu?.subItems, (subMenu, subMenuIndex) => {
                return (
                  <SubItems
                    key={subMenu?.title}
                    item={subMenu}
                    isSelected={getSubUrl(pathname) === subMenu?.path}
                    handleClick={handleClick.bind(undefined, {
                      menuIndex: menuIndex,
                      subMenuIndex: subMenuIndex,
                      path: subMenu?.path,
                    })}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
}

export default SideMenu;
