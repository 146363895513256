import axios from 'axios';
import { BaseURL, PMS } from '../common/constants';
import { refreshTokenInterceptor } from './refresh-token';

const companyId = localStorage.getItem('__current_company_id');

const api = axios.create({
  baseURL: `${BaseURL}/api`,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    'X-Company-Id': companyId,
    'ngrok-skip-browser-warning': '',
    'X-Platform': 'cb-core',
  },
  withCredentials: true,
});

export const unAuthorizedApi = axios.create({
  baseURL: `${BaseURL}/api`,
  timeout: 60000,
  'X-Platform': 'cb-core',
  headers: {
    'ngrok-skip-browser-warning': '',
  },
});

export const apiForm = axios.create({
  baseURL: `${BaseURL}/api`,
  timeout: 60000,
  headers: {
    'Content-Type': 'multipart/form-data',
    'X-Company-Id': companyId,
    'X-Platform': 'cb-core',
    'ngrok-skip-browser-warning': '',
  },
  withCredentials: true,
});

export const apiLogin = axios.create({
  baseURL: `${BaseURL}`,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    'X-Platform': 'cb-core',
    'ngrok-skip-browser-warning': '',
  },
});

export const pmsApi = axios.create({
  baseURL: `${PMS}`,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'X-Company-Id': companyId,
    'X-Platform': 'cb-core',
    'ngrok-skip-browser-warning': '',
  },
  withCredentials: true,
});

export const fileApi = axios.create({
  baseURL: `${BaseURL}/api`,
  timeout: 20000,
  responseType: 'blob',
  'X-Platform': 'cb-core',
  headers: {
    'ngrok-skip-browser-warning': '',
  },
});

api.interceptors.request.use(
  (config) => {
    const currentUrl = window.location.href;

    const searchParams = new URLSearchParams(currentUrl.search);

    const companyId =
      searchParams.get('xCompanyId') ||
      localStorage.getItem('__current_company_id');

    config.headers['X-Company-Id'] = companyId;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    return await refreshTokenInterceptor(error, api);
  },
);

pmsApi.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    return await refreshTokenInterceptor(error, pmsApi);
  },
);

fileApi.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    return await refreshTokenInterceptor(error, fileApi);
  },
);

export function currentUser() {
  return new Promise((resolve) => {
    let activebu = localStorage.getItem('__active__BU__info');
    if (activebu) activebu = JSON.parse(activebu);
    const companyid = activebu.settings?.company_setting?.company_id
      ? activebu.settings?.company_setting?.company_id
      : activebu.settings?.company_id;
    return resolve({
      companyId: companyid
        ? companyid
        : localStorage.getItem('__current_company_id'),
      currencyId: localStorage.getItem('company_currency'),
      currencyName: localStorage.getItem('company_currency'),
      is_admin: localStorage.getItem('__active_user_is_admin__'),
      userId: localStorage.getItem('__active__user__id'),
    });
  });
}

export const getCurrentUser = () => {
  let activebu = localStorage.getItem('__active__BU__info');
  if (activebu) activebu = JSON.parse(activebu);
  const companyid = activebu.settings?.company_setting?.company_id
    ? activebu.settings?.company_setting?.company_id
    : activebu.settings?.company_id;

  return {
    companyId: companyid
      ? companyid
      : localStorage.getItem('__current_company_id'),
    currencyId: localStorage.getItem('company_currency'),
    currencyName: localStorage.getItem('company_currency'),
    is_admin: localStorage.getItem('__active_user_is_admin__'),
    userId: localStorage.getItem('__active__user__id'),
  };
};

export function getCompanyCurrency(company_id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/getCompanyCurrency?company_id=${company_id}`)
      .then((response) => {
        return resolve(response.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

export function fileUpload(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BaseURL}/api/v1/file/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((result) => {
        return resolve(result);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

export function fileUpload_company(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BaseURL}/api/v1/file/upload/company`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

export function fileUploadV2(formData) {
  return new Promise((resolve, reject) => {
    api
      .post(`/v2/file/upload`, formData)
      .then((result) => {
        return resolve(result);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

export function fileUpload_companyV2(formData) {
  return new Promise((resolve, reject) => {
    api
      .post(`/v2/file/upload/company`, formData)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

export function convertNumberToWords(amount) {
  const words = [];
  words[0] = '';
  words[1] = 'One';
  words[2] = 'Two';
  words[3] = 'Three';
  words[4] = 'Four';
  words[5] = 'Five';
  words[6] = 'Six';
  words[7] = 'Seven';
  words[8] = 'Eight';
  words[9] = 'Nine';
  words[10] = 'Ten';
  words[11] = 'Eleven';
  words[12] = 'Twelve';
  words[13] = 'Thirteen';
  words[14] = 'Fourteen';
  words[15] = 'Fifteen';
  words[16] = 'Sixteen';
  words[17] = 'Seventeen';
  words[18] = 'Eighteen';
  words[19] = 'Nineteen';
  words[20] = 'Twenty';
  words[30] = 'Thirty';
  words[40] = 'Forty';
  words[50] = 'Fifty';
  words[60] = 'Sixty';
  words[70] = 'Seventy';
  words[80] = 'Eighty';
  words[90] = 'Ninety';
  var words_string = '';
  if (typeof amount != 'undefined') {
    amount = amount.toString();
    var atemp = amount.split('.');
    var number = atemp[0].split(',').join('');
    var n_length = number.length;
    if (n_length <= 9) {
      var n_array = [0, 0, 0, 0, 0, 0, 0, 0, 0];
      var received_n_array = [];
      for (let i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (let i = 0, j = 1; i < 9; i++, j++) {
        if (i === 0 || i === 2 || i === 4 || i === 7) {
          if (n_array[i] === 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      var value = '';
      for (var i = 0; i < 9; i++) {
        if (i === 0 || i === 2 || i === 4 || i === 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value !== 0) {
          words_string += words[value] + ' ';
        }
        if (
          (i === 1 && value !== 0) ||
          (i === 0 && value !== 0 && n_array[i + 1] === 0)
        ) {
          words_string += 'Crores ';
        }
        if (
          (i === 3 && value !== 0) ||
          (i === 2 && value !== 0 && n_array[i + 1] === 0)
        ) {
          words_string += 'Lakhs ';
        }
        if (
          (i === 5 && value !== 0) ||
          (i === 4 && value !== 0 && n_array[i + 1] === 0)
        ) {
          words_string += 'Thousand ';
        }
        if (
          i === 6 &&
          value !== 0 &&
          n_array[i + 1] !== 0 &&
          n_array[i + 2] !== 0
        ) {
          words_string += 'Hundred and ';
        } else if (i === 6 && value !== 0) {
          words_string += 'Hundred ';
        }
      }
      words_string = words_string.split('  ').join(' ');
    }
  }
  return words_string;
}

export function downloadTemplate(filename) {
  return new Promise((resolve, reject) => {
    api
      .get(`${BaseURL}/api/v1/file/download?name=${filename}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((result) => {
        const fileToDownload = result.data.data;
        const url = `${fileToDownload}`;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileToDownload);
        link.setAttribute('target', '_blank');
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

export default api;
