import { FormControlLabel } from '@material-ui/core';
import { Checkbox } from '@mui/material';
import React from 'react';

export function CheckBox({
  itemClassName,
  checkBoxClassName,
  onChange,
  mutable = true,
  checked,
  label,
  size = 'small',
  labelClassName,
  id,
}) {
  return (
    <div className="w-full">
      <FormControlLabel
        className={itemClassName}
        control={
          <Checkbox
            size={size}
            className={`mx-2 ${checkBoxClassName}`}
            checked={checked}
            onChange={() => (mutable ? onChange(id, !checked) : () => {})}
          />
        }
        label={
          <div className={`text-sm w-full ${labelClassName}`}>
            <p>{label}</p>
          </div>
        }
      />
    </div>
  );
}
