import { useState } from 'react';
import moment from 'moment';
import { baseDateTimeFormatting } from '../helpers/utils';

export function useSelectDates(onlyDate = true) {
  const [fromDate, setFromDate] = useState(
    onlyDate
      ? moment(new Date())
          .subtract('days', 30)
          .startOf('D')
          .format(baseDateTimeFormatting)
      : moment(new Date()).subtract('days', 30).format(baseDateTimeFormatting),
  );

  const [toDate, setToDate] = useState(
    onlyDate
      ? moment(new Date())
          .add('day', 30)
          .startOf('D')
          .format(baseDateTimeFormatting)
      : moment(new Date()).add('day', 30).format(baseDateTimeFormatting),
  );

  const handleFromDate = (date) => {
    setFromDate(date);
  };

  const handleToDate = (date) => {
    setToDate(date);
  };

  return {
    fromDate,
    toDate,
    handleFromDate,
    handleToDate,
  };
}
