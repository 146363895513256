import React from 'react';

export default function Lock() {
  return (
    <path
      d="M12-18.75a5.138,5.138,0,0,0-3.047.984,5.592,5.592,0,0,0-1.922,2.484l1.383.563a4.048,4.048,0,0,1,1.418-1.828A3.614,3.614,0,0,1,12-17.25a3.668,3.668,0,0,1,1.875.5,3.723,3.723,0,0,1,1.371,1.371,3.668,3.668,0,0,1,.5,1.875v1.031A7.373,7.373,0,0,0,12-13.5a7.251,7.251,0,0,0-3.773,1.031,7.2,7.2,0,0,0-2.7,2.7A7.251,7.251,0,0,0,4.5-6,7.251,7.251,0,0,0,5.531-2.227a7.2,7.2,0,0,0,2.7,2.7A7.251,7.251,0,0,0,12,1.5,7.251,7.251,0,0,0,15.773.469a7.2,7.2,0,0,0,2.7-2.7A7.251,7.251,0,0,0,19.5-6a7.349,7.349,0,0,0-.609-2.953,7.418,7.418,0,0,0-1.641-2.391V-13.5a5.039,5.039,0,0,0-.715-2.613,5.393,5.393,0,0,0-1.922-1.922A5.039,5.039,0,0,0,12-18.75ZM12-12a5.821,5.821,0,0,1,3.012.809,6.072,6.072,0,0,1,2.18,2.18A5.821,5.821,0,0,1,18-6a5.821,5.821,0,0,1-.809,3.012,6.072,6.072,0,0,1-2.18,2.18A5.821,5.821,0,0,1,12,0,5.821,5.821,0,0,1,8.988-.809a6.072,6.072,0,0,1-2.18-2.18A5.821,5.821,0,0,1,6-6a5.821,5.821,0,0,1,.809-3.012,6.072,6.072,0,0,1,2.18-2.18A5.821,5.821,0,0,1,12-12Zm0,4.5a1.449,1.449,0,0,0-1.066.434A1.449,1.449,0,0,0,10.5-6a1.434,1.434,0,0,0,.2.738,1.55,1.55,0,0,0,.551.551V-2.25h1.5V-4.711a1.55,1.55,0,0,0,.551-.551A1.434,1.434,0,0,0,13.5-6a1.449,1.449,0,0,0-.434-1.066A1.449,1.449,0,0,0,12-7.5Z"
      transform="translate(-4.5 18.75)"
    />
  );
}
