import React from 'react';

import { SearchInput, Button, ToggleWithLabel2 } from '../../../../../../../components';

import { useTranslate } from '../../../../../../../hooks';

function SearchSeaction({ handleSearchTitle, openAddDesignation, status, handleAutomationStatus }) {
  const { translate } = useTranslate();

  return (
    <div className="flex gap-2">
      <SearchInput
        containerClassName={'w-full max-w-sm'}
        className={
          'w-full max-w-sm py-2 h-10 border-gray-800 border-opacity-25'
        }
        onChange={(e) => handleSearchTitle?.(e.target.value)}
      />
      <ToggleWithLabel2
        handleChange={(e) => handleAutomationStatus(e.target.checked)}
        className='h-[40px] w-60'
        isActive={status}
        label={translate('OT Automation')}
      />
      <Button className="export-btn shrink-0 ml-auto h-10">
        {translate('Export All')}
        <i className="las la-file-export"></i>
      </Button>

        <Button
          className={`primary-btn gap-x-1 shrink-0 h-10`}
          disabled={status ? false : true}
          onClick={openAddDesignation}
        >
          <i className="las la-plus" style={{ fontSize: '1.2rem' }}></i>
          {translate('Add Overtime Configuration')}
        </Button>
    </div>
  );
}

export default SearchSeaction;
