export const BaseURL = `${process.env.REACT_APP_URL}`;
export const EMPortal = `${process.env.REACT_APP_EMPLOYEE_PORTAL_URL}`;
export const ReportAPI = `${process.env.REACT_APP_REPORT_PORTAL_URL}`;
export const StaticBaseURL = `${process.env.REACT_APP_APP_STATIC_URL}`;
export const DefaultAvatar = StaticBaseURL + 'default_avatar.png';
export const DefaultHumanAvatar = StaticBaseURL + 'default_avatar-img.png';
export const PMS = `${process.env.REACT_APP_PMS_URL}`;
export const PRE_BOARDING_STATUS = 1;
export const ON_BOARDING_STATUS = 2;

export const SHORT_ANSWER = 'SHORT_ANSWER';
export const RADIO_CHOICES = 'RADIO_CHOICES';
export const SCORE_TYPE = 'NUMERIC';

export const ACTION_EVENT = {
  SHOW: 'SHOW',
  DOWNLOAD: 'DOWNLOAD',
};
export const EMP_BOARDING_STATUS = {
  PENDING: 1,
  COMPLETED: 2,
  SIGNED: 3,
  TASK_ASSIGNED: 4,
  DRAFT: 5,
  INVITED: 6,
  REVOKED: 7,
};

export const EMP_ONBOARDING_DOCUMENT_TYPE = {
  NDA: 1,
  MEDICAL: 2,
};

export const EVALUATION_STATUS = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
};

export const RESPONSE = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
};

export const EXPORT_TYPE = {
  ACCRUAL_REPORT: 'accrual-report ',
  ACCRUAL_LEAVE_DETAILS: 'leave-accrual-details',
};

export const EXPORT_WAITING_MESSAGE = 'Export under processing...';

export const FILE_EXTENSION_CSV = 'csv';
export const FILE_EXTENSION_XLS = 'xls';

export const EXPORT_FILE = 'exports';

export const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
export const LOGS_FILE = '/logs/';

export const timeShiftLegends = [
  {
    label: 'Normal time shift',
    color: '#8c2b81',
    backgroundColor: '#f5ecf4',
  },
  {
    label: 'Week Off',
    color: '#f15858',
    backgroundColor: '#fef2f2',
  },
  {
    label: 'Flexible time shift',
    color: '#41ce75',
    backgroundColor: '#dcfce7',
  },
  {
    label: 'No time shift',
    color: '#9ca3af',
    backgroundColor: '#f3f4f6',
  },
  {
    label: 'Overnight',
    color: '#FAD02C',
    backgroundColor: '#666666',
  },
  {
    label: 'On Leave',
    color: '#2196F3',
    backgroundColor: '#2196F31A',
  },
  {
    label: 'Holiday',
    color: '#9ca3af',
    backgroundColor: '#f3f4f6',
  },
];
