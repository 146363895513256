import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import _ from 'lodash';
import UserContext from '../context/UserContext';

const AuthRoute = ({ children }) => {
  const { userInfo } = useContext(UserContext);

  if (_.isEmpty(userInfo)) {
    return <Navigate to="/login" />;
  }
  return children;
};

export default AuthRoute;
