import React, { useEffect, createContext, useContext, useReducer } from 'react';

const initState = {
  rowsPerPage: 10,
  companyId: null,
  userId: null,
  isOpenNavbar: false,
  changeRowsPerPage: () => { },
  setCompanyId: () => { },
  toggleOpenNavbar: () => { },
  setUserId: () => { },
};

const AppContext = createContext(initState);

const SET_ROWS_PER_PAGE = 'SET_ROWS_PER_PAGE';
const SET_COMPANY_ID = 'SET_COMPANY_ID';
const SET_ACTIVE_USERID = 'SET_ACTIVE_USERID';
const TOGGLE_OPEN_NAVBAR = 'TOGGLE_OPEN_NAVBAR';

function appReducer(state, action) {
  switch (action.type) {
    case SET_ROWS_PER_PAGE:
      return { ...state, rowsPerPage: action.payload };
    case SET_COMPANY_ID: {
      return { ...state, companyId: action.payload };
    }
    case SET_ACTIVE_USERID: {
      return { ...state, userId: action.payload };
    }
    case TOGGLE_OPEN_NAVBAR: {
      return {
        ...state,
        isOpenNavbar: !{ ...state }.isOpenNavbar,
      };
    }
    default:
      return state;
  }
}

export function AppProvider({ children }) {
  const changeRowsPerPage = (newRowsPerPage) => {
    if (newRowsPerPage !== undefined) dispatch({ type: SET_ROWS_PER_PAGE, payload: newRowsPerPage });
  };

  const setCompanyId = (id) => {
    dispatch({ type: SET_COMPANY_ID, payload: id });
  };

  const toggleOpenNavbar = () => {
    dispatch({ type: TOGGLE_OPEN_NAVBAR });
  };

  const setUserId = (id) => {
    dispatch({ type: SET_ACTIVE_USERID, payload: id })

  }

  const [state, dispatch] = useReducer(appReducer, initState);

  const value = {
    rowsPerPage: state.rowsPerPage,
    companyId: state.companyId,
    userId: state.userId,
    isOpenNavbar: state.isOpenNavbar,
    changeRowsPerPage,
    setCompanyId,
    toggleOpenNavbar,
    setUserId
  };

  useEffect(() => {
    const companyId = localStorage.getItem('__current_company_id');
    const userId = localStorage.getItem('__active__user__id');
    setCompanyId(companyId);
    setUserId(userId)
  }, []);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export function useApp() {
  const app = useContext(AppContext);

  return { ...app };
}
