// Define the enum
export const pagination_list = {
  grid: [6, 12, 30, 100],
  table: [10, 15, 50, 100],
};
export const default_rows = {
  def_table: 10,
  def_protable: 15,
  infinite_scroll: 25,
  shift_roaster_scroll: 10,
  empDocumentsList: 30,
};

export const languages = {
  ar: 'ar',
  en: 'en',
};
