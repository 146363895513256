import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import { Button } from './Button';

export function LoadingButton({ isLoading, disabled, ...props }) {
  return (
    <Button {...props} disabled={isLoading || disabled}>
      {props.children}
      {isLoading ? (
        <CircularProgress className="!w-4 !h-4 mx-2 !text-primary" />
      ) : null}
    </Button>
  );
}
