import React from 'react';
import { useTranslation } from 'react-i18next';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enLocale from 'date-fns/locale/en-US';
import arLocale from 'date-fns/locale/ar-SA';
import { createGenerateClassName } from '@material-ui/styles';
import { StylesProvider } from '@material-ui/core/styles';
import { useTranslate } from '../../hooks';
import { languages } from '../../common/enum';
// Create rtl cache

const defaultenFontFamily = ' !important';
const defaultarFontFamily =
  "'Noto Kufi Arabic', 'El Messiri', sans-serif !important";
const cacheRtl = createCache({
  key: 'muirtl',
  fontFamily: defaultarFontFamily,
  stylisPlugins: [prefixer, rtlPlugin],
});
const cacheLtr = createCache({
  key: 'muiltr',
  fontFamily: defaultenFontFamily,
  stylisPlugins: [prefixer],
});
const themeltr = createTheme({
  direction: 'ltr',
  fontFamily: defaultenFontFamily,

  typography: {
    fontFamily: defaultenFontFamily,
    allVariants: { defaultenFontFamily },
    root: {
      fontFamily: defaultenFontFamily,
    },
    button: {
      fontFamily: defaultenFontFamily,
    },
    body2: {
      fontFamily: defaultenFontFamily,
    },
    body1: {
      fontFamily: defaultenFontFamily,
    },
    caption: {
      fontFamily: defaultenFontFamily,
    },
    h1: {
      fontFamily: defaultenFontFamily,
    },
    h2: {
      fontFamily: defaultenFontFamily,
    },
    h3: {
      fontFamily: defaultenFontFamily,
    },
    h4: {
      fontFamily: defaultenFontFamily,
    },
    h5: {
      fontFamily: defaultenFontFamily,
    },
    h6: {
      fontFamily: defaultenFontFamily,
    },
    subtitle1: {
      fontFamily: defaultenFontFamily,
    },
    subtitle2: {
      fontFamily: defaultenFontFamily,
    },
  },
  body1: {
    fontFamily: defaultenFontFamily,
  },
  root: {
    fontFamily: defaultenFontFamily,
  },
  body2: {
    fontFamily: defaultenFontFamily,
  },
  caption: {
    fontFamily: defaultenFontFamily,
  },
  button: {
    fontFamily: defaultenFontFamily,
  },
  h1: {
    fontFamily: defaultenFontFamily,
  },
  h2: {
    fontFamily: defaultenFontFamily,
  },
  h3: {
    fontFamily: defaultenFontFamily,
  },
  h4: {
    fontFamily: defaultenFontFamily,
  },
  h5: {
    fontFamily: defaultenFontFamily,
  },
  h6: {
    fontFamily: defaultenFontFamily,
  },
  subtitle1: {
    fontFamily: defaultenFontFamily,
  },
  subtitle2: {
    fontFamily: defaultenFontFamily,
  },
  overline: {
    fontFamily: defaultenFontFamily,
  },
  inherit: {
    fontFamily: defaultenFontFamily,
  },
  alignLeft: {
    fontFamily: defaultenFontFamily,
  },
  alignCenter: {
    fontFamily: defaultenFontFamily,
  },
  alignRight: {
    fontFamily: defaultenFontFamily,
  },
  alignJustify: {
    fontFamily: defaultenFontFamily,
  },
  noWrap: {
    fontFamily: defaultenFontFamily,
  },
  gutterBottom: {
    fontFamily: defaultenFontFamily,
  },
  paragraph: {
    fontFamily: defaultenFontFamily,
  },
  components: {
    MuiAutocomplete: {
      defaultProps: {
        noOptionsText: 'No Options',
      },
    },
  },
});
const themertl = createTheme({
  direction: 'rtl',
  overrides: {
    MuiInputLabel: {
      root: {
        direction: 'rtl', // Set the direction of the label to RTL
      },
    },
  },
  fontFamily: defaultarFontFamily,
  typography: {
    fontFamily: defaultarFontFamily,
    allVariants: { defaultarFontFamily },
    root: {
      fontFamily: defaultarFontFamily,
    },
    button: {
      fontFamily: defaultarFontFamily,
    },
    body2: {
      fontFamily: defaultarFontFamily,
    },
    body1: {
      fontFamily: defaultarFontFamily,
    },
    caption: {
      fontFamily: defaultarFontFamily,
    },
    h1: {
      fontFamily: defaultarFontFamily,
    },
    h2: {
      fontFamily: defaultarFontFamily,
    },
    h3: {
      fontFamily: defaultarFontFamily,
    },
    h4: {
      fontFamily: defaultarFontFamily,
    },
    h5: {
      fontFamily: defaultarFontFamily,
    },
    h6: {
      fontFamily: defaultarFontFamily,
    },
    subtitle1: {
      fontFamily: defaultarFontFamily,
    },
    subtitle2: {
      fontFamily: defaultarFontFamily,
    },
  },
  body1: {
    fontFamily: defaultarFontFamily,
  },
  root: {
    fontFamily: defaultarFontFamily,
  },
  body2: {
    fontFamily: defaultarFontFamily,
  },
  caption: {
    fontFamily: defaultarFontFamily,
  },
  button: {
    fontFamily: defaultarFontFamily,
  },
  h1: {
    fontFamily: defaultarFontFamily,
  },
  h2: {
    fontFamily: defaultarFontFamily,
  },
  h3: {
    fontFamily: defaultarFontFamily,
  },
  h4: {
    fontFamily: defaultarFontFamily,
  },
  h5: {
    fontFamily: defaultarFontFamily,
  },
  h6: {
    fontFamily: defaultarFontFamily,
  },
  subtitle1: {
    fontFamily: defaultarFontFamily,
  },
  subtitle2: {
    fontFamily: defaultarFontFamily,
  },
  overline: {
    fontFamily: defaultarFontFamily,
  },
  inherit: {
    fontFamily: defaultarFontFamily,
  },
  alignLeft: {
    fontFamily: defaultarFontFamily,
  },
  alignCenter: {
    fontFamily: defaultarFontFamily,
  },
  alignRight: {
    fontFamily: defaultarFontFamily,
  },
  alignJustify: {
    fontFamily: defaultarFontFamily,
  },
  noWrap: {
    fontFamily: defaultarFontFamily,
  },
  gutterBottom: {
    fontFamily: defaultarFontFamily,
  },
  paragraph: {
    fontFamily: defaultarFontFamily,
  },
  input: {
    fontFamily: defaultarFontFamily,
  },
  select: {
    fontFamily: defaultarFontFamily,
  },
  body: {
    fontFamily: defaultarFontFamily,
  },
  components: {
    MuiAutocomplete: {
      defaultProps: {
        noOptionsText: 'لا توجد خيارات',
      },
    },
    // MuiDataGrid: {
    //   defaultProps: {
    //     localeText: arabicTranslationDataGrid,
    //   },
    // },
  },
});

const generateClassName = createGenerateClassName({
  productionPrefix: 'cipherbizz',
  disableGlobal: true,
});

const Compdirection = ({ children }) => {
  const { i18n } = useTranslation();
  const { language } = useTranslate();

  React.useEffect(() => {
    if (language === languages.ar) {
      const style = document.createElement('style');
      style.innerHTML = `
        .MuiTablePagination-caption, .MuiTablePagination-select-icon, .MuiTablePagination-actions button {
          transform: scaleX(-1);
        }
      `;
      document.head.appendChild(style);
    }

    const body = document.querySelector('body');

    if (language === languages.ar) {
      body.classList.add('arabic-font');
    } else {
      body.classList.remove('arabic-font');
    }
  }, [language]);

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={i18n.language === 'ar' ? themertl : themeltr}>
        <CacheProvider value={i18n.language === 'ar' ? cacheRtl : cacheLtr}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={i18n.language === 'ar' ? arLocale : enLocale}
          >
            {children}
          </LocalizationProvider>
        </CacheProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};
export default Compdirection;
