import React, { useState } from 'react';

import { useTranslate, useValidation } from '../../../../../../../hooks';

import { AutoComplete, Button, CustomRadioGroup, RadioButton, TextInput } from '../../../../../../../components';
import { LoadingButton } from '../../../../../../../components/buttons';
import CloseIcon from '@mui/icons-material/Close';

import { editOvertime } from '../validations';
import { colors } from '../../../../../../../helpers/colors';
import { Box, ButtonBase, Typography } from '@mui/material';
import _ from 'lodash';

function EditGender({ toggleModal, customHandler, seletedData, isEditing, shiftTypes, shiftTypeLoading, allowanceTypes }) {
  const { translate } = useTranslate();
  const [form, setForm] = useState({
    id: seletedData?.id,
    shiftType: {
      id: seletedData?.shiftTypeId,
      label: seletedData?.shiftTypeName
    },
    amount: seletedData?.amount,
    amountType: seletedData?.amountType,
    salaryComponent: (seletedData?.allowanceId || []).map(Number),
    maxOTHours: seletedData?.maxOTHour,
    minOTHours: seletedData?.minOTHour,
    amountType: seletedData?.amountType,
    name: seletedData?.shiftTypeName,
    dailySalHrs: seletedData?.dailySalHrs
  });

  const { errors, validate } = useValidation(editOvertime, {
    amount: form?.amount,
    minOTHours: form?.minOTHours,
    maxOTHours: form?.maxOTHours,
    dailySalHrs: form?.dailySalHrs
  });

  const handleSaveEdit = async (e) => {
    e.preventDefault();
    if (await validate()) {
      customHandler(form);
    }
  };

  const handleChange = ({ key, value }) => {
    setForm((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  return (
    <div className="w-[450px] h-auto p-5 bg-white rounded-md app-modal base-modal">
      <span className="flex items-center gap-x-2 mb-4 h-[26px]">
        <span className="flex gap-x-1">
          <p className="welcome-text font-bold">{translate('Edit')}</p>
          <p className="welcome-text2 font-bold">{translate('Overtime Configuration')}</p>
        </span>
        <div className="ml-auto cursor-pointer" onClick={toggleModal}>
          <CloseIcon className="text-gray-400" />
        </div>
      </span>
      <form
        onSubmit={handleSaveEdit}
        className="flex flex-col gap-4 max-h-[450px] w-[100%] overflow-auto pt-2"
      >
        <AutoComplete
          isLoading={shiftTypeLoading}
          label={translate('Shift Type')}
          data={shiftTypes?.map((item, index) => ({
            id: item?.id,
            label: item?.name,
          }))}
          onChange={(option) => {
            handleChange({ key: 'shiftType', value: option });
          }}
          disabled
          errorText={errors?.shiftType}
          showError={errors?.shiftType}
          className={`w-full h-[56px] border-[${colors?.lightgrey}]`}
          inputProps={{
            fullWidth: true,
          }}
          value={form?.shiftType}
        />
        <Box className='flex flrx-row spacing-1 h-75'>
          <TextInput
            fullWidth
            type='number'
            error={errors?.minOTHours}
            helperText={errors?.minOTHours}
            defaultValue={form?.minOTHours}
            label={translate('Min Hour')}
            step='0.1'
            onChange={(e) => {
              let value = parseFloat(e?.target?.value);
              if (!isNaN(value)) {
                value = parseFloat(value.toFixed(1));
                if (value % 1 === 0) {
                  value = parseFloat(value.toFixed(0));
                }
              }
              handleChange({
                key: `minOTHours`,
                value: value,
              });
            }}
          />
          <Typography
            className='grid content-center px-3 text-base'
          >
            {"and"}
          </Typography>
          <TextInput
            fullWidth
            type='number'
            label={translate('Max Hour')}
            error={errors?.maxOTHours}
            helperText={errors?.maxOTHours}
            defaultValue={form?.maxOTHours}
            step='0.1'
            onChange={(e) => {
              let value = parseFloat(e?.target?.value);
              if (!isNaN(value)) {
                value = parseFloat(value.toFixed(1));
                if (value % 1 === 0) {
                  value = parseFloat(value.toFixed(0));
                }
              }
              handleChange({
                key: `maxOTHours`,
                value: value,
              });
            }}
          />
        </Box>
        <CustomRadioGroup
          className='flex flex-row'
          defaultValue={form?.amountType}
          onChange={(e) => {
            handleChange({
              key: 'amountType',
              value: e?.target?.value
            })
          }}
        >
          <RadioButton
            value={1}
            key={'fixed'}
            label={translate('Fixed Amount')}
          />
          <RadioButton
            value={0}
            key={'percentage'}
            label={translate('%Percentage')}
          />
        </CustomRadioGroup>
        {form.amountType == 1 &&
          <TextInput
            fullWidth
            type='number'
            label={translate('Enter Amount')}
            error={errors?.amount}
            helperText={errors?.amount}
            defaultValue={form?.amount}
            onChange={(e) => {
              handleChange({
                key: `amount`,
                value: e?.target?.value,
              });
            }}
          />
        }
        {form.amountType == 0 &&
          <TextInput
            fullWidth
            type='number'
            label={translate('Enter Percentage')}
            error={errors?.amount}
            helperText={errors?.amount}
            defaultValue={form?.amount}
            onChange={(e) => {
              handleChange({
                key: `amount`,
                value: e?.target?.value
              });
            }}
          />
        }
        {form.amountType == 0 &&
          <Box>
            <Typography className='text-sm tracking-[0.6px]'>
              {translate('Salary Components')}
            </Typography>
            <Box
              className='bg-slate-100 rounded-lg py-2 px-1'
            >
              {_.map(allowanceTypes, (allowance) => {
                const checked = _.some(form?.salaryComponent, (e) => {
                  return e == allowance?.id
                })
                return (
                  <ButtonBase
                    sx={{
                      padding: '0.3em 0.6em',
                      margin: '0.2em 0em 0.2em 0.7em',
                      border: !checked ? '2px solid #7C0E6F' : '2px solid white',
                      borderRadius: '1em',
                      color: !checked ? '#7C0E6F' : 'white',
                      fontSize: '11px',
                      backgroundColor: !checked ? 'white' : '#7C0E6F'
                    }}
                    onClick={() => {
                      const newSalaryComponent = checked
                        ? form.salaryComponent.filter(e => e !== allowance?.id)
                        : [...form.salaryComponent, allowance?.id];

                      handleChange({
                        key: 'salaryComponent',
                        value: newSalaryComponent,
                      });
                    }}

                  >
                    {allowance?.name}
                  </ButtonBase>
                )
              })}
            </Box>
          </Box>
        }
        <TextInput
          fullWidth
          type='number'
          label={translate('Daily Salary Hours')}
          error={errors?.dailySalHrs}
          helperText={errors?.dailySalHrs}
          defaultValue={form?.dailySalHrs}
          onChange={(e) => {
            handleChange({
              key: `dailySalHrs`,
              value: e?.target?.value,
            });
          }}
        />
        <div className="flex justify-end gap-4 mt-auto">
          <Button
            className={`outline-btn min-w-[75px] rounded-md`}
            onClick={toggleModal}
          >
            {translate('Cancel')}
          </Button>
          <LoadingButton
            className="primary-btn min-w-[75px] rounded-md"
            type="submit"
            isLoading={isEditing}
          >
            {translate('Save')}
          </LoadingButton>
        </div>
      </form>
    </div>
  );
}

export default EditGender;
