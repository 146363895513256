import { useList } from '../../../../../../../api/react-query';
import { useApp } from '../../../../../../../context';

function useCompOffList() {
  const { companyId } = useApp();
  const { data, isLoading, refetch, isFetching, isRefetching } = useList(
    'config/comp-off-list',
    { company_id: companyId },
  );

  return {
    data: data?.data?.data ? Object?.values(data?.data?.data) : [],
    isLoading: isLoading || isFetching || isRefetching,
    refetch,
  };
}

export default useCompOffList;
