import { useEffect, useState } from 'react';
import CommonApi from '../../api/v2/common';
import { Avatar, Skeleton } from '@mui/material';
import { StaticBaseURL } from '../../common/constants';

const FetchImage = (props) => {
  const [fileUrl, setFileUrl] = useState(null);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    if (
      props?.src &&
      !props?.src?.includes('/public_images/') &&
      !(
        props?.src?.startsWith(StaticBaseURL) || props?.src?.startsWith('blob:')
      ) &&
      ((props?.fetch && props?.fetch !== 'false') || !props?.fetch)
    ) {
      setLoader(true);
      CommonApi.getFilePath(props?.src)
        .then((res) => {
          setFileUrl(res);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });
    } else {
      if (
        props?.src &&
        !(
          props?.src?.startsWith(StaticBaseURL) ||
          props?.src?.startsWith('blob:')
        )
      )
        setFileUrl(props?.src);
      else {
        setURL(props?.src);
      }
      setLoader(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.src]);

  function setURL(src) {
    if (src === 'loader') setLoader(true);
    else {
      setFileUrl(src);
      setLoader(false);
    }
  }

  return (
    <>
      {props?.type && props?.type === 'avatar' ? (
        loader ? (
          <Skeleton
            variant="circular"
            style={props.style}
            width={props?.width}
            height={props?.height}
          >
            <Avatar />
          </Skeleton>
        ) : fileUrl ? (
          <Avatar src={fileUrl} style={props.style} />
        ) : (
          <Avatar src={props?.src} style={props.style} />
        )
      ) : loader ? (
        <Skeleton style={props.style}>
          <img
            src={props?.src}
            style={props.style}
            width={props?.width}
            height={props?.height}
            alt=""
          ></img>
        </Skeleton>
      ) : fileUrl ? (
        <img
          src={fileUrl}
          style={props.style}
          width={props?.width}
          height={props?.height}
          alt=""
        ></img>
      ) : (
        <img
          src={props?.src}
          style={props.style}
          width={props?.width}
          height={props?.height}
          alt=""
        ></img>
      )}
    </>
  );
};
export default FetchImage;
