import React from "react";
import { Button, Modal, Box, Typography, Grid } from "@material-ui/core";
import Warning from "../../../../Warning";
import ClockInButton from "../../../../ClockInButton";
import { colors } from "../../../../../helpers/colors";

const styles = {
    quickmodal: {
        padding: "3em",
        borderRadius: "1em",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        width: "600px",
        height: "650px",
    },
    cancel:{
        backgroundColor:'#FDEDEF',
        color:'#FF5263',
        width:'10.25em',
        height:'2.87em',
        borderRadius: "6px",
        textTransform: "uppercase",
    },
    
};
function ConfirmPublishedCycleModal(props) {
    return (
        <Modal open={props.isOpen} onClose={props.onClose}>
            <Box className="custom-modalDelete app-modal">
                <Box style={{ display: "flex", marginBottom: "2em" }}>
                    <Box style={{ flex: 1 }}></Box>
                    <i
                        className="las la-times"
                        style={{
                            fontSize: "1.5em",
                            color: colors.grey,
                            cursor: "pointer",
                        }}
                        onClick={() => props?.onClose()}
                    ></i>
                </Box>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        style={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                        }}
                    >
                        <Warning />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        style={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                        }}
                    >
                        <Typography
                            style={{
                                color: "#8181A5",

                                fontSize: "1.5em",
                            }}
                        >
                            {"Warning"}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        style={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                        }}
                    >
                        <Typography
                            style={{
                                color: "#8181A5",
                                fontSize: "0.875em",
                            }}
                        >
                            {"Are you sure you want to Publish this Cycle?"}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container style={{  marginTop: "3em"}} >
                    <Grid item xs={6} style={{display: "flex",justifyContent:'flex-start'}}>
                        <Button
                            style={styles.cancel}
                            onClick={() => {
                                props?.onClose();
                            }}
                        >
                            {"Cancel"}
                        </Button>
                    </Grid>
                    <Grid item xs={6} style={{display: "flex",justifyContent:'flex-end'}}>
                    <ClockInButton xs={6}
                        title={"CONFIRM"}
                        onClick={async () => {
                            await props?.handlePublished();
                        }}
                    />
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
}
export default ConfirmPublishedCycleModal;
