import React from 'react';

function DefaultTableBodyCell({ value, className, containerClassName }) {
  return (
    <div className={`flex justify-start items-center ${containerClassName}`}>
      <p
        className={`text-sm w-28 truncate ${
          value === 'Yes' ? 'text-success' : value === 'No' ? 'text-error' : ''
        } ${className}`}
        title={value}
      >
        {value}
      </p>
    </div>
  );
}

export default DefaultTableBodyCell;
