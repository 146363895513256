import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { styled } from '@mui/styles';

import { colors } from '../../helpers/colors';
import { useTranslate } from '../../hooks';
import { Loader } from '../icons/Loader';

const CustomAutocomplete = styled(Autocomplete)({
  '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    font: 'normal normal normal 14px/24px;',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: colors.lightgrey,
  },
  '& .MuiInputBase-input': {
    font: 'normal normal bold 0.85em',
    color: colors.primary,
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: colors.primary,
  },
  '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
    fontSize: '0.85em',
  },
  '& label.Mui-focused': {
    color: colors.primary,
  },
  '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
    fontSize: '0.85em',
  },
});

export function AutoComplete({
  data = [], // { id, label }[]
  onChange,
  value,
  label,
  showError,
  errorText,
  multiple = false,
  defaultValue,
  className = '',
  isLoading = false,
  disabled = false,
  clearable = true,
  inputProps = {},
  containerClassName,
  autoCompleteProps,
  size = 'medium',
}) {
  const { dir, translate } = useTranslate();

  return (
    <div className={`relative ${containerClassName}`}>
      <CustomAutocomplete
        size={size}
        multiple={multiple}
        disablePortal
        disabled={disabled}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={data}
        onChange={(_, newValue, reason) => {
          onChange?.(newValue, _, reason);
        }}
        disableClearable={!clearable}
        noOptionsText={translate('no-options')}
        defaultValue={defaultValue}
        filterSelectedOptions
        className={className}
        getOptionLabel={(option) => option.label}
        value={value}
        renderInput={(params) => (
          <TextField
            {...params}
            dir={dir}
            label={label}
            error={showError}
            className={className}
            helperText={errorText}
            {...inputProps}
          />
        )}
        {...autoCompleteProps}
      />
      <div className="absolute transform top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        {isLoading && <Loader width={15} />}
      </div>
    </div>
  );
}
