import React from 'react';
import './CompanyConfPage.css';

function CompanyConfPage(props) {
  return (
    <div className="br-company-conf shadow-md mt-5 bg-white p-[0.9rem] w-full">
      {props?.children}
    </div>
  );
}

export default CompanyConfPage;
