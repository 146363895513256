import api from './core';

export const authz = {
    getUserContext,
    switchBU,
    switchlanguage,
};

function getUserContext(user_id) {
    return new Promise((resolve, reject) => {
        api.get(`/v1/authz/getUserRoles?user_id=${user_id}`)
            .then((result) => {
                return resolve(result.data.data);
            })
            .catch((err) => {
                return reject(err);
            });
    });
}
function switchBU(user_id, company_id) {
    return new Promise((resolve, reject) => {
        api.post(`/v1/auth/switchBU`, {
            user_id: user_id,
            company_id: company_id,
        })
            .then((result) => {
                return resolve(result.data.data);
            })
            .catch((err) => {
                return reject(err);
            });
    });
}
function switchlanguage(userId, lang) {
    return new Promise((resolve, reject) => {
        api.put(`/v1/user/switchLanguage`, {
            userId: userId,
            lang: lang,
        })
            .then((result) => {
                return resolve(result.data.data);
            })
            .catch((err) => {
                return reject(err);
            });
    });
}
