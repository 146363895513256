import React from 'react';

export const Image = (props) => {
  return (
    <img
      {...props}
      alt={props.alt !== undefined ? props.alt : ''}
    />
  );
};
