import React from 'react';

import Chevron from './Chevron';
import Circle from './Circle';
import Announcement from './Announcement';
import Calendar from './Calendar';
import Search from './Search';
import Lock from './Lock';
import Bell from './Bell';
import Alert from './Alert';
import Grid from './Grid';
import DoubleCheck from './DoubleCheck';
import Clock from './Clock';
import Close from './Close';
import Hospital from './Hospital';
import SmallPerson from './SmallPerson';
import Sick from './Sick';
import Calendar2 from './Calendar2';
import List from './List';
import Eye from './Eye';
import LeftArrow from './LeftArrow';

const IconsMap = {
  chevron: <Chevron />,
  circle: <Circle />,
  announcement: <Announcement />,
  calendar: <Calendar />,
  calendar2: <Calendar2 />,
  search: <Search />,
  lock: <Lock />,
  bell: <Bell />,
  alert: <Alert />,
  grid: <Grid />,
  'double-check': <DoubleCheck />,
  clock: <Clock />,
  close: <Close />,
  hospital: <Hospital />,
  'small-person': <SmallPerson />,
  sick: <Sick />,
  list: <List />,
  eye: <Eye />,
  'left-arrow': <LeftArrow />,
};

export function Icons({
  name,
  viewBox,
  width = 16,
  height = 16,
  className,
  onClick,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="currentColor"
      className={className}
      viewBox={`0 0 ${viewBox?.[0] ?? width} ${viewBox?.[1] ?? height}`}
      onClick={onClick}
    >
      {name ? IconsMap[name] : null}
    </svg>
  );
}
