import { useDelete } from '../../../../../../../api/react-query';

export function useDeleteOvertimeConfigType({ id }) {
  const { mutateAsync, isLoading: isDeleting } = useDelete({
    url: 'config/overtime-auto',
    id,
  });

  return {
    deleteOvertimeConfig: mutateAsync,
    isDeleting,
  };
}
