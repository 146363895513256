import React from 'react';
import { Loader } from '../../../../components/icons/Loader';

function LoaderCotainer({ className }) {
  return (
    <div className={`h-[92%] flex items-center ${className}`}>
      <Loader height={150} width={150} />
    </div>
  );
}

export default LoaderCotainer;
