import React from 'react';

import { InfinityList } from '../list';
import { CheckBox } from '../inputs';

export function EmployeeInfinityList({
  fetchNextPage,
  hasNextPage,
  data,
  selectedData,
  labelClassName,
  itemClassName,
  checkBoxClassName,
  keys = [],
  height,
  mutable,
  loader,
  endMessage,
  dataLength,
  onChange,
  checkBoxSize,
}) {
  return (
    <InfinityList
      next={fetchNextPage}
      hasMore={hasNextPage}
      height={height}
      loader={loader}
      endMessage={endMessage}
      dataLength={dataLength}
    >
      {data?.map((item) => {
        const checked = selectedData?.some((_item) => +_item === +item.id);
        return (
          <CheckBox
            key={item.id}
            id={item.id}
            itemClassName={itemClassName}
            checkBoxClassName={checkBoxClassName}
            checked={checked}
            mutable={mutable}
            label={
              <>
                {keys?.map(({ label, className }) => (
                  <div className={`${className}`}>
                    {item[`${label}`]}
                  </div>
                ))}
              </>
            }
            labelClassName={labelClassName}
            onChange={onChange}
            size={checkBoxSize}
          />
        );
      })}
    </InfinityList>
  );
}
