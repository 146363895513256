import React, { Suspense } from 'react';
import Shell from './Shell';
import { Outlet } from 'react-router';
import { CircularProgress } from '@material-ui/core';

const MainLayout = () => {
  return (
    <Shell backgroundColor="#f5f5fa">
      <Suspense
        fallback={
          <div className="flex justify-center items-center w-[77vw] h-[80vh]">
            <CircularProgress />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </Shell>
  );
};

export default MainLayout;
