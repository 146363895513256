import React from 'react';
import { FormControlLabel } from '@mui/material';
import Radio from '@mui/material/Radio';

export function RadioButton({ value, defaultValue, label, ...props }) {
  return (
    <FormControlLabel
      value={value}
      control={<Radio {...props?.controllerProps} />}
      label={label}
      classes={{ label: '!text-xs' }}
      {...props}
    />
  );
}
