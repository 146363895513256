import api from './core';
const preferences = {
  setPreferece,
  getPreferenceByUserIdByCompanyId,
  getPreferenceByUserId,
};

function setPreferece(name, value, company, user) {
  return new Promise((resolve, reject) => {
    api
      .post(
        `/v1/auth/preference?user_id=${user}&name=${name}&value=${value}&company_id=${company}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        if (err.response) {
          return reject(err.response.data.data);
        } else {
          return reject('Error setting preference');
        }
      });
  });
}

function getPreferenceByUserId(user) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/auth/preference?user_id=${user}`)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        if (err.response) {
          return reject(err.response.data.data);
        } else {
          return reject('Error setting preference');
        }
      });
  });
}

function getPreferenceByUserIdByCompanyId(user, company_id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/auth/preference?user_id=${user}&company_id=${company_id}`)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        if (err.response) {
          return reject(err.response.data.data);
        } else {
          return reject('Error setting preference');
        }
      });
  });
}

export default preferences;
