import React from 'react';
import Lottie from 'react-lottie';
import * as animationData from '../assets/animation/warning.json';

export default class Warning extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isStopped: false, isPaused: false };
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    return (
      <Lottie
        options={defaultOptions}
        height={'8.375em'}
        width={'8.375em'}
        isStopped={this.state.isStopped}
        isPaused={this.state.isPaused}
      />
    );
  }
}
