import React from 'react';
import Lottie from 'react-lottie';

export function NoData({ data, height, width, isStopped, isPaused }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Lottie
      options={defaultOptions}
      height={height ?? 35}
      width={width ?? 25}
      isStopped={isStopped}
      isPaused={isPaused}
      style={{
        cursor: 'inherit',
      }}
    />
  );
}
