import React from 'react';

import { EmployeeCard, InfinityList, CustomRadioGroup, RadioButton } from '..';

export function EmployeeInfinityListRadioButton({
  fetchNextPage,
  hasNextPage,
  data,
  height,
  loader,
  endMessage,
  dataLength,
  onChange,
}) {
  
  return (
    <CustomRadioGroup onChange={onChange}>
      <InfinityList
        next={fetchNextPage}
        hasMore={hasNextPage}
        height={height}
        loader={loader}
        endMessage={endMessage}
        dataLength={dataLength}
      >
        {data?.map((item) => {
          return (
            <div className="flex gap-5 items-center py-[10px]">
              <RadioButton
                key={item.id}
                value={item.id}
                classes={{
                  root: '!mr-0',
                }}
                controllerProps={{ classes: { root: '!p-[0px]' } }}
              />
              <p className="text-text text-xs">{item?.staffId}</p>
              <EmployeeCard
                empName={item?.name}
                profileImage={item?.profileImage}
              />
            </div>
          );
        })}
      </InfinityList>
    </CustomRadioGroup>
  );
}
