import React from 'react';

import { Image } from '../img';
import { DefaultHumanAvatar } from '../../common/constants';

export function EmployeeCard({ className, empName, profileImage }) {
  return (
    <div className={`flex gap-2 items-center ${className}`}>
      <Image
        className="w-[30px] h-[30px] object-contain rounded-full bg-gray-600"
        src={profileImage || DefaultHumanAvatar}
      />
      <div className="flex flex-col gap-0 items-center">
        <p className="font-semibold text-xs text-black max-w-[10rem] truncate">
          {empName}
        </p>
      </div>
    </div>
  );
}
