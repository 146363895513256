import React from 'react';

export default function Hospital() {
  return (
    <path
      d="M0-12.99l-6.961,2.9-.352.158V-.562H7.313V-9.932l-.352-.158Zm0,1.23L6.188-9.176v7.488H-6.187V-9.176ZM-.562-8.437V-6.75H-2.25v1.125H-.562v1.688H.563V-5.625H2.25V-6.75H.563V-8.437Z"
      transform="translate(7.313 12.99)"
    />
  );
}
