import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { isAndroid, isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

export default function Example() {
  const [showPopUp, setShowPopUp] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const { t } = useTranslation();
  const appScheme = 'com.cipherbizz.cbemployeeapp://open';
  const android = "https://play.google.com/store/apps/details?id=com.cipherbizz.cbemployeeapp";
  const ios = "https://apps.apple.com/in/app/cipherbizz/id6446880270";

  const cancelButtonRef = useRef(null)

  useEffect(() => {
    if (isMobile) {
      setShowPopUp(true);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePopUpClose = () => {
    setShowPopUp(false);
  };

  const handleOpenApp = () => {
        window.location.href = appScheme;

        setTimeout(() => {
            if (window.location.href === appScheme || document.visibilityState !== "hidden") {
                redirectToDownLoad();
            }
        }, 1000);
  };

  const redirectToDownLoad = () =>{
    if (isAndroid) {
      window.location.href = android;
    } else if (isIOS) {
      window.location.href = ios;
    } else {
      setShowPopUp(false)
    }
  }


  return (
    <Transition.Root show={showPopUp} as={Fragment}>
        <Dialog as="div" className="" initialFocus={cancelButtonRef} onClose={setShowPopUp}>
            <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
            </Transition.Child>
        <div className="fixed inset-0 z-10 w- overflow-y-auto">
          <div className="flex min-h-96 items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white sm:px-4 pt-5 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                      <div className="mt-2">
                        <p className="text-xl text-center text-black">
                          {t("Click to open the App ?")}
                        </p>
                      </div>
                    </div>
                  </div>
                <div className="px-6 py-3 sm:flex sm:flex-row sm:px-6">
                <button
                    type="button"
                    className="mt-3 inline-flex w-28 justify-center rounded-md  px-3 mx-2 py-2 text-base font-semibold text-red-500 ring-1 ring-inset ring-red-500 sm:mt-0 sm:w-auto sm:w-24"
                    onClick={handlePopUpClose}
                    ref={cancelButtonRef}
                  >
                    {t("Cancel")}
                  </button>
                  <button
                    type="button"
                    className="inline-flex w-28 text-nowrap justify-center rounded-md bg-primary px-3 py-2 text-base font-semibold text-white shadow-sm sm:ml-3 sm:w-auto sm:w-24"
                    onClick={handleOpenApp}
                  >
                    {t('Open App')}
                  </button>
                  
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
        </Dialog>
    </Transition.Root>
  )
}
