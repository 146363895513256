import React from 'react';

function AddedListItem({ handleDelete, value }) {
  return (
    <div className="p-3 rounded-md bg-base-gray w-full flex justify-between items-center h-11">
      <p className="text-xs">{value?.name}</p>
      <div onClick={handleDelete}>
        <i className="las la-trash-alt h-[20px] text-error cursor-pointer"></i>
      </div>
    </div>
  );
}

export default AddedListItem;
