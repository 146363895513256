import React from 'react';
import { Button, LoadingButton } from './buttons';
import { useTranslate } from '../hooks';

export function ModalActions({
  isSubmitting,
  className,
  onSubmit,
  onCancel,
  disabled,
  title,
  cancelButtonProps,
  applyButtonProps,
}) {
  const { translate } = useTranslate();

  return (
    <div className={`flex justify-end w-full gap-x-4 ${className}`}>
      <Button
        className={`outline-btn ${cancelButtonProps?.className}`}
        onClick={onCancel}
      >
        {translate('Cancel')}
      </Button>
      <LoadingButton
        className={`primary-btn ${applyButtonProps?.className}`}
        isLoading={isSubmitting}
        onClick={onSubmit}
        disabled={disabled}
      >
        {title || translate('Submit')}
      </LoadingButton>
    </div>
  );
}
