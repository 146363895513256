import React from 'react';

import { TextField } from '@mui/material';
import { styled } from '@mui/styles';
import { colors } from '../../helpers/colors';
import { useTranslate } from '../../hooks';

const CustomTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: colors.primary,
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: colors.primary,
  },
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
});

export function TextInput(props) {
  const { dir } = useTranslate();

  return (
    <CustomTextField
      dir={dir}
      label={props.label}
      multiline={props.multiline}
      variant="outlined"
      focused={props.defaultValue || props.value || undefined}
      {...props}
      inputProps={{
        ...props?.inputProps,
        style: {
          padding: '14px',
        },
      }}
    />
  );
}
