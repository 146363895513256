import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

export const InfinityList = ({
  hasMore = false,
  next,
  loader,
  endMessage,
  children,
  dataLength = 0,
  height = '445px',
}) => {
  return (
    <InfiniteScroll
      hasMore={hasMore}
      next={next}
      loader={loader}
      endMessage={endMessage}
      dataLength={dataLength}
      height={height}
    >
      {children}
    </InfiniteScroll>
  );
};
