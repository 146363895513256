import { useUpdate } from '../../../../../../../api/react-query';

export function useEditOvertimeConfigType({ id }) {
  const { mutateAsync, isLoading: isEditing } = useUpdate({
    url: 'config/overtime-auto',
    id,
  });

  return {
    editOvertimeConfig: mutateAsync,
    isEditing,
  };
}
