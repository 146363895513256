import { pmsApi } from '../core';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { CustomToast } from '../../common/Methodcommon';

const assignedKpi = {
  getAllStatusBox,
  getAssignedKpis,
  getAssignedKpi,
  CreateAssignedKpis,
  updateAssignedKpi,
  deleteAssignedKpi,
  createEvaluationSheet,
};


function getAllStatusBox() {
  return new Promise((resolve, reject) => {
    pmsApi
      .get(`/api/v1/kpi_status_box`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

async function getAssignedKpis(filters) {
  try {
    const response = await pmsApi.get(`/api/evaluations/assigned`, {
      params: filters,
    });

    return response.data.data;
  } catch (err) {
    throw err;
  }
}

function CreateAssignedKpis(payload) {
  return new Promise((resolve, reject) => {
    pmsApi
      .post(`/api/cycle`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

async function getAssignedKpi() {
  return pmsApi.get(`/api/evaluations/assigned`);
}

async function getKpis(filters) {
  try {
    const response = await pmsApi.get(`/api/evaluations/kpis`, {
      params: filters,
    });

    return response.data.data;
  } catch (err) {
    throw err;
  }
}

function updateAssignedKpi(id, payload) {
  return new Promise((resolve, reject) => {
    pmsApi
      .put(`/api/cycle/${id}`, payload)
      .then((result) => {
        return resolve(result?.data?.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function deleteAssignedKpi(id) {
  return new Promise((resolve, reject) => {
    pmsApi
      .delete(`/api/evaluations/${id}`)
      .then((result) => {
        return resolve(result?.data?.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function createEvaluationSheet(payload) {
  return new Promise((resolve, reject) => {
    pmsApi
      .post(`/api/evaluation-sheets/link`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
  function getEvaluationSheetByEvaluationId(evaluationId) {
  return new Promise((resolve, reject) => {
    pmsApi
      .get(`/api/evaluation-sheets/evaluations/${evaluationId}`, )
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

export const useGetEvaluationSheetByEvaluationId = (evaluationId , onSuccess) => {
  return useQuery(['get-evaluation-sheet' , evaluationId], () =>
    getEvaluationSheetByEvaluationId(evaluationId), {
    initialData: { data: [] },
    enabled: !!evaluationId,
    onSuccess: onSuccess
  });
}

export const useGetAssignedKpis = (filters) => {
  return useQuery('assigned-kpis', () => getAssignedKpis(filters), {
    initialData: { list: [], count: 0 },
  });
};

export const useGetKpis = (filters) => {
  return useQuery('kpis', () => getKpis(filters), {
    initialData: { list: [], count: 0 },
  });
};

const updatePublished = async (cycle)  => {
  try {
    const updated =
      await pmsApi.put(`/api/cycle/${cycle.id}/published`,
        {
          published : cycle.published
        })
    return updated.data;
  } catch (error) {
    return error;
  }
}

export const useUpdatePublished = ({msg}) => {
  const queryClient = useQueryClient();
  return useMutation(updatePublished , {
    onSuccess : async () =>{
      await queryClient.invalidateQueries('unpublished-cycles')
      CustomToast('success',msg)
    }
})
}

const getUnPublishedCycles = async (filters) => {
  try {
  const response = await pmsApi.get(`/api/cycle/unpublished` , {
    params : {
      search : filters?.search,

      skip: 0,
      limit: 100
    }
  })
    return response.data.data;
  } catch (error) {
    return error;
  }
}

export const useUnPublishedCycles = (filters) => {
  return useQuery(['unpublished-cycles' , filters] , async () =>
    await getUnPublishedCycles(filters), {
      initialData: [],
    }
  )
}

export default assignedKpi;
